<nav class="flex flex-wrap gap-xs gap-md-md py-xs py-md-md items-center justify-between"
     [ngClass]="containerClass"
>
    <span class="block w-full w-md-auto fw-bold lowercase">
        <span class="inline-block text-end" style="width: 2ch;">{{ resultsPerPage }}</span> {{ resultsLabel | translate }} {{ 'out_of' | translate }} {{ maxResults ? maxResults : '??' }}
    </span>

    <ul class="flex flex-1 gap-sm justify-md-center scrollable-x clean-list">
        <li *ngFor="let pageNumber of this.pages">
            <app-button (btnClick)="goTo(pageNumber)"
                        variant="ghost"
                        [color]="currentPage == pageNumber ? 'primary': ''"
                        class="text-neutral bg-transparent fw-bold"
                        [disabled]="currentPage == pageNumber ? true : null"
            >{{ pageNumber }}
            </app-button>
        </li>
    </ul>

    <ul class="clean-list flex gap-xs">
        <li *ngIf="showFirstLastButtons">
            <app-button
                    mat-raised-button
                    matTooltip="{{'first_page' | translate}}"
                    (btnClick)="goTo(1)"
                    [disabled]="currentPage == 1"
                    icon="chevron-back-circle-outline"
                    variant="ghost"
                    [color]="''"
                    rounded="full"
            ></app-button>
        </li>
        <li>
            <app-button
                    mat-raised-button
                    matTooltip="{{'prev_page' | translate}}"
                    (btnClick)="goTo(currentPage - 1)"
                    [disabled]="currentPage == 1"
                    icon="chevron-back"
                    variant="ghost"
                    [color]="''"
                    rounded="full"
            ></app-button>
        </li>
        <li>
            <app-button
                    mat-raised-button
                    matTooltip="{{'next_page' | translate}}"
                    (btnClick)="goTo(currentPage + 1)"
                    [disabled]="currentPage >= nbOfPages"
                    icon="chevron-forward"
                    variant="ghost"
                    [color]="''"
                    rounded="full"
            ></app-button>
        </li>
        <li *ngIf="showFirstLastButtons">
            <app-button
                    mat-raised-button
                    matTooltip="{{'last_page' | translate}}"
                    (btnClick)="goTo(nbOfPages)"
                    [disabled]="currentPage >= nbOfPages"
                    icon="chevron-forward-circle-outline"
                    variant="ghost"
                    [color]="''"
                    rounded="full"
            ></app-button>
        </li>
    </ul>
</nav>
