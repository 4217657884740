<ion-card-header>
    <ion-card-title>
        {{ project.nom_sit }}
    </ion-card-title>
</ion-card-header>
<div class="container">
    <ion-list>
        <ion-item>
            <ion-label>
                {{ 'project_debut_suivi' | translate }}:
            </ion-label>
            <ion-text>
                {{ project.date_debut_suivi | date: utils.dateFormat }}
            </ion-text>
        </ion-item>
        <ion-item>
            <ion-label>
                {{ 'project_fin_suivi' | translate }}:
            </ion-label>
            <ion-text>
                {{ project.date_fin_suivi | date:utils.dateFormat }}
            </ion-text>
        </ion-item>
        <ion-item>
            <ion-label>
                {{ 'project_type_offre' | translate }}:
            </ion-label>
            <ion-text>
                {{ 'offer_level_' + project.type_offre | translate }}
            </ion-text>
        </ion-item>
        <ion-item *ngIf="utils.parseJsonWeekArray(project.jour_preco).length > 0">
            <ion-label>
                {{ 'project_jours_preco' | translate }}
            </ion-label>
            <ion-text>
                <div class="flex-row-wrap" style="justify-content: flex-end">
                    <ion-badge *ngFor="let day of utils.parseJsonWeekArray(project.jour_preco)"
                               color="success"
                    >
                        {{ day | translate }}
                    </ion-badge>
                </div>
            </ion-text>
        </ion-item>
        <ion-item>
            <ion-label>
                {{ 'sujet_contrat_under' | translate }}:
            </ion-label>
            <ion-text>
                {{ sujetsDetailsCount.underContract }}
            </ion-text>
        </ion-item>
        <ion-item>
            <ion-label>
                {{ 'sujet_contrat_under_not' | translate}}:
            </ion-label>
            <ion-text>
                {{ sujetsDetailsCount.notUnderContract}}
            </ion-text>
        </ion-item>
    </ion-list>
</div>
