<ion-header *ngIf="compteur">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>&nbsp;</ion-title>
  </ion-toolbar>
</ion-header>
<ion-segment [(ngModel)]="segment">
  <ion-segment-button value="infos">
    <ion-label>Informations</ion-label>
  </ion-segment-button>
  <!-- <ion-segment-button value="calendar">
    <ion-label>Calendrier</ion-label>
  </ion-segment-button> -->
</ion-segment>






<ion-content [ngSwitch]="segment" >
  <div *ngSwitchCase="'infos'">
    <ion-item>
      <ion-label>
        {{'Référence' | translate}} : {{compteur?.ref}}
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        {{'Nom' | translate}} : {{compteur?.nom}}
      </ion-label>
    </ion-item>
    <ion-list-header>
      <strong>{{'Ce compteur est utilisé avec les voies suivantes' | translate}} :</strong>
    </ion-list-header>
    <ion-list *ngIf="compteur">
      <ion-item *ngFor="let sujet of compteur.sujets">
    <ion-label class="ion-text-wrap">
      <ion-text>
        {{sujet.nom_suj}} (Minisense  {{sujet.nom}} )
      </ion-text>
      <div *ngIf="sujet.programme_actif">
        <h4>{{'Programme actif' | translate}} : {{sujet.programme_actif.programme.nom}}</h4>
        <app-semainier [data]="sujet.programme_actif.programme.contenu" [ev_duree_ouverture]="sujet.ev_duree_ouverture" [ev_heure_ouverture]="sujet.ev_heure_ouverture"></app-semainier>
      </div>
      <p *ngIf="!sujet.programme_actif">
        {{'Aucun programme actif sur cette voie' | translate}}

      </p>
    </ion-label>

      </ion-item>
    </ion-list>
  </div>
<div *ngSwitchCase="'calendar'">
  {{viewTitle}}
  <calendar *ngIf="eventSource" [eventSource]="eventSource"
    [calendarMode]="calendar.mode"
    [currentDate]="calendar.currentDate"
    [locale]="calendar.locale"
    (onCurrentDateChanged)="onCurrentDateChanged($event)"
    (onEventSelected)="onEventSelected($event)"
    (onTitleChanged)="onViewTitleChanged($event)"
    (onTimeSelected)="onTimeSelected($event)"
    step="30">

  </calendar>

</div>

</ion-content>
