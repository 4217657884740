import {Component, Input, OnInit} from '@angular/core';
import {BulletinWeek} from '../../../pages/archive-bulletins/archive-bulletins.page';

@Component({
  selector: 'app-bulletins-group',
  templateUrl: './bulletins-group.component.html',
  styleUrls: ['./bulletins-group.component.scss'],
})
export class BulletinsGroupComponent implements OnInit {
  @Input() week: BulletinWeek;

  constructor() { }

  ngOnInit() {}

}
