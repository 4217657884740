import {Component, Input, OnInit} from '@angular/core';
import {IFormField} from '../../../interfaces/IFormStep';
import {FormControl} from '@angular/forms';
import {MatVerticalStepper} from '@angular/material/stepper';

@Component({
    selector: 'app-step-input',
    templateUrl: './step-input.component.html',
    styleUrls: ['./step-input.component.scss'],
})
export class StepInputComponent implements OnInit {
    @Input() field: IFormField;
    @Input() control: FormControl;
    @Input() stepper: MatVerticalStepper;

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Preventing checkbox form being manually interacted with
     */
    handleInput(e: Event): void {
        e.preventDefault();
        this.field.onFormNav(this.stepper);
    }

    /**
     * Adding the same behaviour for keyboard users for accessibility
     */
    handlePressInput(e: KeyboardEvent): void {
        e.preventDefault();
        if (e.key === 'Enter') {
            this.field.onFormNav(this.stepper);
        }
    }

}
