import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FileValue, IFormField, IFormFieldFile, IModularFormPayload, Size} from '../../../interfaces/IFormStep';
import {ModularFormService} from '../../../services/modular-form.service';

export const DEFAULT_SIZE: Size = {
    width: 500,
    height: 500,
};

@Component({
    selector: 'app-modular-file-input',
    templateUrl: './modular-file-input.component.html',
    styleUrls: ['./modular-file-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModularFileInputComponent),
            multi: true
        }
    ]
})
export class ModularFileInputComponent implements OnInit {
    @ViewChild('input') input;

    @Input() control: FormArray;
    @Input() field: IFormFieldFile;

    message = 'no_file';

    constructor(
        private formBuilder: FormBuilder,
        private formService: ModularFormService,
    ) {
    }


    ngOnInit() {
        // If the input was pre-filled, we need to generate the message.
        this.message = this.getInputInfoTranslationKey();
    }


    /**
     * Triggers on file selection
     * Adds newly selected files to this.control FormArray as new FormGroups
     */
    handleFileSelect(e) {
        const fileList: FileList = e.target.files;
        for (const i in fileList) {
            this.addFile(fileList[i]);
        }
    }

    /**
     * Removes a given FormGroup, effectively deleting the associated file
     */
    deleteFile(i: number) {
        this.control.removeAt(i);
        this.message = this.getInputInfoTranslationKey();
    }

    /**
     * Generate FormGroup for each file on the go
     */
    createFormGroup(data): FormGroup {
        return this.formBuilder.group(data);
    }

    /**
     * Return translation key according to number of uploaded files
     */
    getInputInfoTranslationKey() {
        const fileList = this.control.controls;
        if (fileList.length > 1) {
            return 'files_uploaded_count';
        }
        if (fileList.length === 1) {
            return fileList[0].value.file.name.substr(0, 20);
        }
        return 'no_file';
    }

    injectValueIntoInput(fileValue: FileValue) {
        console.log(fileValue);
        // Ensure we have only one file on singular input scenario
        if (!this.field.multiple && this.control.length) {
            this.control.clear();
        }

        this.control.push(this.createFormGroup(fileValue));
        this.message = this.getInputInfoTranslationKey();
    }

    addFile(file: File): void {
        this.control.markAsTouched();
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            const base64 = event.target?.result as string;
            this.compressImageFile(base64, file)
                .then(this.injectValueIntoInput.bind(this));
        };

        if (file instanceof Blob) {
            reader.readAsDataURL(file);
            // reader.readAsArrayBuffer(file); // TODO turn it async ?
        }
    }

    /**
     * Forces a click on the hidden file input
     */
    handleInputClick() {
        this.input.nativeElement.click();
    }

    /**
     * Checks if a file can be previewed as an image
     */
    isPreviewable(file: File) {
        return this.formService.isPreviewable(file.type);
    }

    getReduceImageSize(img: HTMLImageElement): Size {
        const maxSize = this.field.imageCompressionSize ?? DEFAULT_SIZE;

        const aspectRatio = img.width / img.height;
        const newSize: Size = {
            width: img.width,
            height: img.height,
        };

        // Check if the image width needs to be resized
        if (img.width > maxSize.width) {
            newSize.width = maxSize.width;
            newSize.height = newSize.width / aspectRatio;
        }

        // Check if the image height needs to be resized
        if (newSize.height > maxSize.height) {
            newSize.height = maxSize.height;
            newSize.width = newSize.height * aspectRatio;
        }

        return newSize;
    }


    readBase64FileSize(base64str: string) {
        // We need to extract base64 data which are AFTER the ","
        // Decodes the base64 with atob in order to get its length
        return atob(base64str.split(',')[1]).length;
    }

    /**
     *  Attemps to convert a given File
     *  It will always return a working FileValue, regardless
     *  of compression success/failure.
     */
    compressImageFile(base64: string, file: File): Promise<FileValue> {
        return new Promise((resolve, reject) => {
            // No need to compress if compression is disabled
            if (this.field.enableImageCompression === false) {
                resolve(this.newFileValue(file, base64));
            }

            const img = new Image();
            img.src = base64;

            // If file cannot be converted to Image, we don't compress it
            img.onerror = err => {
                resolve(this.newFileValue(file, base64));
            };

            // If file can be turned into Image, we use a canvas to resize it
            img.onload = () => {
                const canvas: HTMLCanvasElement = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const {width, height} = this.getReduceImageSize(img);
                canvas.width = width;
                canvas.height = height;

                // Draw the resized image onto the canvas, centering it
                const x = (canvas.width - width) / 2;
                const y = (canvas.height - height) / 2;
                ctx.drawImage(img, x, y, width, height);

                const compressedBase64 = canvas.toDataURL(file.type, 0.5);

                resolve(this.newFileValue(file, compressedBase64));
            };
        });
    }

    newFileValue(file: File, compressedBase64: string | ArrayBuffer): FileValue {
        return {
            file,
            blob: compressedBase64,
            compressedSize: this.readBase64FileSize(compressedBase64 as string),
            fileName: file.name, // Needed to reconstruct File
        };
    }

}
