<div *ngIf="!projetService.canAccess(has, required); else content"
     class="flex flex-wrap"
     [ngClass]="classes"
>
    <p class="flex flex-wrap justify-center items-center gap-sm text-center ff-alt fw-bold">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <span *ngIf="!short">{{ 'access_restricted_by_offer' | translate: {level: required} }}</span>
    </p>
    <app-button *ngIf="cta" [to]="['/help']" icon="settings">
        {{'changer_offre'|translate}}
    </app-button>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
