import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-tooltip',
  templateUrl: './form-tooltip.component.html',
  styleUrls: ['./form-tooltip.component.scss'],
})
export class FormTooltipComponent implements OnInit {
  @Input() message: string;
  @Input() position: 'above'|'below'|'left'|'right'|'before'|'after' = 'above';
  @Input() delay = 1_000_000;
  @Input() color = 'success';
  @Input() icon = 'help-outline';

  constructor() { }

  ngOnInit() {}

}
