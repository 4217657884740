<app-page-header *ngIf="sujet" [textTitle]="sujet?.nom_sit + ' > ' + sujet?.nom_grp + ' > ' + sujet?.nom_suj"
                 [params]="paramsHeader"></app-page-header>

<ion-content>
    <ion-tabs>
        <ion-tab-bar slot="top">
            <ion-tab-button tab="vegetal">
                <ion-icon name="clipboard-outline"></ion-icon>
                <ion-label>{{'vegetal' | translate}}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="courbes">
                <ion-icon name="analytics"></ion-icon>
                <ion-label>{{'courbes' | translate}}</ion-label>
            </ion-tab-button>
            <ng-container *ngIf="projet?.droits?.voir_photo == 1">
                <ion-tab-button tab="photos">
                    <ion-icon name="camera"></ion-icon>
                    <ion-label>{{'photos' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="carte">
                    <ion-icon name="map"></ion-icon>
                    <ion-label>{{'carte' | translate}}</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="notes">
                    <ion-icon name="receipt-outline"></ion-icon>
                    <ion-label>{{'notes' | translate}}</ion-label>
                </ion-tab-button>
            </ng-container>
        </ion-tab-bar>
    </ion-tabs>
</ion-content>




