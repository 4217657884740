<article *ngIf="card; else row"
         class="urbasense-card intervention-card"
         [ngClass]="{
         'urgent': intervention.urgent,
         'critique': intervention.critique
         }"
>
    <!--Card Version-->
    <h2 class="intervention-card__region">{{ intervention.nom }}</h2>

    <address class="card-content-group intervention-card__address">
        <small>{{ 'site' | translate }}:</small>
        <p>{{ intervention.nom_sit }}</p>
        <small>{{ 'sujet' | translate }}:</small>
        <p>{{ intervention.nom_suj }}</p>
    </address>

    <app-intervention-type-badge
            class="intervention-card__type"
            [interventionType]="intervention.interv_type"
            [interventionDetailJson]="intervention.interv_detail"
    ></app-intervention-type-badge>

    <app-status-badge
            class="intervention-card__status"
            [status]="intervention.status.intst_statut"></app-status-badge>

    <time class="card-content-group intervention-card__time"
          [dateTime]="intervention.interv_creation_date">
        <small>{{ 'published_at' | translate }}:</small>
        {{ intervention.interv_creation_date | date: 'dd/MM/yyyy' }}
    </time>

    <ng-container *ngIf="!short">
        <div class="card-content-group intervention-card__resp">
            <small>{{ 'resp_eqpt_short' | translate }}:</small>
            <p *ngIf="intervention.resp_eqpt">{{ intervention.resp_eqpt['Nom'] }} {{ intervention.resp_eqpt['prenom'] }}</p>
            <p *ngIf="!intervention.resp_eqpt">{{ "none" | translate }}</p>
        </div>

        <ion-button
                class="intervention-card__mission"
                *ngIf="interventionService.isShowable(intervention.status.intst_statut) && intervention.status && intervention.status.ref_mission"
                [routerLink]="['/mission/'+ intervention.status.ref_mission]"
                [queryParams]="{type_demandeur: typeDemandeur}">
            <ion-icon name="eye-outline"></ion-icon>
            Mission {{ missionService.formatMissionNumber(intervention.status.ref_mission) }}
        </ion-button>


        <div *ngIf="interventionService.isOpenOrRefused(intervention.status.intst_statut) && canSelect"
             class="card-content-group intervention-card__select">
            <mat-checkbox
                    *ngIf="interventionService.isOpenOrRefused(intervention.status.intst_statut)"
                    [id]="intervention.interv_id"
                    (ngModelChange)="handleSelectGroupMember()"
                    [(ngModel)]="intervention.checked"
            ></mat-checkbox>
        </div>
    </ng-container>

    <ion-button
            class="intervention-card__modal"
            [color]="'tertiary'"
            (click)="handleShowInterventionHistoryModal()">
        <ion-icon name="document-text-outline"></ion-icon>
        {{ 'details' | translate }}
    </ion-button>

    <ion-button
            *ngIf="canDoIntervention"
            [color]="'success'"
            [routerLink]="[this.linkToInterventionForm]"
            size="large"
            style="width: 100%"
            expand="block"
            class="col-span"
    >
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <ion-label>{{ 'intervenir' | translate }}</ion-label>
    </ion-button>

    <ion-button
            [color]="'danger'"
            *ngIf="canCancelIntervention"
            (click)="handleDeleteIntervention()">
        <ion-icon name="trash-outline"></ion-icon>
        {{ 'Annuler l\intervention' | translate }}
    </ion-button>
</article>

<ng-template #row>
    <!--Table Row Version-->
    <td>{{ intervention.nom }}</td>
    <td>{{ intervention.nom_sit }}</td>
    <td>{{ intervention.nom_suj }}</td>
    <td>
        <app-intervention-type-badge
                [interventionType]="intervention.interv_type"
                [interventionDetailJson]="intervention.interv_detail"
        ></app-intervention-type-badge>
    </td>
    <td>
        <app-status-badge [status]="intervention.status.intst_statut"></app-status-badge>
    </td>
    <td>{{ intervention.interv_creation_date | date: 'dd/MM/yyyy' }}</td>
    <ng-container *ngIf="!short">
        <td *ngIf="intervention.resp_eqpt">{{ intervention.resp_eqpt['Nom'] }} {{ intervention.resp_eqpt['prenom'] }}</td>
        <td *ngIf="!intervention.resp_eqpt">{{ "none" | translate }}</td>
        <td style="text-align:center !important;" *ngIf="canSelect">
            <mat-checkbox
                    *ngIf="interventionService.isOpenOrRefused(intervention.status.intst_statut)"
                    [id]="intervention.interv_id"
                    (ngModelChange)="handleSelectGroupMember()"
                    [(ngModel)]="intervention.checked"
            ></mat-checkbox>
            <ion-button size="small"
                        *ngIf="interventionService.isShowable(intervention.status.intst_statut)
                        && intervention.status
                        && intervention.status.ref_mission"
                        [routerLink]="['/mission/'+ intervention.status.ref_mission]"
                        [queryParams]="{type_demandeur: typeDemandeur}"
            >
                <ion-icon name="eye-outline"></ion-icon>
                {{ 'mission_show' | translate }} {{ missionService.formatMissionNumber(intervention.status.ref_mission) }}
            </ion-button>
        </td>
    </ng-container>
    <td>
        <ion-button
                color="tertiary"
                size="small" (click)="handleShowInterventionHistoryModal()"
        >
            <ion-icon name="document-text-outline"></ion-icon>
            {{ 'details' | translate }}
        </ion-button>

        <ion-button
                *ngIf="canDoIntervention"
                [color]="'success'"
                [routerLink]="[this.linkToInterventionForm]"
                size="small"
        >
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <ion-label>{{ 'intervenir' | translate }}</ion-label>
        </ion-button>
    </td>
    <td>
        <ion-button
                [color]="'danger'"
                size="small"
                *ngIf="canCancelIntervention"
                (click)="handleDeleteIntervention()"
        >
            <ion-icon name="trash-outline"></ion-icon>
            {{ 'Annuler l\'intervention' | translate }}
        </ion-button>
    </td>
</ng-template>

