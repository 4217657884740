<app-offline-banner></app-offline-banner>
<ion-toolbar color="primary">
    <ion-buttons slot="start">
        <ng-container *ngIf="params?.url">
            <ion-button *ngIf="previousUrl" [routerLink]="previousUrl">
                <ion-icon style="font-size: 32px;" color="light" name="arrow-back-outline"></ion-icon>
            </ion-button>
            <ion-button *ngIf="!previousUrl" (click)="goBack()">
                <ion-icon style="font-size: 32px;" color="light" name="arrow-back-outline"></ion-icon>
            </ion-button>
        </ng-container>
        <a class="header-logo boldSf ion-text-wrap" [routerLink]="['/accueil']">
            <img src="/assets/logo_urbasense.png" class="header-logo" alt="logo urbasense">
            <h2 data-from="left">{{ getPageTitleExcerpt() }}</h2>
        </a>
    </ion-buttons>
    <ion-buttons slot="end">
        <app-combobox
                class="max-w-10r max-w-md-20r me-sm-sm"
                prefix="search"
                [placeholder]="'rechercher_projet'"
                [intoOptions$]="{
                        items$: projects$,
                        into: this.formService.projectInfoIntoComboboxOption
                    }"
                [multiple]="false"
                (change)="onSearchProject($event)"
        ></app-combobox>

        <app-button [to]="'/bulletins'" class="hidden inline-flex-md">
            {{'mes_bulletins'|translate}}
        </app-button>

        <ion-menu-button></ion-menu-button>
    </ion-buttons>
</ion-toolbar>
