<h2 mat-dialog-title>{{ 'intervention_maintenance' | translate }}</h2>

<div mat-dialog-content>
    <ng-container *ngIf="data.intervention?.intst_ref_interv; else no_intervention">
        <p>
            {{ 'maintenance_confirm_exists' | translate }}
        </p>
        <p>{{ 'intervention' | translate }}: {{ data.intervention.intst_ref_interv }}</p>
    </ng-container>
    <ng-template #no_intervention>
        <p>{{ 'maintenance_confirm_none' | translate }}</p>

    </ng-template>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="closeDialogWithChoice(false)">
        {{ 'annuler' | translate }}
    </button>

    <button mat-button *ngIf="data.intervention?.intst_ref_interv; else no_intervention_cta"
            (click)="closeDialogWithChoice(data.intervention.intst_ref_interv)">
        {{ 'maintenance_confirm_with_intervention' | translate: {interventionId: data.intervention.intst_ref_interv} }}
    </button>

    <ng-template #no_intervention_cta>
        <button mat-button
                (click)="closeDialogWithChoice(true)"
        >{{ 'maintenance_confirm_do_without_intervention' | translate }}
        </button>
    </ng-template>
</div>
