<section class="grid grid-cols-lg-3 grid-cols-md-2 bg-surface-1 p-md gap-lg rounded-sm">
    <div class="col-span flex flex-wrap justify-between gap-sm items-start">
        <h2 class="heading my-0 flex-1">
            {{ 'semaine_du_alt' | translate: {date_debut: (week.date_debut|date: 'dd/MM/yyyy')} }}
        </h2>
        <div *ngIf="week.projets.length">
            <app-button
                    icon="download"
                    title="{{'telecharger_tout_group'|translate }}"
                    [disabled]="true"
            ></app-button>
        </div>
    </div>
    <ul *ngIf="week.projets.length; else noProjects"
        class="clean-list col-span false-padding-xs grid gap-md grid-flow-col grid-auto-col-33-lg grid-auto-col-50-md grid-auto-col-95 overflow-auto scrollable-x">
        <li *ngFor="let projet of week.projets" class="snap-start">
            <app-bulletin-card class="contents"
                               [projet]="projet"
                               [date]="week.date_debut"
            ></app-bulletin-card>
        </li>
    </ul>
    <ng-template #noProjects>
        <p class="col-span flex flex-column gap-sm justify-self-center text-center p-m heading text-muted fs-lg">
            <ion-icon icon="notifications-off-outline"></ion-icon>
            {{ 'aucune_bulletin_disponible_cette_semaine'|translate }}
        </p>
    </ng-template>
</section>
