<h2 class="heading my-0 mb-lg">{{'mes_projets'|translate}}</h2>

<app-loader *ngIf="loading" [loading]="true"
            [message]="'loading_projects'"
></app-loader>

<ul *ngIf="!loading && !error && projects?.length > 0;" class="clean-list grid grid-flow-col gap-lg scrollable-x"
    style="grid-auto-columns: 20rem"
>
    <li *ngFor="let projectData of projects">
        <app-project-info-card
                [project]="projectData"
        ></app-project-info-card>
    </li>
</ul>

<p *ngIf="!loading && !error && projects?.length == 0" class="text-center p-lg">
    {{'no_projects'|translate}}
</p>

<div *ngIf="!loading && error" class="grid gap-lg justify-center text-center p-lg">
    <p class="text-danger font-bold">{{'projects_error'|translate}}</p>

    <app-button variant="outline" icon="refresh" (click)="fetchProjects()" title="'reload'">
        {{'refresh'|translate}}
    </app-button>
</div>
