import {Component, Input, OnInit} from '@angular/core';
import {ProjetSimple} from '../../../pages/archive-bulletins/archive-bulletins.page';
import {ModalController} from '@ionic/angular';
import {BulletinModalComponent} from '../bulletin-modal/bulletin-modal.component';

@Component({
    selector: 'app-bulletin-card',
    templateUrl: './bulletin-card.component.html',
    styleUrls: ['./bulletin-card.component.scss'],
})
export class BulletinCardComponent implements OnInit {
    @Input() projet: ProjetSimple;
    @Input() date: string; // date

    constructor(
        private modalController: ModalController,
    ) {
    }

    ngOnInit() {
    }

    async showBulletinModal() {
        const modal = await this.modalController.create({
            component: BulletinModalComponent,
            cssClass: 'modal-lg',
            componentProps: {
                projet: this.projet,
                date: this.date,
            }
        });
        await modal.present();
    }

}
