<app-button [matMenuTriggerFor]="menuFiltres"
            color="dark"
            fill="block"
            rounded="full"
>
    <ion-icon icon="pencil-outline"></ion-icon>
    {{label|translate}} ({{count}}/{{maxCount}})
</app-button>

<mat-menu #menuFiltres="matMenu">
    <div class="urbacard rounded-sm">
        <app-filter-list
                (click)="$event.stopPropagation();"
                [filters]="filters"
                (filtersChanged)="onFilterChanged()"
        ></app-filter-list>
    </div>
</mat-menu>
