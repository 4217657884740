<nav *ngIf="{isSubmitting: formService.isSubmitting$ | async} as ctx">
    <button
            class="save-button"
            *ngIf="showSaveBtn"
            (click)="handleSave()"
            mat-button>
        <ion-icon name="save-outline"></ion-icon>
        {{ 'save_form' | translate }}
    </button>
    <button
            class="prev-button"
            *ngIf="isNotFirstStep()"
            (click)="handlePrev()"
            [disabled]="!canGoBack"
            mat-button>
        <ion-icon name="chevron-back-outline"></ion-icon>
        {{ 'prev' | translate }}
    </button>
    <button
            class="next-button"
            *ngIf="!isLastStep()"
            (click)="handleNext()"
            [disabled]="!isCompletedStep"
            mat-button>
        {{ 'next' | translate }}
        <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
    <button
            *ngIf="isLastStep()"
            (click)="handleSubmit()"
            [disabled]="!isCompletedStep || ctx.isSubmitting"
            type="submit"
            mat-button>
        {{ (isEditMode ? 'modifier' : 'submit') | translate }}
        <ion-icon name="checkmark-outline"></ion-icon>
    </button>
<!--    <p>{{ctx.isSubmitting}}</p>-->
</nav>
