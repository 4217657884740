<div class="modular-input-container">
    <ng-container [ngSwitch]="field.type">
        <!-- Default -->
        <mat-form-field *ngSwitchDefault style="width: 100%">
            <mat-label [innerHtml]="field.label | translate"></mat-label>
            <input matInput
                   style="width: 100%"
                   placeholder="{{field.placeholder | translate}}"
                   [type]="field.type"
                   [formControl]="formControl"
                   [required]="field.required"
                   [multiple]="field.multiple"
            />
        </mat-form-field>

        <!-- TextArea-->
        <mat-form-field
                *ngSwitchCase="modularFormService.types.TEXTAREA"
                style="width: 100%">
            <mat-label *ngIf="field.label" [innerHtml]="field.label | translate"></mat-label>
            <textarea matInput
                      [formControl]="formControl"
                      rows="4"
                      placeholder="{{ field.placeholder | translate }}"
                      [required]="field.required"
            ></textarea>
        </mat-form-field>

        <!-- Checkbox -->
        <ng-container *ngSwitchCase="modularFormService.types.CHECKBOX">
            <mat-checkbox [formControl]="formControl">
                {{ field.label | translate }}
            </mat-checkbox>
        </ng-container>

        <!-- Radio Input-->
        <mat-radio-group *ngSwitchCase="modularFormService.types.RADIO"
                         [formControl]="formControl"
                         style="width: 100%"
        >
            <mat-label style="display: block;" [innerHtml]="field.label | translate"></mat-label>
            <div class="radio-group">
                <mat-radio-button *ngFor="let choice of field.choices"
                                  [value]="choice.value"
                                  [required]="field.required"
                >
                    {{ modularFormService.getChoiceTranslationKey(choice, field) | translate }}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <!-- Radio Vertical Input-->
        <mat-radio-group *ngSwitchCase="modularFormService.types.RADIO_VERTICAL"
                         [formControl]="formControl"
                         style="width: 100%" class="radio-vertical"
        >
            <mat-label style="display: block;" [innerHtml]="field.label | translate"></mat-label>
            <div class="radio-group">
                <mat-radio-button *ngFor="let choice of field.choices"
                                  [value]="choice.value"
                                  [required]="field.required"
                >
                    {{ modularFormService.getChoiceTranslationKey(choice, field) | translate }}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <!-- Select Input -->
        <mat-form-field
                *ngSwitchCase="modularFormService.types.SELECT"
                style="width: 100%">
            <mat-label *ngIf="field.label" [innerHtml]="field.label | translate"></mat-label>
            <mat-select
                    [formControl]="formControl"
                    [required]="field.required"
                    [multiple]="field.multiple"
            >
                <mat-option *ngFor="let choice of field.choices" value="{{choice.value}}">
                    {{ modularFormService.getChoiceTranslationKey(choice, field) | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- File-->
        <app-modular-file-input *ngSwitchCase="modularFormService.types.FILE"
                                [control]="formArray"
                                [field]="field"
        ></app-modular-file-input>

        <!-- Hidden-->
        <input *ngSwitchCase="modularFormService.types.HIDDEN"
               type="hidden" [name]="field.key" [value]="field.control[0]">

        <!-- Geolocation-->
        <app-geo-input *ngSwitchCase="modularFormService.types.GEO"
                       [field]="field"
                       [control]="formControl"
        ></app-geo-input>

        <!-- Checklist -->
        <app-checklist-input *ngSwitchCase="modularFormService.types.CHECKLIST"
                             [control]="formControl"
                             [field]="field"
        ></app-checklist-input>

        <!-- Not an Input -->
        <app-not-input *ngSwitchCase="modularFormService.types.NOT"
                       [field]="field"
        ></app-not-input>

        <!-- Accordeon Group -->
        <app-accordeon-input *ngSwitchCase="modularFormService.types.ACCORDEON"
                             [field]="field"
                             [step]="step"
        ></app-accordeon-input>

        <!-- Step input -->
        <app-step-input *ngSwitchCase="modularFormService.types.STEP"
                        [field]="field"
                        [control]="formControl"
                        [stepper]="stepper"
        ></app-step-input>

        <!-- Munsell color input -->
        <app-munsell-input *ngSwitchCase="modularFormService.types.MUNSELL"
                           [field]="field"
                           [control]="formControl"
        ></app-munsell-input>

        <!-- Table Group -->
        <app-table-input *ngSwitchCase="modularFormService.types.TABLE"
                         [field]="field"
                         [step]="step"
        ></app-table-input>
    </ng-container>
    <zxing-scanner
            *ngIf="isScanning"
            (scanSuccess)="handleScanSuccess($event)"
    ></zxing-scanner>
    <ng-container *ngIf="field.type !== modularFormService.types.NOT">
        <div>
            <app-form-tooltip
                    *ngIf="field.help"
                    [message]="field.help"
            ></app-form-tooltip>
            <ion-button *ngIf="field.scanner" color="success"
                        (click)="toggleScanner()"

                        class="scan-toggler">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                Scanner
            </ion-button>

            <!-- <div *ngIf="field.scanner">
                hghjhg
                <ion-icon name="qr-code-outline"
                          class="scan-toggler"
                          (click)="toggleScanner()"
                ></ion-icon>
            </div> -->
        </div>


        <app-form-error
                *ngIf="modularFormService.isInvalidTouchedInput(control)"
                [errors]="control.errors"
        ></app-form-error>
    </ng-container>
</div>

