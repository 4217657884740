<blockquote *ngIf="summary == null; else details" class="urbasense-input-container">
    {{ remarque }}
</blockquote>

<ng-template #details>
    <ng-container *ngFor="let dataKey of dataKeys" class="flex-row-wrap">
        <div *ngIf="canShow(dataKey)">
            <p style="color: var(--ion-color-step-600, #666666)">{{ 'intervention_remarque.' + dataKey | translate }}</p>
            <p>{{ dataToTranslationKey(summary[dataKey]) | translate }}</p>
        </div>
    </ng-container>
</ng-template>
