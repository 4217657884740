<ng-container *ngIf="!isStade; else stade">
<div class="grid grid-cols-lg-2_1 content-start align-items-start gap-lg"
style="resize: both">
    <app-card-rows [indicateurs]="indicateur_stats"></app-card-rows>
    <div class="keyval-group self-start" *ngIf="indicateur_id">
        <app-keyvals [indicateurs]="indicateur_id"></app-keyvals>
    </div>

    <app-graphe
            *ngIf="subject"
            [height]="'20rem'"
            class="col-span"
            [sujet]="subject"
            [withLegend]="true"
            [withScrollbar]="true"
            [smallLegends]="true"
    ></app-graphe>
</div>
</ng-container>

<ng-template #stade>
    <app-sujet-stade-section
            [subject]="subject"
            [indicateurs_stade]="indicateur_stats"
    ></app-sujet-stade-section>
</ng-template>
