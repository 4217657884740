<ion-button
        shape="block"
        fill="outline"
        color="medium"
        class="collapsable-toggle"
            (click)="toggleCollapsable()">
    {{ title }}
</ion-button>

<div
        class="collapsable-content"
        [ngClass]="{'show': isOpened}">
    <ng-content></ng-content>
</div>

