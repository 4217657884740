import {Injectable} from '@angular/core';
import {FileTypeEnum} from '../enums/FileTypeEnum';
import {AbstractControl, AsyncValidatorFn, FormArray, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {EVService} from './ev.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {IMinisense} from '../interfaces/IMinisense';
import {FileSizeUnitEnum} from '../interfaces/IFormStep';

@Injectable({
    providedIn: 'root'
})
export class ModularValidatorService {
    readonly ALL_IMAGES = [
        FileTypeEnum.IMAGE,
        FileTypeEnum.JPG,
        FileTypeEnum.PNG,
    ];

    constructor(
        private ev: EVService,
    ) {
    }

    public countValidator({min, max}: { min?: number, max?: number }): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;

            if (value === null || value === undefined || value === '') {
                return null;
            }

            if (null != min && value.length < min) {
                return {
                    minselected: {
                        requiredCount: min,
                        actualCount: value.length
                    }
                };
            }

            if (null != max && value.length > max) {
                return {
                    maxselected: {
                        requiredCount: max,
                        actualCount: value.length
                    }
                };
            }

            return null;
        };
    }

    public fileTypeValidator(types: FileTypeEnum[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control as any instanceof FormArray) {
                return {wrongControlType: true};
            }
            const controlArray = control as FormArray;

            for (const fileControl of controlArray.controls) {
                const file: File = fileControl.value.file;
                if (!types.includes(file.type as FileTypeEnum)) {
                    return {filetype: true};
                }
            }

            return null;
        };
    }

    /**
     * Allow to limit the size of a single file
     * A number and a unit (FileSizeUnitEnum) can be provided
     * Defaults to 1e+6 octet (1Mo)
     */
    public fileMaxSizeValidator(maxSize: number = 1e+6, unit: FileSizeUnitEnum = FileSizeUnitEnum.OCTET): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control as any instanceof FormArray) {
                return {wrongControlType: true};
            }
            const controlArray = control as FormArray;

            for (const fileControl of controlArray.controls) {
                const filesize = fileControl.value.compressedSize ?? fileControl.value.file.size;

                if (filesize > (maxSize * unit)) {
                    return {maxsize: true};
                }
            }

            return null;
        };
    }


    /**
     * Checks if a minisense is valid and returns corresponding error message
     */
    public minisenseValidator(): AsyncValidatorFn {
        // console.log('minisenseValidator');
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return this.ev.v6_enrollMinisense(control.value).pipe(
                map((ms: IMinisense) => {
                    console.log('v6_enrollMinisense', ms);
                    const msExists = (m: IMinisense) => m.status == null || m.status != 0;
                    const msIsBeingInstalled = (m: IMinisense) => m.install != null && m.install.id_centr_install;
                    const msIsAvailable = (m: IMinisense) => m.sujets_equipes != null && m.sujets_equipes.length === 0;
                    console.log('v6_enrollMinisense', ms);
                    if (msIsAvailable(ms)) {
                        return null;
                    }

                    // TODO Refacto this
                    if (!msExists(ms)) {
                        return {
                            invalidMsNotFound: true,
                        };
                    }

                    if (!msIsAvailable(ms) || msIsBeingInstalled(ms)) {
                        const sujet = ms.sujets_equipes[0];
                        return {
                            invalidMsInUse: {
                                sujet: sujet.nom_suj,
                                link: `/site/${sujet.id_sit}/data/vegetaux/sujet/${sujet.id_suj}/vegetal`
                            },
                        };
                    }

                    return {
                        invalidMsNotFound: !msExists(ms),
                    };
                })
            );
        };
    }

}
