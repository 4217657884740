<ion-grid *ngIf="auth.user$ | async as user">
    <ion-row>
        <ng-container *ngIf="userService.hasRole(user, userService.roles.INTERVENTION_ADMIN)">
            <ion-col>
                <ion-item button
                          [routerLink]="['/missions/' + userService.getAliasFromCode(userService.roles.INTERVENTION_ADMIN)]"
                          routerDirection="none">
                    <ion-label>{{ 'mission_all' | translate }}</ion-label>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item button
                          [routerLink]="['/interventions/liste/' + userService.getAliasFromCode(userService.roles.INTERVENTION_ADMIN)]"
                          routerDirection="none">
                    <ion-label>{{ 'intervention_all' | translate }}</ion-label>
                </ion-item>
            </ion-col>
        </ng-container>
        <ng-container *ngIf="userService.hasRole(user, userService.roles.INSTALLATEUR)">
            <ion-col>
                <ion-item button
                          [routerLink]="['/missions/' + userService.getAliasFromCode(userService.roles.INSTALLATEUR)]"
                          routerDirection="none">
                    <ion-label>{{ 'mission_my' | translate }}</ion-label>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item button
                          [routerLink]="['/interventions/liste/' + userService.getAliasFromCode(userService.roles.INSTALLATEUR)]"
                          routerDirection="none">
                    <ion-label>{{ 'intervention_my' | translate }}</ion-label>
                </ion-item>
            </ion-col>
        </ng-container>
    </ion-row>
</ion-grid>
