<div class="grid gap-lg content-start items-end">
    <app-contact-data *ngIf="mainContact"
            [title]="'Votre conseiller'"
            [contact]="mainContact"
    ></app-contact-data>
<!--    [imgSrc]="'https://fr.web.img6.acsta.net/c_310_420/medias/nmedia/18/73/54/92/19795744.jpg'"-->

    <mat-accordion multi *ngIf="contacts.length">
        <mat-expansion-panel class="bg-surface-1 shadow-none" *ngIf="contacts[0].length">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'autres_referents'|translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ul class="clean-list grid grid-auto-fit gap-md" style="--_col-width: 18rem">
                <li *ngFor="let contact of contacts[0]">
                    <app-person-card [person]="contact"></app-person-card>
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="bg-surface-1 shadow-none" *ngIf="contacts[1].length">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'liste_diffusion'|translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ul class="clean-list grid grid-auto-fit gap-md" style="--_col-width: 18rem">
                <li *ngFor="let contact of contacts[1]">
                    <app-person-card [person]="contact"></app-person-card>
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>
</div>
