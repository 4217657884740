<ion-header *ngIf="title">{{ title | translate }}</ion-header>
<ng-container *ngIf="(counters$ | async) as counters; else loading">
    <app-counter-list
            [data]="counters[role]"
            [labels]="labels"
    ></app-counter-list>
</ng-container>

<ng-template #loading>
    <ion-item>
        <ion-label>{{ 'interventions_counters_loading' | translate }}</ion-label>
        <ion-spinner name="lines"></ion-spinner>
    </ion-item>
</ng-template>
