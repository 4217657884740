<header mat-dialog-title>
    <h2>{{ 'form_data_select' | translate }}</h2>
</header>

<div mat-dialog-content>
    <p>{{ 'form_data_help' | translate }}</p>
    <small>{{ 'form_data_help_delete' | translate }}</small>
    <mat-selection-list [multiple]="false">
        <mat-list-option
                (click)="closeDialogWithChoice(null)"
        >
            {{ 'form_data_new' | translate }}
        </mat-list-option>
        <mat-list-option *ngFor="let choice of data.choices | keyvalue"
                         (click)="closeDialogWithChoice(choice.key)"
                         (contextmenu)="deleteFromStorage($event, choice.key)"
        >
            <div class="grid-row-choice">
                <div class="grid-row-choice__inner">
                    <div *ngIf="data.labels.length === 0; else byLabel">
                        {{ choice.key }}
                    </div>
                    <ng-template #byLabel>
                        <div *ngFor="let label of data.labels">
                            {{ choice.value['formData'][label] }}
                        </div>
                    </ng-template>
                </div>
                <div>
                    {{ choice.value['createdAt'] | date: 'hh:mm dd/MM/yyyy' }}
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</div>
