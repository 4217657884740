import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from '../components/atoms/loader/loader.component';
import {ButtonComponent} from '../components/atoms/button/button.component';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {ErrorComponent} from '../components/atoms/error/error.component';
import {GeoInputComponent} from "../components/atoms/geo-input/geo-input.component";
import {FileInputSimpleComponent} from "../components/molecules/file-input-simple/file-input-simple.component";
import {ComboboxComponent} from "../components/molecules/combobox/combobox.component";
import {ComboboxDialogComponent} from "../components/molecules/combobox-dialog/combobox-dialog.component";
import {InputSimpleComponent} from "../components/molecules/input-simple/input-simple.component";
import {FormErrorComponent} from "../components/atoms/form-error/form-error.component";
import {LabelComponent} from "../components/atoms/label/label.component";
import {FormControl, FormControlDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatModule} from "../shared/mat.module";
import {RouterModule} from "@angular/router";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";


@NgModule({
    declarations: [
        ButtonComponent,
        ComboboxComponent,
        ComboboxDialogComponent,
        ErrorComponent,
        FileInputSimpleComponent,
        GeoInputComponent,
        InputSimpleComponent,
        LoaderComponent,
        FormErrorComponent,
        LabelComponent,
    ],
    exports: [
        LabelComponent,
        ButtonComponent,
        ComboboxComponent,
        FormErrorComponent,
        ComboboxDialogComponent,
        FileInputSimpleComponent,
        ErrorComponent,
        GeoInputComponent,
        InputSimpleComponent,
        LoaderComponent,
    ],
    imports: [
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        MatModule,
        CommonModule,
        IonicModule,
        TranslateModule,
        LeafletModule,
    ]
})
export class UiModule {
}
