import {Component, Input, OnInit} from '@angular/core';
import {IFormField} from '../../../interfaces/IFormStep';
import {FormControl} from '@angular/forms';
import {munsellToHex} from 'munsell';

@Component({
    selector: 'app-munsell-input',
    templateUrl: './munsell-input.component.html',
    styleUrls: ['./munsell-input.component.scss'],
})
export class MunsellInputComponent implements OnInit {
    readonly munsellHues = [
        '5R', '7.5R', '10R', '2.5YR', '5YR', '7.5YR',
        '10YR', '2.5Y', '5Y', '10Y', '5GY'
    ];

    readonly munsellValues = [
        '2', '2.5', '3', '4', '5', '6', '7', '8'
    ];

    readonly munsellChromas = [
        '2', '3', '4', '5', '6', '8'
    ];

    @Input() field: IFormField;
    @Input() control: FormControl;
    hue: string;
    value: string;
    chroma: string;
    hexColor: string;

    constructor() {
    }

    ngOnInit() {
        if (this.control.value) {
            // Converting a munsell string to a munsell color to prefill the input
            this.fromMunsellString(this.control.value);
        }
    }

    fromMunsellString(munsellStr: string): void {
        // Extract hue, value and chroma form the munsell string
        const regex = /^(?<hue>\d+(\.\d+)?[A-Z]+)(?<value>\d+(\.\d+)?)(?<chroma>\d+)$/;
        const result = regex.exec(munsellStr);

        if (result && result.groups) {
            const {hue, value, chroma} = result.groups;
            this.hue = hue;
            this.value = value;
            this.chroma = chroma;
            this.onChange();
        }
    }

    onChange() {
        if (this.hue
            && this.value
            && this.chroma
        ) {
            this.control.setValue(this.toMunsellColor());
            this.hexColor = this.toHexColor();
        }
    }

    toMunsellColor() {
        return `${this.hue}${this.value}${this.chroma}`;
    }

    toHexColor() {
        return munsellToHex(`${this.hue} ${this.value}/${this.chroma}`);
    }
}
