<p class="text-muted uppercase mb-xs">{{'formats' | translate}}: {{ acceptedFormats.join(', ') }}</p>

<input type="file"
       class="sr-only"
       name="field.key"
       [multiple]="multiple"
       [disabled]="control.disabled"
       (change)="handleFileSelect($event)"
       [accept]="acceptedFormats.join(', ')"
       #input
/>

<app-button
        icon="cloud-upload-outline"
        variant="outline"
        (click)="handleInputClick()"
>
    {{ label|translate }}
</app-button>

<p class="mt-xs text-primary ff-alt">{{ message | translate: {count: control.controls.length} }}</p>
