<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button id="closeB" (click)="modalController.dismiss()">{{'fermer' | translate}}</ion-button>
    </ion-buttons>
    <ion-label>
      <h5 style="margin-left: 10px;">{{ title | translate}}</h5>
    </ion-label>
  </ion-toolbar>
</ion-header>
