<div class="grid gap-lg">
    <h2 class="heading col-span">{{ 'tutoriels'|translate }}</h2>
    <app-wip class="bg-surface-1"></app-wip>

    <!--                    <ul class="clean-list grid gap-md">-->
    <!--                        <li class="flex items-center gap-md">-->
    <!--                            <app-button icon="arrow-forward" target="_blank">-->
    <!--                                Youtube-->
    <!--                            </app-button>-->
    <!--                            <p>Voir les tutos youtube</p>-->
    <!--                        </li>-->
    <!--                        <li class="flex items-center gap-md">-->
    <!--                            <app-button icon="pencil" target="_blank">-->
    <!--                                {{'inscription_cta'|translate}}-->
    <!--                            </app-button>-->
    <!--                            <p>Voir les tutos youtube plus long voir même beaucoup plus long</p>-->
    <!--                        </li>-->
    <!--                    </ul>-->
</div>
