<ion-chip *ngIf="!alt" color="{{ color }}">
    <ion-label>{{ status | translate }}</ion-label>
    <ion-icon name="{{ icon }}"></ion-icon>
<!--    <ion-text>{{status}}</ion-text>-->
</ion-chip>

<!--Alternate version for timeline-->
<div *ngIf="alt"
     class="status-badge-alt ion-color-{{ color }}"
>
    <ion-icon name="{{ icon }}"></ion-icon>
</div>
