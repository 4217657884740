<mat-label>{{ field.label | translate }}</mat-label>

<div class="responsive-table">
    <table>
        <thead>
        <tr>
            <td></td>
            <th class="vertical-label" *ngFor="let choice of field.choices">
                {{ formService.getChoiceTranslationKey(choice, field) | translate }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <th></th>
            <th *ngFor="let choice of field.choices" class="p-0">
                <app-button
                        variant="outline"
                        icon="checkmark"
                        size="sm"
                        (btnClick)="setAll(choice.value)"
                ></app-button>
            </th>
        </tr>
        <tr *ngFor="let row of field.tableFields; let i = index">
            <th>
                {{ field.tableRows[i] | translate }}
            </th>
            <mat-radio-group
                    *ngIf="!field.multiple; else multiple"
                    style="display: contents"
                    [formControl]="getFormControl(row)">
                <td *ngFor="let choice of field.choices">
                    <label for="{{ field.key + row + choice.value }}">
                        <mat-radio-button
                                [value]="choice.value"
                                [required]="field.required"
                                [name]="field.key + row"
                        ></mat-radio-button>
                    </label>
                </td>
            </mat-radio-group>
            <ng-template #multiple>
                <td *ngFor="let choice of field.choices; let y = index">
                    <label for="{{ field.key + row + choice.value }}">
                        <mat-checkbox
                                [name]="field.key + row + choice.value"
                                [disabled]="getFormControl(row).disabled"
                                [checked]="hasValue(row, choice.value)"
                                (change)="handleChange($event, row , choice.value)"
                        ></mat-checkbox>
                    </label>
                </td>
            </ng-template>
            <td *ngIf="field.tableCommentKey">
                <mat-form-field style="width: 100%">
                    <!--     <mat-label *ngIf="field.label">-->
                    <!--          {{ field.key + '_' + field.tableCommentKey | translate }}-->
                    <!--     </mat-label>-->
                    <textarea matInput
                              rows="2"
                              [formControl]="getCommentFormControl(field.tableCommentFields[i])"
                              placeholder="{{ field.placeholder | translate }}"
                    ></textarea>
                </mat-form-field>
            </td>
        </tr>
        </tbody>
    </table>
</div>
