<div class="data-row">
    <ion-label class="ion-text-wrap">
        {{ labelPrefix + label | translate }}
    </ion-label>
    <div class="row-value">
        <p *ngIf="value">
            {{ valuePrefix + value | translate }}
        </p>
        <ul *ngIf="values" class="data-row-list">
            <li *ngFor="let v of values">
                {{ valuesPrefix + v | translate }}
            </li>
        </ul>
        <p *ngIf="(value == '' || value == null) && null == values">{{ 'nr' | translate }}</p>
        <p *ngIf="null == value && values.length == 0">{{ 'none' | translate }}</p>
    </div>
</div>
