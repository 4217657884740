<article class="flex flex-1 flex-column gap-md h-full">
    <div tabindex="0"
         (click)="showBulletinModal()"
         (keydown.enter)="showBulletinModal()"
         class="urbacard shadow rounded-sm content-start flex-1 cursor-pointer">
        <h3 class="heading">{{ projet.nom_site }}</h3>
        <app-card-rows
                [indicateurs]="projet.indicateurs"
        ></app-card-rows>
    </div>

    <app-button
            *ngIf="projet.link"
            variant="outline" class="self-md-start bg-surface-2"
            icon="download"
            [downloadUrl]="projet.link"
            [saveAs]="'projet-' + projet.id_site + 'bulletin-' + projet.id_bulletin + '.pdf'"
    >
        {{ 'telecharger_bulletin'|translate }}
    </app-button>
</article>
