<input type="hidden" name="field.key"
       [formControl]="control">

<div class="checklist-input__container urbasense-input-container">
    <label *ngIf="field.label" for="{{ field.key }}">{{ field.label | translate }}</label>
    <ion-icon name="checkmark-circle-outline"
              [ngClass]="{'done': control.value.length === field.choices.length}"
    ></ion-icon>
    <ol>
        <li *ngFor="let todo of field.choices; let i = index;"
            [ngClass]="{ 'done': control.value.length > i }"
        >
            <mat-checkbox
                    [checked]="isChecked(i)"
                    [disabled]="isDisabled(i)"
                    (change)="handleChange($event.checked, i)"
            >
                {{ modularFormService.getChoiceTranslationKey(todo, field) | translate }}
            </mat-checkbox>
        </li>
    </ol>
</div>
