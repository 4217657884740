<div class="prevent-full-screen">
    <ng-image-fullscreen-view
            [images]="photos"
            [imageIndex]="selectedImageIndex"
            [show]="showFlag"
            (close)="closeEventHandler()"></ng-image-fullscreen-view>
</div>

<ion-item *ngIf="isLoading" class="ion-text-wrap">
    <ion-spinner name="lines"></ion-spinner>
    <ion-label style="text-align:center;" class="ion-text-wrap">
        {{'Chargement de vos photos en cours' | translate}}
    </ion-label>
</ion-item>
<mat-form-field>
    <input type="text" placeholder="Saisir un nom de sujet" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="filtervalue">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearch($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
  <button type="button" mat-button *ngIf="filtervalue" matSuffix mat-icon-button aria-label="Clear" (click)="clearF()">
    <mat-icon>close</mat-icon>
  </button>
<ion-grid *ngIf="!isLoading">
    <ion-row *ngIf="auth.user$ | async as user">
        <ion-col sizeLg="4" sizeMd="4" sizeXs="12" *ngFor="let image of photos; let i = index">
            <app-photo-card
                    [photo]="image"
                    (handleClick)="showLightbox(i)"
            ></app-photo-card>
        </ion-col>
    </ion-row>
</ion-grid>

<ion-infinite-scroll (ionInfinite)="fetchPhotos($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="">
    </ion-infinite-scroll-content>
</ion-infinite-scroll>

<!--<div class="custom-file" *ngIf="!isLoading && addPhoto">-->
<!--  <label class="custom-file-label" for="customFile">{{'choisir_un_fichier' |  translate}} </label>-->
<!--</div>-->
<ion-item *ngIf="!isLoading && photos.length == 0">
    <ion-label>{{'vous_n_avez_aucune_photo' |  translate}}</ion-label>
</ion-item>
