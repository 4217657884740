import {Component, Input, OnInit} from '@angular/core';
import {Id} from '../../../interfaces/Id';
import {ISujet} from '../../../interfaces/ISujet';
import {ModularFormService} from '../../../services/modular-form.service';
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {ComboBoxOption} from "../../molecules/combobox/combobox.component";

type SujetGroupe = {
    id_grp: Id;
    nom: string;
    type_arro?: string,
    type_preco: string;
    nb_suj: number;
    date_plantation: string; // Date
    detail: string;
    grp_archive: number | string; // 0
    sujets: ISujet[]; // not really, it is a simplified object with only id_ ?
};

@Component({
    selector: 'app-projet-groupe-section',
    templateUrl: './projet-groupe-section.component.html',
    styleUrls: ['./projet-groupe-section.component.scss'],
})
export class ProjetGroupeSectionComponent implements OnInit {
    @Input() groupes: SujetGroupe[] = [];
    sujets: ISujet[];

    constructor(
        public formService: ModularFormService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        // Delete duplicates
        this.groupes = this.groupes.filter((obj1, i, arr) => {
            return arr.findIndex(obj2 => (obj2.id_grp === obj1.id_grp)) === i;
        });

        this.sujets = this.groupes
            .map(g => g.sujets)
            .reduce((acc, val) => acc.concat(val), [])
            .filter((obj1, i, arr) => {
                return null != obj1
                    && arr.findIndex(obj2 => (obj2.id_suj === obj1.id_suj)) === i;
            })
            .sort((a, b) => a.nom_suj.localeCompare(b.nom_suj));
    }

    onSearchSujet(options: ComboBoxOption[]) {
        if (options.length > 0) {
            this.router.navigate(['/sujet', options[0].value]);
        }
    }

}
