import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {IFormField} from '../../../interfaces/IFormStep';
import {NotAModularInputDirective} from '../../../directives/not-amodular-input.directive';

@Component({
    selector: 'app-not-input',
    templateUrl: './not-input.component.html',
    styleUrls: ['./not-input.component.scss'],
})
export class NotInputComponent implements OnInit {
    @ViewChild(
        NotAModularInputDirective,
        {static: false}
    ) appNotAModularInput: NotAModularInputDirective;
    @Input() field: IFormField;

    interval: any;
    loading = false;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
    }

    ngOnInit() {
        if (this.field.widget?.component) {
            this.loading = true;
            this.injectWidgetSafe();
        }
    }

    injectWidgetSafe() {
        /**
         * Injects the generated Component after a small delay
         * Delay is needed by Angular to make sure the view is rendered
         * We cannot use ngAfterInit, or else Angular throw an error
         */
        this.interval = setInterval(() => {
            this.injectWidget();
            clearInterval(this.interval);
            this.loading = false;
        }, 500);
    }

    injectWidget() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.field.widget.component);
        const viewContainerRef = this.appNotAModularInput.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);

        // Injecting props
        for (const propKey in this.field.widget.props) {
            componentRef.instance[propKey] = this.field.widget.props[propKey];
        }
    }
}
