import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IModularFormPayload} from "../../../interfaces/IFormStep";
import {StorageService} from "../../../services/storage.service";
import {ModularFormService} from "../../../services/modular-form.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-modular-select-dialog',
    templateUrl: './modular-select-dialog.component.html',
    styleUrls: ['./modular-select-dialog.component.scss'],
})
export class ModularSelectDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            choices: {[key: string]: IModularFormPayload}
            storageKey: string,
            labels: [],
        },
        public dialogRef: MatDialogRef<ModularSelectDialogComponent>,
        private formService: ModularFormService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
    }

    deleteFromStorage(e: Event, choice: string) {
        e.preventDefault();
        if (!confirm(this.translateService.instant('form_data_confirm_delete'))) {
            return;
        }
        delete this.data.choices[choice];
        this.formService.removeFormDataFromStorage(this.data.storageKey, choice);
    }

    closeDialogWithChoice(choice: any) {
        this.dialogRef.close(choice);
    }
}
