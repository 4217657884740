<ion-row *ngIf="modeAffichage == 'detail'">
  <ion-col size="12" size-lg="4" *ngFor="let indicateur of dendro?.listeParams | keyvalue">
  <ion-card >
    <ion-item [style.--background]="dendro[indicateur.value]?.couleur" [style.--color]="'#FFFFFF'">
      <ion-label class="ion-text-wrap"  matTooltip="{{ dendro[indicateur.value]?.tooltip | translate }}" >
        <h4 style="font-weight:bolder;font-size:large;">{{dendro[indicateur.value]?.nom | translate}}</h4>
        <p style="font-weight:bolder;font-size:xx-large;line-height: 34px;" [style.color]="'#FFFFFF'">{{dendro[indicateur.value]?.valeur}} {{dendro[indicateur.value]?.unite}}</p>
      </ion-label>
      <ion-badge style="background-color: rgba(0, 0, 255, 0);">
        <ion-icon name="checkmark-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'ok'" color="transparent"></ion-icon>
        <ion-icon name="eye-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'vigilance'" color="transparent"></ion-icon>
        <ion-icon name="alert-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'alerte'" color="transparent"></ion-icon>
      </ion-badge>
    </ion-item>
  </ion-card>
</ion-col>
</ion-row>

<ion-row *ngIf="modeAffichage == 'liste'">
    <ion-col *ngFor="let indicateur of dendro?.listeParams | keyvalue" >
        <ion-card>
            <ion-item [style.--background]="dendro[indicateur.value]?.couleur" [style.--color]="'#FFFFFF'">
                <ion-label class="ion-text-wrap">
                  <h4 style="font-weight:bolder;">{{dendro[indicateur.value]?.nom | translate}}</h4>
                  <p style="font-weight:bolder;" [style.color]="'#FFFFFF'">{{dendro[indicateur.value]?.valeur}} {{dendro[indicateur.value]?.unite}}</p>
                </ion-label>
                <ion-badge style="background-color: rgba(0, 0, 255, 0);">
                  <ion-icon name="checkmark-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'ok'" color="transparent"></ion-icon>
                  <ion-icon name="eye-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'vigilance'" color="transparent"></ion-icon>
                  <ion-icon name="alert-outline" size="large"  *ngIf="dendro[indicateur.value]?.badge === 'alerte'" color="transparent"></ion-icon>
                </ion-badge>
            </ion-item>
        </ion-card>
    </ion-col>
</ion-row>
