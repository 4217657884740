

<table style="width:100%;">

  <tbody>
    <tr>
      <td>{{'lun' | translate}}</td>
      <td>{{'mar' | translate}}</td>
      <td>{{'mer' | translate}}</td>
      <td>{{'jeu' | translate}}</td>
      <td>{{'ven' | translate}}</td>
      <td>{{'sam' | translate}}</td>
      <td>{{'dim' | translate}}</td>
      <td>{{'repetition' | translate}}</td>

  </tr>
      <tr style="font-size:2em !important;">
          <td>{{(data?.frequence.indexOf("1") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("2") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("3") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("4") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("5") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("6") > -1) ? '■' :  '□'}}</td>
          <td>{{(data?.frequence.indexOf("7") > -1) ? '■' :  '□'}}</td>
          <td style="font-size:1em !important;">{{data?.planning}}</td>
      </tr>
      <tr style="font-size:0.6em !important;" *ngIf="ev_heure_ouverture">
        <td>{{(data?.frequence.indexOf("1") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("2") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("3") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("4") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("5") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("6") > -1) ? ev_heure_ouverture : ''}}</td>
        <td>{{(data?.frequence.indexOf("7") > -1) ? ev_heure_ouverture : ''}}</td>

    </tr>
    <tr style="font-size:0.6em !important;" *ngIf="ev_duree_ouverture">
      <td>{{(data?.frequence.indexOf("1") > -1) ? utils.transformTime(ev_duree_ouverture)  : ''}}</td>
      <td>{{(data?.frequence.indexOf("2") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>
      <td>{{(data?.frequence.indexOf("3") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>
      <td>{{(data?.frequence.indexOf("4") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>
      <td>{{(data?.frequence.indexOf("5") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>
      <td>{{(data?.frequence.indexOf("6") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>
      <td>{{(data?.frequence.indexOf("7") > -1) ? utils.transformTime(ev_duree_ouverture) : ''}}</td>

  </tr>
  </tbody>
</table>
<div>

</div>



