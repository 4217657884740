<app-blurhash style="width:100%; height:250px; cursor: pointer;"
              blurHash="{{photo.blurhash}}"
              (click)="onClick()"
              [imageSrc]="photo?.image">
</app-blurhash>
<ion-item style="font-size:0.6em">
    <ion-label class="ion-text-wrap">
        {{photo.nom_sujet}} - {{photo.user}}
        <p>{{photo.date}}</p>
    </ion-label>
    <ion-button *ngIf="permissions?.ajouter_photo == 1"
                (click)="onEdit()" [disabled]="permissions?.ajouter_photo == 0">
        <ion-icon name="create-outline"></ion-icon>
    </ion-button>
    <ion-button *ngIf="permissions?.ajouter_photo == 1"
                (click)="onDelete()" [disabled]="permissions?.ajouter_photo == 0">
        <ion-icon name="trash-outline"></ion-icon>
    </ion-button>
    <ion-button (click)="onClick()">
        <ion-icon name="eye-outline"></ion-icon>
    </ion-button>
</ion-item>
