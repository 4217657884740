<article class="urbacard shadow rounded-sm">
    <div class="flex gap-sm min-w-0">
        <a [routerLink]="['/accueil']" [queryParams]="{site: project.id_sit}"
           queryParamsHandling="merge"
           class="simple-link truncate flex-1"
        >
            <h2 class="truncate flex-1 ff-heading fw-bold" title="{{project.nom_sit}}">
                {{ project.nom_sit }}
            </h2>
        </a>

        <div class="flex items-center gap-sm">
            <!--     TODO Icon list ?       -->
        </div>
    </div>

    <app-card-rows [indicateurs]="project.indicateurs"></app-card-rows>

    <div class="grid grid-cols-2 gap-x-sm gap-y-xs empty-hidden">
        <app-button [to]="['/projet', project.id_sit]"
                    fill="block"
                    icon="eye-outline"
                    class="col-span"
        >
            {{ 'fiche_detaillee_du_projet' | translate }}
        </app-button>

        <app-button [to]="['/accueil']"
                    [queryParams]="{site: project.id_sit}"
                    queryParamsHandling="merge"
                    fill="block"
                    variant="outline"
                    icon="leaf-outline"
                    class="col-span"
        >
            {{ 'voir_les_sujets' | translate }}
        </app-button>

        <app-button
                *ngIf="project.indicateurs['nombreEV']?.valeur > 0"
                [variant]="'outline'"
                [to]="['/site', project.id_sit, 'projet-watering', 'programmation-gen']"
                icon="arrow-forward"
                class="col-start-1"
        >
            {{ 'acces_programmateur' | translate }}
        </app-button>

        <app-button
                *ngIf="project.gestion_device == '1' && project.indicateurs['nombreMS']?.valeur > 0"
                [variant]="'outline'"
                [to]="['/site', project.id_sit, 'equipement']"
                icon="wifi"
                class="col-start-2"
        >
            {{ 'equipement' | translate }}
        </app-button>
    </div>
    <!--    <app-button *ngIf="project?.nb_ev">{{'arrosage'|translate}}</app-button>-->
</article>
