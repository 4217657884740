<p *ngIf="start && end; else startOnly">
    <ion-icon name="time-outline"></ion-icon>
    {{ 'time_start' | translate }}
    <time [dateTime]="start">
        {{start | date: format}}
    </time>
    {{ 'time_end' | translate }}
    <time *ngIf="end"
          [dateTime]="end"
    >{{end | date: format}}</time>
</p>

<ng-template #startOnly>
    <ion-icon name="time-outline"></ion-icon>
    {{ 'time_single' | translate }}
    <time [dateTime]="start">
        {{start | date: format}}
    </time>
</ng-template>
