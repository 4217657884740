<mat-accordion>
    <mat-expansion-panel *ngFor="let group of this.field.accordeonGroups; let i = index"
                         [expanded]="i === 0 || group.expanded"
                         [disabled]="group.disabled"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{group.title | translate }}
            </mat-panel-title>
            <mat-panel-description *ngIf="group.description">
                {{group.description | translate }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="modular-grid"
             [style.--_xs-cols]="group.gridTemplate?.xs?.cols"
             [style.--_xs-rows]="group.gridTemplate?.xs?.rows"
             [style.--_sm-cols]="group.gridTemplate?.sm?.cols"
             [style.--_sm-rows]="group.gridTemplate?.sm?.rows"
             [style.--_md-cols]="group.gridTemplate?.md?.cols"
             [style.--_md-rows]="group.gridTemplate?.md?.rows"
             [style.--_lg-cols]="group.gridTemplate?.lg?.cols"
             [style.--_lg-rows]="group.gridTemplate?.lg?.rows"
             [style.--_xl-cols]="group.gridTemplate?.xl?.cols"
             [style.--_xl-rows]="group.gridTemplate?.xl?.rows"
             [style.--_2xl-cols]="group.gridTemplate?.xxl?.cols"
             [style.--_2xl-rows]="group.gridTemplate?.xxl?.rows"
        >
        <app-modular-input *ngFor="let field of group.fields"
                           [field]="field"
                           [step]="step"
                           [control]="step.formGroup.get(field.key)"
                           [style.--_xs-col]="field.grid?.xs?.col"
                           [style.--_xs-row]="field.grid?.xs?.row"
                           [style.--_xs-colspan]="field.grid?.xs?.colspan"
                           [style.--_xs-rowspan]="field.grid?.xs?.rowspan"
                           [style.--_xs-justify-self]="field.grid?.xs?.justifySelf"
                           [style.--_xs-align-self]="field.grid?.xs?.alignSelf"
                           [style.--_sm-col]="field.grid?.sm?.col"
                           [style.--_sm-row]="field.grid?.sm?.row"
                           [style.--_sm-colspan]="field.grid?.sm?.colspan"
                           [style.--_sm-rowspan]="field.grid?.sm?.rowspan"
                           [style.--_sm-justify-self]="field.grid?.sm?.justifySelf"
                           [style.--_sm-align-self]="field.grid?.sm?.alignSelf"
                           [style.--_md-col]="field.grid?.md?.col"
                           [style.--_md-row]="field.grid?.md?.row"
                           [style.--_md-colspan]="field.grid?.md?.colspan"
                           [style.--_md-rowspan]="field.grid?.md?.rowspan"
                           [style.--_md-justify-self]="field.grid?.md?.justifySelf"
                           [style.--_md-align-self]="field.grid?.md?.alignSelf"
                           [style.--_lg-col]="field.grid?.lg?.col"
                           [style.--_lg-row]="field.grid?.lg?.row"
                           [style.--_lg-colspan]="field.grid?.lg?.colspan"
                           [style.--_lg-rowspan]="field.grid?.lg?.rowspan"
                           [style.--_lg-justify-self]="field.grid?.lg?.justifySelf"
                           [style.--_lg-align-self]="field.grid?.lg?.alignSelf"
                           [style.--_xl-col]="field.grid?.xl?.col"
                           [style.--_xl-row]="field.grid?.xl?.row"
                           [style.--_xl-colspan]="field.grid?.xl?.colspan"
                           [style.--_xl-rowspan]="field.grid?.xl?.rowspan"
                           [style.--_xl-justify-self]="field.grid?.xl?.justifySelf"
                           [style.--_xl-align-self]="field.grid?.xl?.alignSelf"
                           [style.--_2xl-col]="field.grid?.xxl?.col"
                           [style.--_2xl-row]="field.grid?.xxl?.row"
                           [style.--_2xl-colspan]="field.grid?.xxl?.colspan"
                           [style.--_2xl-rowspan]="field.grid?.xxl?.rowspan"
                           [style.--_2xl-justify-self]="field.grid?.xxl?.justifySelf"
                           [style.--_2xl-align-self]="field.grid?.xxl?.alignSelf"
        ></app-modular-input>
        </div>
    </mat-expansion-panel>
</mat-accordion>
