<app-theme-fetcher></app-theme-fetcher>

<app-detectnavigator></app-detectnavigator>
<app-install-prompt></app-install-prompt>
<ion-app>
    <!--  <app-generic-loader></app-generic-loader>-->
    <ion-split-pane contentId="main-content">
        <ion-menu side="end" contentId="main-content" type="overlay" *ngIf="auth.isAuthenticated$ | async">
            <ion-content>
                <ion-list>
                    <ion-item [routerLink]="['/accueil']" (click)="menu.close()">
                        <ion-icon slot="start" name="list-outline"></ion-icon>
                        <ion-label>{{ 'mes_projets' | translate }}</ion-label>
                    </ion-item>
                    <!-- <div *ngIf="auth.user$ | async as user" >
                       <ion-item *ngIf="user['https://api-webapp-urbasense.com/roles']" [routerLink]="['adminarbre']">
                         <ion-label>Super admin</ion-label>
                       </ion-item>
                     </div> -->

                    <ion-item [routerLink]="['urbadata']" (click)="menu.close();trackMix('Urbadata')">
                        <ion-icon name="analytics-outline" slot="start"></ion-icon>
                        <ion-label>Urbadata</ion-label>
                    </ion-item>
                    <!-- <ion-item [routerLink]="['installminisense']" (click)="menu.close();trackMix('Click menu install minisense')" id="install_mini" *ngIf="isInstallateur || isAdmin">
                      <ion-icon name="add-circle-outline" slot="start"></ion-icon>
                      <ion-label>Installation</ion-label>
                    </ion-item> -->
                    <ion-item [routerLink]="['arrosage']" (click)="menu.close();">
                        <ion-icon name="water-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'gestion_arrosage'|translate }}</ion-label>
                    </ion-item>
                    <ion-item [routerLink]="['interventions']"
                              (click)="menu.close();trackMix('Click menu interventions')" id="interventions"
                              *ngIf="isInstallateur || isAdmin">
                        <ion-icon name="calendar-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'interventions'|translate }}</ion-label>
                    </ion-item>

                    <ion-item [routerLink]="['/bulletins']" (click)="menu.close()">
                        <ion-icon name="folder-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'bulletins' | translate }}</ion-label>
                    </ion-item>

                    <ion-item [routerLink]="['maintenancemap']" (click)="menu.close()" *ngIf="isUrbasenseCorporate">
                        <ion-icon name="map-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'Carte des maintenances' | translate }}</ion-label>
                    </ion-item>

                    <ion-item [routerLink]="['profil']" (click)="menu.close()">
                        <ion-icon name="list-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'profil' | translate }}</ion-label>
                    </ion-item>

                    <ion-item>
                        <app-theme-toggler></app-theme-toggler>
                    </ion-item>
                    <ion-item>
                        <app-language-selector class="w-full"></app-language-selector>
                    </ion-item>
                    <ion-item button (click)="logout()">
                        <ion-icon name="log-out-outline" slot="start"></ion-icon>
                        <ion-label>{{ 'se_deconnecter' | translate }}</ion-label>
                    </ion-item>
                    <ion-item [routerLink]="['/help']" (click)="menu.close()">
                        <ion-button>
                            {{ 'besoin_aide' | translate }} ?
                            <ion-icon name="help-circle-outline" slot="start"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <ion-item href="https://services.urbasense.eu/Southside_api/v7/doc_urbasense_3.0.3_FR.pdf"
                              target="_blank" (click)="trackMix('Telechargement documentation')">
                        <ion-button color="light">
                            {{ 'telecharger_doc' | translate }}
                            <ion-icon name="document-text-outline" slot="start"></ion-icon>
                        </ion-button>
                    </ion-item>

                </ion-list>
                <p style="text-align:center;font-size:0.7em">version {{ version }}</p>
            </ion-content>
        </ion-menu>

        <ion-router-outlet id="main-content">

        </ion-router-outlet>
    </ion-split-pane>
</ion-app>


