<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">{{ 'fermer' | translate }}</ion-button>
        </ion-buttons>
        <ion-title>&nbsp;</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <h3>{{ 'historique_intervention'|translate }}</h3>

    <ion-item>
        <ion-label>
            <h4>{{ 'type_intervention'|translate }}</h4>
            <app-intervention-type-badge *ngIf="detailIntervention"
                                         [interventionType]="detailIntervention.interv_type"
                                         [interventionDetailJson]="detailIntervention.interv_detail"
            ></app-intervention-type-badge>
        </ion-label>
    </ion-item>

    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>{{ 'mission_created_at'|translate }}</h4>
                        <p>{{ detailIntervention?.interv_creation_date | date: 'dd/MM/yyyy' }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>

            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>Région</h4>
                        <p>{{ detailIntervention?.nom_region }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>Site</h4>
                        <p>{{ detailIntervention?.nom_sit }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>Sujet</h4>
                        <p>{{ detailIntervention?.nom_suj }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>Type du dispositif</h4>
                        <p>{{ detailIntervention?.nom_dispositif }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>
            <ion-col>
                <ion-item>
                    <ion-label>
                        <h4>Responsable équipements</h4>
                        <p *ngIf="detailIntervention?.resp_equipement">{{ detailIntervention?.resp_equipement.Nom | uppercase }} {{ detailIntervention?.resp_equipement.prenom }}</p>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>


    <ion-item>
        <ion-label>
            <h4>Remarque</h4>
            <p>{{ detail?.remarque }}</p>
        </ion-label>
    </ion-item>

    <div class="">
        <ul class="timeline">
            <li *ngFor="let statut_inter of detailIntervention?.historique">
                <div class="timeline-thumb timeline-icon">
                    <app-status-badge
                            [status]="statut_inter?.intst_statut"
                            [alt]="true">
                    </app-status-badge>
                </div>
                <div class="timeline-item">
                    <h4>{{ statut_inter?.intst_statut | translate }}</h4>
                    <p *ngIf="statut_inter?.intst_statut === interventionService.status.DEMANDEE">{{ 'by' | translate }} {{ statut_inter.prenom }} {{ statut_inter.Nom }}</p>
                    <p *ngIf="statut_inter?.intst_statut === interventionService.status.PROPOSEE">{{ 'à' | translate }} {{ statut_inter.installateur.prenom }} {{ statut_inter.installateur.Nom }}</p>
                    <p *ngIf="statut_inter?.intst_statut === interventionService.status.REALISEE || statut_inter?.intst_statut === interventionService.status.ACCEPTEE">{{ 'by' | translate }} {{ statut_inter.installateur.prenom }} {{ statut_inter.installateur.Nom }}</p>
                    <div class="timeline-stats">
                        <span class="pos-absolute-right">{{ statut_inter?.intst_statut_date | date: 'dd/MM/yyyy' }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</ion-content>
