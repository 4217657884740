<article *ngIf="card; else row" class="urbasense-card mission-card">

    <time  class="card-content-group mission-card__time"
        [dateTime]="mission.date_debut_mission">
        <small>{{ 'mission_date' | translate }}</small>
        <p>{{ mission.date_debut_mission | date: 'dd/MM/yyyy' }}</p>
    </time>


    <time class="card-content-group mission-card__time"
          [dateTime]="mission.date_creation_mission">
        <small>{{ 'mission_created_at' | translate }}</small>
        <p>{{ mission.date_creation_mission | date: 'dd/MM/yyyy' }}</p>
    </time>

    <div class="card-content-group mission-card__installer">
        <small>{{ 'USER_ROLE_18'| translate }}</small>
        <p>{{ mission.installateur.Nom }} {{ mission.installateur.prenom }}</p>
    </div>

    <div class="mission-card__site-list card-content-group">
        <small>{{'sites'}}</small>
        <ul class="mission-card__site-list">
            <li *ngFor="let site of mission.nombre_site">{{site}}</li>
        </ul>
    </div>

    <div class="card-content-group mission-card__interventions">
        <small>{{ 'nb_interventions'| translate }}</small>
        <p>{{ mission.interventions.length }}</p>
    </div>

    <app-status-badge
            class="mission-card__status"
            [status]="mission.statut_mission"></app-status-badge>

    <ion-buttons class="mission-card__actions">
        <!--    Modal-->
        <ion-button color="secondary"
                    [routerLink]="['/mission/'+ mission.id_mission]"
                    [queryParams]="{type_demandeur: typeDemandeur}"
        >
            <ion-icon name="eye-outline"></ion-icon>
            {{ 'mission_show' | translate }} {{mission.id_mission}}
        </ion-button>

        <!--    Actions-->
        <ion-button
                *ngIf="canDeleteMission"
                color="danger" (click)="handleDeleteMission()">
            <ion-icon name="trash-outline"></ion-icon>
            {{ 'mission_delete' | translate }}
        </ion-button>

        <ion-button
                *ngIf="canAcceptOrDeclineMission"
                color="danger" (click)="handleDeclineMission()">
            <ion-icon name="hand-left-outline"></ion-icon>
            {{ 'mission_decline' | translate }}
        </ion-button>

        <ion-button
                *ngIf="canAcceptOrDeclineMission"
                color="primary" (click)="handleAcceptMission()">
            <ion-icon name="thumbs-up-outline"></ion-icon>
            {{ 'mission_accept' | translate }}
        </ion-button>
    </ion-buttons>
</article>

<ng-template #row>
    <td>{{mission.date_debut_mission | date: 'dd/MM/yyyy'}}</td>
    <td>{{mission.date_creation_mission | date: 'dd/MM/yyyy'}}</td>
    <td>{{mission.installateur?.Nom}} {{mission.installateur.prenom}}</td>
    <td>
        <app-status-badge [status]="mission.statut_mission"></app-status-badge>
    </td>
    <td>
        <p *ngFor="let site of mission.nombre_site">{{site}}</p>
    </td>
    <td>
        {{mission.interventions.length}}
    </td>
    <td>
        <ion-button color="secondary" size="small"
                    [routerLink]="['/mission/'+ mission.id_mission]"
                    [queryParams]="{type_demandeur: typeDemandeur}"
        >
            <ion-icon name="eye-outline"></ion-icon>
            {{ 'mission_show' | translate }} {{mission.id_mission}}
        </ion-button>
    </td>
    <td>
        <ion-button
                *ngIf="canDeleteMission"
                color="danger" size="small" (click)="handleDeleteMission()">
            {{ 'mission_delete' | translate }}
        </ion-button>

        <ion-button
                *ngIf="canAcceptOrDeclineMission"
                color="danger" size="small" (click)="handleDeclineMission()">
            {{ 'mission_decline' | translate }}
        </ion-button>

        <ion-button
                *ngIf="canAcceptOrDeclineMission"
                color="primary" size="small" (click)="handleAcceptMission()">
            {{ 'mission_accept' | translate }}
        </ion-button>

        <!-- <ion-button *ngIf="utils.checkUser(user,18) && this.type_demandeur=='installateur' && mission.statut_mission =='INTERV_STATUT_ACCEPTEE'" color="danger" size="small" (click)="problemeMission(mission.id_mission)">Probleme avec la mission</ion-button>
        <ion-button *ngIf="utils.checkUser(user,18) && this.type_demandeur=='installateur' && mission.statut_mission =='INTERV_STATUT_ACCEPTEE'" color="primary" size="small" (click)="valideFinMission(mission.id_mission)">Valider la mission</ion-button> -->

        <!-- <ion-button *ngIf="utils.checkUser(user,18) && this.type_demandeur=='admin' && mission.statut_mission =='INTERV_STATUT_REALISEE'" color="primary" size="small" (click)="valideConformiteMission(mission.id_mission)">Valider la conformité de la mission</ion-button>
        <ion-button *ngIf="utils.checkUser(user,18) && this.type_demandeur=='admin' && mission.statut_mission =='INTERV_STATUT_REALISEE'" color="danger" size="small" (click)="invalideConformiteMission(mission.id_mission)">Invalider la conformité de la mission</ion-button> -->
    </td>
</ng-template>
