<div class="anchor-layout-grid">
    <nav class="bg-surface-2 overflow-x-auto">
        <div class="action-list">
            <app-button
                    variant="outline"
                    (click)="back()"
                    fill="block"
                    class="mb-md-lg"
                    icon="arrow-back-outline"
            >
                {{'back'|translate}}
            </app-button>

            <app-button *ngFor="let anchor of anchors"
                        appScrollTo="{{anchor.target}}"
                        variant="ghost"
                        color="{{anchor.color}}"
                        icon="{{anchor.icon}}"
                        fill="block"
            >
                {{anchor.label|translate}}
            </app-button>
        </div>
    </nav>
    <section class="main bg-surface-1 overflow-y-auto" #main>
        <div class="container my-md my-md-xl">
            <app-loader *ngIf="isLoading;else content"
                        [loading]="true"
                        [message]="loadingMsg"
            ></app-loader>
            <ng-template #content>
                <ng-content></ng-content>
            </ng-template>
        </div>
    </section>
</div>
