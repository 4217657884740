<div class="grid gap-sm">
    <div class="col-span flex items-center gap-xs">
        <h2 class="heading flex items-center gap-xs flex-1">
            {{ 'remarques'|translate }}
        </h2>
        <div class="flex gap-xs">
        </div>
    </div>

    <form (submit)="postRemarque()" class="contents flex-md gap-xs items-end">
        <app-input-simple
                class="flex-1"
                [label]="'nouvelle_remarque'"
                [placeholder]="'Votre texte'"
                inputClass="bg-surface-1"
                [control]="noteControl"
                prefix="pencil"
        ></app-input-simple>

        <app-button class="justify-self-end"
                    [disabled]="loading || noteControl?.value?.trim().length === 0"
        >
            <ion-icon icon="checkmark"></ion-icon>
            {{ 'enregistrer'|translate }}
        </app-button>
    </form>

    <div class="text-end empty-hidden" data-from="right">
        <p *ngIf="success === true" aria-atomic="true" class="text-primary">
            {{ 'notes_enregistrees_success' | translate }}
        </p>
        <p *ngIf="success === false" aria-atomic="true" class="text-danger">
            {{ 'error_save' | translate }}
        </p>
    </div>

    <ul class="empty-hidden clean-list grid scrollable" style="max-height: 20rem">
        <li *ngFor="let remarque of remarques" class="even-bg-surface-3 px-md py-xs">
            <app-remarque-sujet-item
                    [remarque]="remarque"
            ></app-remarque-sujet-item>
        </li>
    </ul>
</div>
