import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectInfo} from '../../../interfaces/IProjet';
import {Observable, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/internal/operators/shareReplay';
import {EVService} from '../../../services/ev.service';

@Component({
    selector: 'app-my-projects-section',
    templateUrl: './my-projects-section.component.html',
    styleUrls: ['./my-projects-section.component.scss'],
})
export class MyProjectsSectionComponent implements OnInit, OnDestroy {
    projects$: Observable<ProjectInfo[]> = this.ev.projects$;
    projects: ProjectInfo[];
    loading = false;
    error = false;
    subscription: Subscription;

    constructor(
        private ev: EVService,
    ) {
    }

    ngOnInit() {
        this.fetchProjects();
    }

    fetchProjects() {
        this.loading = true;
        this.error = false;

        // Ensure we fetch only own projects
        this.ev.isSuperAdmin$.subscribe(isAdminVue => {
            if (isAdminVue === true) {
                this.ev.isSuperAdmin = false;
            }
            this.subscription = this.projects$.subscribe({
                next: (projects) => {
                    this.projects = projects;
                    this.loading = false;
                },
                error: (err) => {
                    console.error(err);
                    this.error = true;
                    this.loading = false;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

}
