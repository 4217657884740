<a *ngIf="item.routerLink; else notClickable"
   [routerLink]="item.routerLink"
   [queryParams]="item.queryParams ? item.queryParams : {}"
   class="flex items-center gap-xs primary-link text-muted uppercase fw-bold whitespace-nowrap">
    <ion-icon *ngIf="item.icon" [icon]="item.icon" [color]="item.color ? item.color : 'auto'"></ion-icon>
    {{ item.label|translate }}
</a>

<ng-template #notClickable>
    <span class="flex items-center gap-xs text-muted uppercase fw-bold whitespace-nowrap">
        <ion-icon *ngIf="item.icon" [icon]="item.icon" [color]="item.color ? item.color : 'auto'"></ion-icon>
        {{ item.label|translate }}
    </span>
</ng-template>
