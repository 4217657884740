<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
        </ion-buttons>
        <ion-title>&nbsp;</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-searchbar placeholder="" (keyup)="onSearch($event)" (ionClear)="clearF()"></ion-searchbar>
    <table matSort (matSortChange)="sortData($event)" style="width:100%">
        <tbody>
        <tr>
            <th mat-sort-header="nom">{{'nom'|translate}}</th>
            <th mat-sort-header="prenom">{{'prenom'|translate}}</th>
            <th mat-sort-header="organisation">{{'organisation'|translate}}</th>
            <th>Selectionner</th>
        </tr>
        <tr *ngFor="let installateur of sortedData;let i = index;">
            <td>{{ installateur?.Nom }}</td>
            <td>{{ installateur?.prenom }} </td>
            <td>{{installateur?.organisation}}</td>
            <td>
                <ion-button size="small" (click)="selectInstallateur(installateur)">Sélectionner</ion-button>
            </td>
        </tr>
        </tbody>
    </table>
</ion-content>
