import {Injectable} from '@angular/core';
import {InputTypeEnum} from '../enums/InputTypeEnum';
import {FileTypeEnum} from '../enums/FileTypeEnum';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    AbstractFormField,
    Choice,
    FileValue,
    IFormStep,
    IModularFormData,
    IModularFormPayload,
    InputValue,
    ModularFormField
} from '../interfaces/IFormStep';
import {FormGroupStatusEnum} from '../enums/FormGroupStatusEnum';
import {StorageService} from './storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ComboBoxOption} from "../components/molecules/combobox/combobox.component";
import {ProjectInfo} from "../interfaces/IProjet";
import {ISujet} from "../interfaces/ISujet";


@Injectable({
    providedIn: 'root'
})
export class ModularFormService {
    readonly KEY_SEPARATOR = '_';
    readonly FORM_STORAGE_KEY = 'form_storage';
    // Enums
    types: typeof InputTypeEnum = InputTypeEnum;
    fileTypes: typeof FileTypeEnum = FileTypeEnum;
    status: typeof FormGroupStatusEnum = FormGroupStatusEnum;

    private isSubmittingEvent = new BehaviorSubject<boolean>(false);
    isSubmitting$ = this.isSubmittingEvent.asObservable();

    constructor(
        private formBuilder: FormBuilder,
        private storage: StorageService,
    ) {
    }

    turnFieldsIntoFormGroup(fields: ModularFormField[], rawGroup = {}): FormGroup {
        const DEFAULT_CONTROL = [null, Validators.compose([])];
        fields.forEach((field) => {
            // Input type NOT should not be handled
            if (field.type !== InputTypeEnum.NOT) {
                if (field.type === InputTypeEnum.FILE) {
                    const fileValues: FileValue[] = Array.isArray(field.control[0]) ? field.control[0] as FileValue[] : [];
                    rawGroup[field.key] = this.formBuilder.array([], field.control[1], field.control[2]);
                    fileValues.forEach((fv: FileValue, i: number) => {
                        // If File field is not multiple, we inject only the first file
                        if ((field.multiple && i > 0) || i === 0) {
                            fv.file = this.generateFileFromFileValue(fv);
                            rawGroup[field.key].push(this.formBuilder.group(fv));
                        }
                    });
                } else if (field.type === InputTypeEnum.ACCORDEON) {
                    for (const group of field.accordeonGroups) {
                        this.turnFieldsIntoFormGroup(group.fields, rawGroup);
                    }
                } else if (field.type === InputTypeEnum.TABLE) {
                    // Need to dynamically generate a FormControl foreach row of the table
                    field.tableFields = field.tableFields ?? [];
                    field.tableCommentFields = field.tableCommentFields ?? [];
                    for (const rowField of field.tableFields) {
                        rawGroup[rowField.key] = rowField.control ?? DEFAULT_CONTROL;
                    }
                    for (const commentRowField of field.tableCommentFields) {
                        rawGroup[commentRowField.key] = commentRowField.control ?? DEFAULT_CONTROL;
                    }
                } else {
                    rawGroup[field.key] = field.control ?? DEFAULT_CONTROL;
                }
            }
        });

        return this.formBuilder.group(rawGroup);
    }

    generateTranslationKey(field: ModularFormField, prefix: string): void {
        if (field.label != null || prefix == null) {
            return;
        }
        field.label = `${prefix}.${field.key}`;
    }

    /**
     * Based on IFormStep[], retrieves every input key and value
     * and returns theme in a flat object.
     */
    getModularFormPayload(steps: IFormStep[], ignoreIgnoredKeys = true): IModularFormPayload {
        const formData = {};
        const files = {};

        const formGroups = this.extractFormGroups(steps);
        const fileKeys = this.getInputFileKeys(steps);
        const ignoredKeys = ignoreIgnoredKeys ? this.getIgnoredKey(steps) : [];

        for (const formGroup of formGroups) {
            Object.keys(formGroup.controls)
                .forEach(key => {
                    if (!ignoredKeys.includes(key)) {
                        if (fileKeys.includes(key)) {
                            files[key] = formGroup.controls[key].value;
                        } else {
                            formData[key] = formGroup.controls[key].value;
                        }
                    }
                });
        }

        return {
            formData,
            files,
            isValid: this.areValidFormGroups(formGroups),
            createdAt: new Date(),
        };
    }

    getFlatAllFieldList(steps: IFormStep[]) {
        return steps.map((step) => this.getFlatFieldList(step))
            .reduce((prev, curr) => {
                return prev.concat(curr);
            }, []);
    }

    getInputFileKeys(steps: IFormStep[]): string[] {
        return this.getFlatAllFieldList(steps)
            .filter(f => f.type === InputTypeEnum.FILE)
            .map(f => f.key)
            ;
    }

    getIgnoredKey(steps: IFormStep[]): string[] {
        return this.getFlatAllFieldList(steps)
            .filter(f => f.ignored)
            .map(f => f.key)
            ;
    }

    extractFormGroups(steps: IFormStep[]): FormGroup[] {
        return steps.map(step => step.formGroup);
    }

    /**
     * Based on IFormStep[], retrieves every input key and value
     * and returns them in dom FormData.
     * By default retrieves only Files
     */
    getFormData(formGroups: FormGroup[], filesOnly = true): FormData {
        const formData = new FormData();
        formGroups.forEach(formGroup => {
            Object.keys(formGroup.controls)
                .forEach(key => {
                    const value = formGroup.controls[key].value;
                    if (Array.isArray(value) && value[0]?.file) {
                        // Handling File data
                        value.forEach((fileValue: FileValue) => {
                            formData.append(key, fileValue.file);
                        });
                    } else if (!filesOnly) {
                        formData.append(key, this.convertToFormDataValue(value));
                    }
                });
        });
        return formData;
    }

    private convertToFormDataValue(value: any): any {
        if (typeof value === 'object') {
            return JSON.stringify(value);
        }
        return value;
    }

    /**
     * Checks if there is any error in a FormGroup
     */
    public isValidFormGroup(formGroup: FormGroup): boolean {
        return formGroup.status === this.status.VALID;
    }

    /**
     * Checks if there is any error in an array of FormGroups
     */
    areValidFormGroups(formGroups: FormGroup[]): boolean {
        return !formGroups.some(g => g.status !== this.status.VALID);
    }

    /**
     * Checks if a given input has been interacted with and is invalid
     */
    isInvalidTouchedInput(control: AbstractControl): boolean {
        return control?.invalid && (control.touched || control.dirty);
    }

    /**
     * Fills an empty IFormStep[] with data from a previous submission.
     * Used to allow Edit mode in the ModularForm component
     */
    populateWithPreviousData(steps: IFormStep[], oldPayload: IModularFormPayload): IFormStep[] {
        // Generating Fields for each table raw so that they can be
        // treated as regular fields later
        this.generateTableFieldsRows(steps);

        // Ensure oldData is an object
        const oldData = oldPayload?.formData ?? {};
        const oldFiles = oldPayload?.files ?? {};

        const INITIAL_VALUE_INDEX = 0;
        for (const step of steps) {
            const flatFieldList = this.getFlatFieldList(step);
            for (const field of flatFieldList) {
                if (!this.isPossibleToPopulate(field)) {
                    continue;
                }
                if (field.type === InputTypeEnum.FILE && oldFiles[field.key] != null) {
                    const oldFile = oldFiles[field.key];
                    field.control[INITIAL_VALUE_INDEX] = oldFile;
                } else if (oldData[field.key] != null) {
                    // If the given field exists, we populate it
                    let oldValue = oldData[field.key];

                    // Value might need to be parsed before being injected into a FormControl
                    oldValue = Array.isArray(oldValue) ?
                        this.handleIterableValue(oldValue, field) : this.handleSingularValue(oldValue, field);

                    if (oldValue != null && field.control != null) {
                        // Setting a new control to avoid object reference issues
                        field.control = [oldValue, field.control[1], field.control[2]];
                    }
                }
            }
        }

        return steps;
    }

    /**
     * Checks if the given field can be pre-populated on edition mode
     */
    isPossibleToPopulate(field: ModularFormField): boolean {
        return ![
            InputTypeEnum.NOT,
            InputTypeEnum.ACCORDEON,
        ].includes(field.type);
    }

    /**
     * Checks if a given choiceValue is a valid Option for a given SELECT type
     * FormField.
     */
    private hasChoice(choiceValue: InputValue, field: ModularFormField): boolean {
        if (this.isChoiceInputType(field)) {
            return field.choices?.some(choice => choice.value == choiceValue);
        }
        return false;
    }

    private isEmptyArray(value: InputValue): boolean {
        return Array.isArray(value) && value.length === 0;
    }

    private getOneValidValueFromIterable(values: InputValue[], field: ModularFormField): InputValue | null {
        const onlyValidValues = values.filter(v => this.hasChoice(v, field));
        return onlyValidValues[0] ?? null;
    }

    private handleIterableValue(iterableValue: InputValue[], field: ModularFormField): InputValue | InputValue[] {
        if (field.type === InputTypeEnum.SELECT && field.choices) {
            if (field.multiple) {
                // And we expect an iterable InputValue
                return iterableValue.filter((v, i) => this.hasChoice(v, field));
            } else {
                return this.getOneValidValueFromIterable(iterableValue, field);
            }
        }

        // We simply keep the first value
        return iterableValue[0] ?? null;
    }

    private isExpectingIterableValue(field: AbstractFormField): boolean {
        return field.multiple || field.type === InputTypeEnum.CHECKLIST;
    }

    private handleSingularValue(value: InputValue, field: ModularFormField): InputValue | InputValue[] {
        if (this.isExpectingIterableValue(field)) {
            return this.parseSingularValueToIterable(value, field);
        }
        return this.parseValueToFieldType(value, field);
    }

    private parseSingularValueToIterable(
        value: InputValue,
        field: ModularFormField
    ): InputValue[] {
        // If the choice is valid but the input expect an iterable we parse it
        if (this.isChoiceInputType(field)) {
            return this.hasChoice(value, field) ? [value] : [];
        }
        return [value];
    }

    private parseValueToFieldType(value: InputValue, field: ModularFormField): InputValue {
        if (field.type === InputTypeEnum.CHECKBOX) return Boolean(value);
        if (field.type === InputTypeEnum.NUMBER) return Number(value);
        if (field.choices) return this.hasChoice(value, field) ? value : null;
        // TODO handle GeoInput case
        return value;
    }

    /**
     * Dynamically generate translation key for SELECT and RADIO choices
     */
    getChoiceTranslationKey(choice: Choice, field: ModularFormField): string {
        return choice.label ?? `${field.label}${this.KEY_SEPARATOR}${choice.value}`;
    }


    /**
     * Use carefully, only to prepare data to be edited !
     * Forces an object conversation to a IModularFormData.
     */
    public parseObjectToModularFormData<T>(object: object): IModularFormData {
        const raw: unknown = object;
        return raw as IModularFormData;
    }

    /**
     * Parse any formData to its original model/interface.
     * Awful solution but it gets the job done.
     */
    public parseFormDataToOriginal<T>(formData: IModularFormData): T {
        const raw: unknown = formData;
        return raw as T;
    }

    /**
     * Check if a blob of this file type can be shown in an img tag
     */
    public isPreviewable(type: FileTypeEnum | string) {
        return [
            FileTypeEnum.JPG,
            FileTypeEnum.PNG,
            FileTypeEnum.IMAGE,
        ].includes(type as FileTypeEnum);
    }


    /**
     * Finds and returns an Abstract Control in a IFormStep[] based on its key
     */
    public getControlByKey(key: string, steps: IFormStep[]): AbstractControl {
        for (const step of steps) {
            const control = step.formGroup.get(key);
            if (control) {
                return control;
            }
        }
        return null;
    }

    /**
     * Deduce where the user should be redirected on a "previous" click based on visited
     * steps.
     */
    public getPreviousAutoStep(history: number[], currentStep: number): number {
        for (let i = history.length - 1; i >= 0; i--) {
            if (history[i] < currentStep) {
                return history[i];
            }
        }
        return 0;
    }

    public toggleEnableOnControl(
        control: AbstractControl,
        isEnabled: boolean = true,
        opts = {}): void {
        if (isEnabled) {
            control.enable(opts);
        } else {
            control.disable(opts);
        }
    }

    getFlatFieldList(step: IFormStep): ModularFormField[] {
        const fieldList = [];
        step.fields.forEach(field => {
            this.extractFieldsFromField(field, fieldList);
        });

        return fieldList;
    }

    extractFieldsFromField(field: ModularFormField, fieldList: ModularFormField[] = []): ModularFormField[] {
        if (field.type === InputTypeEnum.ACCORDEON) {
            field.accordeonGroups.forEach(group => {
                group.fields.forEach(f => {
                    this.extractFieldsFromField(f, fieldList);
                });
            });
        } else if (field.type === InputTypeEnum.TABLE) {
            // Table Input has extra fields we need to extract separately
            if (field.tableFields) {
                fieldList.push(...field.tableFields); // One field per row
            }
            if (field.tableCommentFields) {
                fieldList.push(...field.tableCommentFields); // One extra field per row if commentKey was given
            }
        } else {
            fieldList.push(field);
        }

        return fieldList;
    }

    generateFileFromFileValue(fileValue: FileValue) {
        return new File([],
            fileValue.fileName,
            {type: this.extractFileTypeFromBlob(fileValue.blob)}
        );
    }

    /**
     * Extract the file type from a base64 blob string
     */
    extractFileTypeFromBlob(blob: string | ArrayBuffer): string | FileTypeEnum {
        return /data:(?<filetype>[^;]*)/.exec(blob as string).groups?.filetype;
    }

    generateTableFieldsRows(steps: IFormStep[]): void {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field.type === InputTypeEnum.TABLE) {
                    field.tableFields = field.tableRows.map((row) => {
                        return {
                            key: `${field.key}${this.KEY_SEPARATOR}${row}`,
                            control: field.multiple ? [[]] : [...field.control],
                            type: field.multiple ? InputTypeEnum.SELECT : InputTypeEnum.RADIO,
                            choices: field.choices,
                            multiple: field.multiple ?? false,
                        };
                    });
                    field.tableCommentFields = [];
                    if (field.tableCommentKey) {
                        field.tableCommentFields = field.tableRows.map((row) => {
                            return {
                                key: `${field.key}${this.KEY_SEPARATOR}${field.tableCommentKey}${this.KEY_SEPARATOR}${row}`,
                                control: [null],
                                type: InputTypeEnum.TEXTAREA,
                            };
                        });
                    }
                }
            }
        }
    }

    isChoiceInputType(field: ModularFormField): boolean {
        return [
            InputTypeEnum.CHECKBOX,
            InputTypeEnum.SELECT,
            InputTypeEnum.RADIO,
            InputTypeEnum.RADIO_VERTICAL,
            InputTypeEnum.CHECKLIST
        ].includes(field.type);
    }

    parseChoice(
        value: InputValue,
        label: string = null,
    ): Choice {
        return {
            value,
            label,
        };
    }

    parseChoices(values: InputValue[]): Choice[] {
        return values.map(v => this.parseChoice(v));
    }

    parseEnumToChoices(enumObj: object): Choice[] {
        return this.parseChoices(Object.values(enumObj));
    }

    emitIsSubmittingEvent(isSubmitting = false) {
        this.isSubmittingEvent.next(isSubmitting);
    }

    /**
     * Launch an API call but makes sure to set the isSubmitting Event to true
     * just before the call and turns it back to false once the call is done.
     */
    callSubmitEvent<T>(
        observable: Observable<T>,
        onSuccess: CallableFunction = (res: T) => console.log(res),
        onError: CallableFunction = (error: string) => console.log(error),
    ) {
        this.isSubmittingEvent.next(true);
        observable.subscribe(async (res) => {
            await onSuccess(res);
            this.isSubmittingEvent.next(false);
        }, async (error) => {
            await onError(error);
            this.isSubmittingEvent.next(false);
        });
    }

    isFormPayload(maybePayload: any): boolean {
        return maybePayload && typeof maybePayload === 'object'
            && 'formData' in maybePayload
            && 'files' in maybePayload
            && 'createdAt' in maybePayload
            && 'isValid' in maybePayload;
    }


    removeFormDataFromStorage(key: string, subKey: string = null) {
        if (null === subKey) {
            // Simply removing data
            this.storage.removeKey(this.FORM_STORAGE_KEY, key);
        } else {
            // Removing nested data at key location and overwriting it
            const currentStorageState = this.storage.getKey(
                this.FORM_STORAGE_KEY,
                key,
            );
            delete currentStorageState[subKey];
            this.storage.setKey(this.FORM_STORAGE_KEY, key, currentStorageState);
        }
    }

    generateRandomStorageKey(): string {
        return new Date().getTime().toString();
    }

    projectInfoIntoComboboxOption(project: ProjectInfo): ComboBoxOption {
        return {
            value: project.id_sit as string,
            label: project.nom_sit,
        };
    }

    sujetIntoComboboxOption(sujet: ISujet): ComboBoxOption {
        return {
            value: sujet.id_suj ? sujet.id_suj.toString() : sujet.id.toString(),
            label: sujet.nom_suj,
        };
    }
}
