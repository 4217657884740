<canvas #canvas [width]="canvasWidth" [height]="canvasHeight"
        class="rounded-sm"
></canvas>

<img [src]="imageSrc" (load)="imageLoaded = true"
     class="rounded-sm"
     style="visibility: hidden !important;"
/>

<div
        class="rounded-sm"
        [ngClass]="{'img-loaded': imageLoaded}"
     [ngStyle]="this.generateStyle()"

     style="
      background-repeat: no-repeat;
      height:100%;
      width:100%;
"></div>
