<div class="desktop-only no-interaction">
    <mat-horizontal-stepper linear>
        <mat-step *ngFor="let step of steps; let i = index" label="{{ step | translate }} {{ (i ===3)?date_mission:''}}"
                  [completed]="progressionLevel > i"
                  [editable]="false"
                  [state]="'chat'"
        >
            <ng-template matStepperIcon="number">
                <mat-icon>
                    <mat-icon>done</mat-icon>
                </mat-icon>
            </ng-template>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<div class="responsive-only no-interaction">
    <mat-vertical-stepper linear>
        <mat-step *ngFor="let step of steps; let i = index" label="{{ step | translate}} {{(i ===3)?date_mission:''}}"
                  [completed]="progressionLevel > i"
                  [editable]="false"
        >
            <ng-template matStepperIcon="number">
                <mat-icon>
                    <mat-icon>done</mat-icon>
                </mat-icon>
            </ng-template>
        </mat-step>
    </mat-vertical-stepper>
</div>

