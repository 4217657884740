<div class="flex flex-column gap-xs"
     [ngClass]="containerClass"
>
    <app-label *ngIf="label"
               [label]="label"
               [for]="id"
    ></app-label>

    <div class="urbainput flex items-center gap-sm"
         [ngClass]="inputClass"
    >
        <ion-icon *ngIf="prefix" icon="{{prefix}}"></ion-icon>
        <input type="{{ type }}"
               #input
               id="{{ id }}"
               class="flex-1"
               [value]="value"
               (input)="onInput($event)"
               (blur)="onBlur()"
               [formControl]="control"
               [placeholder]="placeholder | translate"
               [attr.autocomplete]="autocomplete"
               [attr.aria-controls]="ariaControls ? ariaControls : null"
               [attr.aria-expanded]="ariaExpanded ? ariaExpanded : null"
               [attr.aria-autocomplete]="ariaAutoComplete ? ariaAutoComplete : null"
        />


<!--        // todo turn this into a "button" tag without style-->
        <ion-icon *ngIf="control.value.length && resetable"
                  icon="close"
                  tabindex="0"
                  data-from="right"
                  class="cursor-pointer"
                  (click)="reset($event)"
                  (keydown.enter)="reset($event)"
        ></ion-icon>

        <!--        // todo turn this into a "button" tag without style-->
        <ion-icon *ngIf="togglePassword"
                  icon="eye"
                  tabindex="0"
                  class="cursor-pointer"
                  (click)="togglePasswordView()"
                  (keydown.enter)="togglePasswordView()"
        ></ion-icon>

        <ion-icon *ngIf="suffix" icon="{{suffix}}"></ion-icon>
    </div>
</div>

