<ul data-from="top" class="form-error-list" *ngIf="errors">
    <ng-container *ngFor="let error of errors | keyvalue">
        <li *ngIf="error.value.link == null;else linkError">
            {{ 'formErrors.' + error.key | translate: error.value }}
        </li>
        <ng-template #linkError>
            <li class="error-link">
                <a href="{{error.value.link}}">
                    {{ 'formErrors.' + error.key | translate: error.value }}
                </a>
            </li>
        </ng-template>
        <!--        <ng-template #linkError>-->
        <!--            <li [routerLink]="[error.value.link]" class="error-link">-->
        <!--                {{ 'formErrors.' + error.key | translate: error.value }}-->
        <!--            </li>-->
        <!--        </ng-template>-->
    </ng-container>
</ul>
