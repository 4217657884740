<article class="urbacard rounded-sm shadow bg-surface-2">
    <header>
        <h3 class="heading">{{person.Nom}} {{person.prenom}}</h3>
        <span *ngIf="person.site_fonction" class="text-muted">{{person.site_fonction}}</span>
    </header>

    <a *ngIf="person.mail" class="flex flex-wrap items-baseline primary-link" href="mailto:{{person.mail}}">
        <ion-icon icon="mail" class="me-sm"></ion-icon>
        {{person.mail}}
    </a>
    <a *ngIf="person.tel" class="flex flex-wrap items-baseline primary-link" href="tel:{{person.tel}}">
        <ion-icon icon="call" class="me-sm"></ion-icon>
        {{person.tel}}
    </a>
</article>
