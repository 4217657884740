import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModularFormService} from '../../../services/modular-form.service';

@Component({
    selector: 'app-modular-form-nav',
    templateUrl: './modular-form-nav.component.html',
    styleUrls: ['./modular-form-nav.component.scss'],
})
export class ModularFormNavComponent implements OnInit {
    @Input() currentStepIndex: number;
    @Input() stepsTotal: number;
    @Input() isEditMode = false;
    @Input() hasPrevFn = false;
    @Input() hasNextFn = false;
    @Input() showSaveBtn = false;

    @Input() isCompletedStep = true;
    @Input() canGoBack = true;

    @Output() onPrev: EventEmitter<void> = new EventEmitter();
    @Output() onNext: EventEmitter<void> = new EventEmitter();
    @Output() onFormSubmit: EventEmitter<void> = new EventEmitter();
    @Output() onSave: EventEmitter<void> = new EventEmitter();


    constructor(
        public formService: ModularFormService,
    ) {
    }

    ngOnInit() {
    }

    handlePrev() {
        this.onPrev.emit();
    }

    handleNext() {
        this.onNext.emit();
    }

    handleSubmit() {
        this.onFormSubmit.emit();
    }

    handleSave() {
        this.onSave.emit();
    }

    isNotFirstStep() {
        return this.currentStepIndex !== 0;
    }

    isLastStep() {
        return this.currentStepIndex === this.stepsTotal - 1;
    }
}
