import {ModalController, ToastController} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {AidegeolocPage} from '../aidegeoloc/aidegeoloc.page';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';


import {environment} from '../../environments/environment';
import {UserRolesEnum} from '../enums/UserRolesEnum';
import {IPosition} from '../interfaces/IPosition';
import {IonicColorKeyEnum} from '../enums/IonicColorKeyEnum';
import {DatePeriod} from '../interfaces/DatePeriod';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ParamMap} from '@angular/router';

type ToastPosition = 'bottom' | 'top' | 'middle';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    readonly queryParamSeparator = ':';
    today: string;
    public key_role = environment.key_roles;
    public dateFormat = 'dd/MM/yyyy';
    readonly toastDuration = {
        short: 3000,
        medium: 5000,
        long: 8000,
    };

    constructor(
        private toastController: ToastController,
        private modalController: ModalController,
        private datePipe: DatePipe,
        private _sanitizer: DomSanitizer,
        private translate: TranslateService
    ) {
    }

    public getValeur(sujet?: any) {
        if (sujet && sujet.teadexpert) {
            if (sujet.teadexpert.moisture) {
                return Math.ceil(sujet.teadexpert.moisture * 100);
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    public compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public getSVGImageUrl(image: string): SafeResourceUrl {
        const base64string = btoa(image);
        return this._sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/svg+xml;base64,${base64string}`
        );
    }

    public getSVGImage(image: string): SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/svg+xml;base64,${image}`
        );
    }

    checkUser(user, role: UserRolesEnum) {
        return user[this.key_role + 'roles'].indexOf(role) > -1;
    }


    public transformTime(value: number): string {
        if (!value) {
            return this.translate.instant('Aucune activité');
        }
        let minutes: number = Math.trunc(value / 60);
        let hours: number = 0;
        let seconds: number = value - (minutes * 60);

        if (minutes >= 60) {
            hours = Math.trunc(minutes / 60);
            minutes = minutes - (hours * 60);
        }

        let response: string = "";

        if (hours > 0) {
            response = response + hours + " heures le";
        }

        if (minutes > 0) {
            response = response + minutes + " minutes le";
        }

        if (seconds > 0) {
            response = response + seconds + " secondes le";
        }

        return response;
    }

    public async presentModalHelp() {
        const modal = await this.modalController.create({
            component: AidegeolocPage,
            cssClass: 'modal-screen'
        });
        return await modal.present();
    }

    public getGaugeColor(sujet?: any) {
        let color = 'black';
        if (sujet && sujet.teadexpert) {
            if (sujet.teadexpert.moisture) {
                if ((sujet.teadexpert.moisture) > 0.9) {
                    color = '#406AE0';
                } else if ((sujet.teadexpert.moisture) >= 0.5 && (sujet.teadexpert.moisture) <= 0.9) {
                    color = 'rgb(85, 194, 119)';
                } else if ((sujet.teadexpert.moisture) >= 0.1 && (sujet.teadexpert.moisture) <= 0.5) {
                    color = '#FAB51A';
                } else if ((sujet.teadexpert.moisture) < 0.1) {
                    color = 'red';
                }
            }
        }
        return color;
    }

    public getCouleurVbat(valeur) {
        let color = '#55c277';
        switch (valeur) {
            case valeur >= 5500:
                color = '#55c277';
                break;
            case valeur < 5500 && valeur >= 5000:
                color = '#FFA500';
                break;
            case valeur < 5000:
                color = '#eb3013';
                break;
        }
        return color; // Remplacer ceci par votre propre logique
    }

    public getValeurS(sujet?: any) {
        //On doit extraire dernier element de la collection

        if (sujet && sujet.txp_moisture.result) {
            if (sujet.txp_moisture.result.moistures) {

                if (sujet.txp_moisture.result.moistures.length > 0) {
                    if (Math.ceil(sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1] * 100) > 0) {
                        return Math.ceil(sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1] * 100);
                    } else {
                        return 0;
                    }

                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    public getGaugeColorS(sujet?: any) {
        let color = 'black';
        if (sujet && sujet.txp_moisture.result) {
            if (sujet.txp_moisture.result.moistures) {
                if (sujet.txp_moisture.result.moistures.length > 0) {
                    if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) > 0.9) {
                        color = '#406AE0';
                    } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) >= 0.5 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.9) {
                        color = 'rgb(85, 194, 119)';
                    } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) >= 0.1 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.5) {
                        color = '#FAB51A';
                    } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.1) {
                        color = 'red';
                    }
                }
            }
        }
        return color;
    }

    public ConvertToInt(val) {
        return parseInt(val);
    }

    /**
     * Parse a string to an IPosition object
     */
    public parsePosition(pos: string): IPosition {
        const DEFAULT_ZOOM = 14;
        if (pos === '' || pos == null) {
            // Position par défaut en cas d'erreur
            return {
                lat: 0,
                lng: 0,
                zoom: 5,
            };
        }

        const regex = /\((?<lat>-?\d+\.?\d+),\s?(?<lng>-?\d+\.?\d+)\):?(?<zoom>\d+)?/;
        const result = regex.exec(pos);

        // Vérifiez si le résultat est non nul avant de déstructurer
        if (result && result.groups) {
            const {lat, lng, zoom} = result.groups;
            return {
                lat: Number(lat),
                lng: Number(lng),
                zoom: Number(zoom) ?? DEFAULT_ZOOM,
            };
        }

    }

    getCouleurSonde(val) {
        let color = '#55c277';
        if (val >= 256) {
            color = 'red';
        }
        return color;
    }


    analyseSonde(sujet) {
        let abberrantValue = false;
        sujet.trame_avg = 720;
        if (sujet.trames) {
            if (sujet.trames[0]) {
                if (sujet.trames[0].messages) {
                    if (sujet.trames[0].messages.decode.length > 0) {
                        let index = 0;
                        for (var i = 0; i < sujet.trames[0].messages.decode.length; i++) {
                            if (sujet.trames[0].messages.decode[i].valeur >= 256 && sujet.trames[0].messages.decode[i].type != 'mvolt') {
                                abberrantValue = true;
                            }

                            if (sujet.trames[0].messages.decode[i].valeur < 5000 && sujet.trames[0].messages.decode[i].type == 'mvolt') {
                                abberrantValue = true;
                            }
                            if (index >= sujet.nb_sond) {
                                break;
                            } else {
                                index = index + 1;
                            }
                        }
                    }
                    var date1 = new Date(sujet.trames[0].messages.date_mysql.replace(' ', 'T'));
                    var date2 = new Date(sujet.trames[sujet.trames.length - 1].messages.date_mysql.replace(' ', 'T'));
                    var diff = this.diff_minutes(date1, date2);
                    sujet.trame_avg = diff / sujet.trames.length;
                }
            }
        }
        sujet.aberrantValeur = abberrantValue;
        return sujet;
    }

    calculerCouleurSondeMvolt(trame) {
        let color = '#55c277';
        if (!trame.valeur || trame.valeur < 10) {
            color = 'gray';
        } else if (trame.valeur > 10 && trame.valeur < 4500) {
            color = 'red';
        } else if (trame.valeur > 4500 && trame.valeur < 5300) {
            color = 'orange';
        } else {
            color = '#55c277';
        }
        return color;
    }

    calculerCouleurSondeWLref(trame) {
        let color = '#55c277';
        if (!trame.valeur || trame.valeur == 0 || trame.valeur > 40000) {
            color = "red";
        }
        return color;
    }

    diff_minutes(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    public days_between_detail(objetVegetal) {
        if (objetVegetal.txp_nextwatering) {
            if (objetVegetal.txp_nextwatering.result['next-watering']) {
                if (new Date(objetVegetal.txp_nextwatering.result['next-watering']) < new Date()) {
                    return '0';
                }
                const date1 = Date.now();
                // The number of milliseconds in one day
                const ONE_DAY = 1000 * 60 * 60 * 24;
                if (objetVegetal.txp_nextwatering.result['next-watering'] != 'none') {
                    objetVegetal.txp_nextwatering.result['next-watering'] = new Date(objetVegetal.txp_nextwatering.result['next-watering']);
                    // Calculate the difference in milliseconds
                    const differenceMs = Math.abs(objetVegetal.txp_nextwatering.result['next-watering'] - date1);
                    if (Math.round(differenceMs / ONE_DAY) > 14) {
                        return '14+';
                    } else {
                        return Math.round(differenceMs / ONE_DAY);
                    }
                } else {
                    return '14+';
                }
            } else {
                return 'NC'
            }
        } else {
            return 'NC'
        }
    }

    public days_between(objetVegetal) {
        if (objetVegetal.indicateurs) {
            if (objetVegetal.indicateurs['next-watering']) {
                if (new Date(objetVegetal.indicateurs['next-watering']) < new Date()) {
                    return '0';
                }
                const date1 = Date.now();
                // The number of milliseconds in one day
                const ONE_DAY = 1000 * 60 * 60 * 24;
                if (objetVegetal.indicateurs['next-watering'] != 'none') {
                    objetVegetal.indicateurs['next-watering'] = new Date(objetVegetal.indicateurs['next-watering']);
                    // Calculate the difference in milliseconds
                    const differenceMs = Math.abs(objetVegetal.indicateurs['next-watering'] - date1);
                    if (Math.round(differenceMs / ONE_DAY) > 14) {
                        return '14+';
                    } else {
                        return Math.round(differenceMs / ONE_DAY);
                    }
                } else {
                    return '14+';
                }
            } else {
                return 'NC'
            }
        } else {
            return 'NC'
        }
    }

    public genererPhraseRehumectation(objetVegetal) {
        if (objetVegetal.indicateurs) {
            if (objetVegetal.indicateurs['efficiency']) {
                if (Math.round(objetVegetal.indicateurs['efficiency']) > 0) {
                    return Math.round(objetVegetal.indicateurs['efficiency'] * 100) + '% le ' + this.datePipe.transform(objetVegetal.indicateurs['last-watering'], 'dd/MM/yyyy');
                } else {
                    return this.translate.instant('no_rehum');
                }
            } else {
                return this.translate.instant('no_rehum');
            }
        } else {
            return this.translate.instant('no_rehum');
        }

        // {{ utils.cvrt(vegetal?.indicateurs['efficiency'])}}% le {{vegetal?.indicateurs['last-watering'] | date:"mediumDate"}}

    }

    public cvrt(objetVegetal) {
        if (objetVegetal.indicateurs) {
            if (objetVegetal.indicateurs['efficiency']) {
                return Math.round(objetVegetal.indicateurs['efficiency'] * 100);
            } else {
                return '0';
            }
        } else {
            return '0';
        }
    }

    public getDeviceInfoSynchro(sujet, typeAnalyse) {
        var d = new Date();
        d.setDate(d.getDate() - 2);
        this.today = d.toISOString();
        //sujet = this.analyseSonde(sujet);
        // debugger;
        let styles = {
            'color': 'red'
        };
        let text = "";
        if (sujet.trames) {

            if (sujet.trames[0]) {
                if (sujet.trames[0].messages.date_mysql) {
                    if (sujet.trames[0].messages.date_mysql.replace(' ', 'T') > this.today && sujet.centrale && sujet.trame_avg > 960) {
                        styles = {
                            'color': 'orange'
                        };

                        text = "Cet icône signifie le minisense a remonté un message dans les dernières 48h mais que leur fréquence est trop faible";
                    } else if ((sujet.trames[0].messages.date_mysql.replace(' ', 'T') < this.today && sujet.centrale || sujet.trames.length == 0) && sujet.centrale != '0STATION') {
                        styles = {
                            'color': 'red'
                        };

                        text = "Cet icône signifie le minisense n'a pas remonté de message dans les dernières 48h";
                    } else if (sujet.trames[0].messages.date_mysql.replace(' ', 'T') >= this.today && sujet.centrale && sujet.trame_avg <= 960) {
                        styles = {
                            'color': '#55c277'
                        };
                        text = "Cet icône signifie que le minisense est parfaitement synchronisé";
                    }
                }
            }
        }
        if (typeAnalyse != 'tooltip') {
            return styles;
        } else {
            return text;
        }

    }


    async presentToast(
        message,
        duration = this.toastDuration.medium,
        color: string | IonicColorKeyEnum = IonicColorKeyEnum.DARK,
        cssClass: string = 'urba-toast',
        position: ToastPosition = 'bottom',
        button = false,
        photos = [],
        interpolateParams = {},
    ) {
        const toastOptions: any = {
            message: this.translate.instant(message, interpolateParams),
            duration,
            color,
            position,
            cssClass,
        };

        if (button) {
            toastOptions.buttons = [{text: 'OK'}];
        }

        const toast = await this.toastController.create(toastOptions);
        toast.present();
    }

    public afficherIconeEauDispoRacine(sujet?: any) {
        let color = 'empty';
        let ret: string;
        if (sujet && sujet.txp_moisture) {
            if (sujet.txp_moisture.result) {
                if (sujet.txp_moisture.result.moistures) {
                    if (sujet.txp_moisture.result.moistures.length > 0) {
                        if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) > 0.9) {
                            color = 'blue';
                        } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) >= 0.5 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.9) {
                            color = 'green';
                        } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) >= 0.1 && (sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.5) {
                            color = 'yellow';
                        } else if ((sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length - 1]) < 0.1) {
                            color = 'red';
                        }
                    }
                }
            }
        }
        ret = 'assets/' + 'pin_default_' + color + '.png';
        if (sujet.picto_type) {
            if (sujet.picto_type === 'defaut') {
                ret = 'assets/' + 'pin_default_' + color + '.png';
            } else if (sujet.picto_type === 'arbre') {
                ret = 'assets/UrbaNewPictos/Jeune_pousse/jeune_pousse_' + color + '.png';
            } else if (sujet.picto_type === 'vivace') {
                ret = 'assets/UrbaNewPictos/Fleur_gazon/fleur_gazon_' + color + '.png';
            } else if (sujet.picto_type === 'pilote') {
                ret = 'assets/UrbaNewPictos/Electrovanne/electrovanne_' + color + '.png';
            } else if (sujet.picto_type === 'sport') {
                ret = 'assets/UrbaNewPictos/Terrain_sport/terrain_sport_' + color + '.png';
            }
        }


        return ret;
    }

    public getIconObject(sujet?: any) {

        let color = 'empty';
        let ret: string;
        if (sujet && sujet.txp_moisture) {
            if (sujet && sujet.txp_moisture.result) {
                if (sujet.txp_moisture.result.moisture) {
                    if ((sujet.txp_moisture.result.moisture) > 0.9) {
                        color = 'blue';
                    } else if ((sujet.txp_moisture.result.moisture) >= 0.5 && (sujet.txp_moisture.result.moisture) <= 0.9) {
                        color = 'green';
                    } else if ((sujet.txp_moisture.result.moisture) >= 0.1 && (sujet.txp_moisture.result.moisture) <= 0.5) {
                        color = 'yellow';
                    } else if ((sujet.txp_moisture.result.moisture) < 0.1) {
                        color = 'red';
                    }
                }
            }
        }
        ret = 'assets/' + 'pin_default_' + color + '.png';
        if (sujet.picto_type) {
            if (sujet.picto_type === 'defaut') {
                ret = 'assets/' + 'pin_default_' + color + '.png';
            } else if (sujet.picto_type === 'arbre') {
                ret = 'assets/UrbaNewPictos/Jeune_pousse/jeune_pousse_' + color + '.png';
            } else if (sujet.picto_type === 'vivace') {
                ret = 'assets/UrbaNewPictos/Fleur_gazon/fleur_gazon_' + color + '.png';
            } else if (sujet.picto_type === 'pilote') {
                ret = 'assets/UrbaNewPictos/Electrovanne/electrovanne_' + color + '.png';
            } else if (sujet.picto_type === 'sport') {
                ret = 'assets/UrbaNewPictos/Terrain_sport/terrain_sport_' + color + '.png';
            }
        }


        return ret;
    }

    afficherValeurTXP(sujet) {

        if (sujet && sujet.indicateurs) {
            if (sujet.indicateurs && sujet.indicateurs.moisture) {

                // if (Math.ceil(sujet.txp_moisture.result.moistures[sujet.txp_moisture.result.moistures.length-1] * 100) > 0) {
                return Math.ceil(sujet.indicateurs.moisture * 100);
                // }else {
                //   return 0;
                // }


            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    getPictoReprise(sujet) {
        let picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/reprise_1.svg";
        if (sujet.indicateurs) {
            if (sujet.indicateurs['revival-max']) {
                picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/reprise_" + sujet.indicateurs['revival-max'] + ".svg"
            }
        }
        return picto;

    }

    getImageReseau(sujet) {
        var reseau = 0;
        var batterie = 0;
        let image_reseau = 'https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/connectivite_ok.svg';
        // return 'https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/connectivite_ok.svg';
        if (sujet.trames) {
            if (sujet.trames[0]) {
                if (sujet.trames[0]['messages']) {
                    reseau = sujet.trames[0]['messages']['reseau'];
                    batterie = sujet.trames[0]['messages']['vbat_mv'];
                    if (reseau == 0) {
                        image_reseau = 'https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/connectivite_alerte.svg';
                    }
                }
            }
        }

        return image_reseau;
    }

    getGaugeColorNew(sujet?: any) {
        let color = 'rgb(85, 194, 119)';
        if (sujet && sujet.indicateurs) {
            if (sujet.indicateurs.moisture) {

                if (sujet.indicateurs.moisture > 0.9) {
                    color = '#406AE0';
                } else if (sujet.indicateurs.moisture >= 0.5 && sujet.indicateurs.moisture < 0.9) {
                    color = 'rgb(85, 194, 119)';
                } else if (sujet.indicateurs.moisture >= 0.1 && sujet.indicateurs.moisture < 0.5) {
                    color = '#FAB51A';
                } else if (sujet.indicateurs.moisture < 0.1) {
                    color = 'red';
                }
            }
        }
        return color;
    }

    getPictoActivite(sujet) {
        console.log('indicateurs', sujet);
        let picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/activite_0.svg";
        if (sujet.indicateurs) {
            if (sujet.indicateurs.result[0]['root-activity'] < 1) {
                picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/activite_1.svg";
            } else if (sujet.indicateurs.result[0]['root-activity'] > 1 && sujet.indicateurs.result[0]['root-activity'] < 3) {
                picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/activite_2.svg";
            } else if (sujet.indicateurs.result[0]['root-activity'] > 3) {
                picto = "https://arbre.urbasense.eu/images/urbasense/icones/icones_bulletins/activite_3.svg";
            }
        }


        return picto;

    }

    getMoisture(vegetal) {
        return Math.round(vegetal.indicateurs.result[0].moisture * 100);
    }

    /**
     * Keys become values
     * Values become keys
     */
    reverseObject(obj) {
        return Object.keys(obj).reduce((ret, key) => {
            ret[obj[key]] = key;
            return ret;
        }, {});
    }

    /**
     * Turns french special characters (é, à, î...) into normal letters (e, a, i ...)
     */
    normalizeFrenchLetters(sentence: string): string {
        return sentence.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
    }

    /**
     * Parse a week json into an array.
     * Also excludes "aucun" in order to return an empty array
     */
    parseJsonWeekArray(json: string): string[] {
        return JSON.parse(json).filter(day => day !== 'aucun');
    }

    /**
     * Returns an excerpt (short version) of a given string
     * Max length can be given but defaults to 100 chars
     * Overflow replacement defaults to '...'
     */
    getExcerpt(msg: string, maxLength: number = 100, overflowReplacement: string = '...') {
        if (msg.length > maxLength) {
            return msg.substr(0, maxLength) + overflowReplacement;
        }
        return msg;
    }


    /**
     * Return the start and the end of the current week or past weeks
     * @param target = 0 means current week, 1 means last week etc
     */
    getWeekBoundaries(target: number = 0): DatePeriod {
        const WEEK_LENGTH = 7;
        const HOURS_IN_DAY = 24;
        const MINUTES_IN_HOUR = 60;

        const start = new Date();
        while (target >= 0) {
            if (start.getDay() === 1) {
                target--;
            }
            if (target >= 0) {
                start.setDate(start.getDate() - 1);
            }
        }
        start.setHours(0, 0, 0, 0);

        const end = new Date();
        end.setDate(start.getDate() + (WEEK_LENGTH - 1));
        end.setHours(HOURS_IN_DAY - 1, MINUTES_IN_HOUR - 1, 0, 0);

        return {start, end};
    }

    isInDatePeriod(date: Date, period: DatePeriod): boolean {
        return date.getTime() >= period.start.getTime()
            && date.getTime() < period.end.getTime();
    }

    async toast(msg: string, type: IonicColorKeyEnum = IonicColorKeyEnum.SUCCESS) {
        return await this.presentToast(
            msg,
            this.toastDuration.medium,
            type,
            '',
            'bottom',
            true
        );
    }

    dateStrToIsoDate(dateStr: string, locale = 'fr-FR', separator = '/'): string {
        const [day, month, year] = dateStr.split(separator);

        if (!day || !month || !year) {
            // throw new Error("Invalid date format");
            return null;
        }

        return `${year}-${month}-${day}`;
    }

    dateToString(date: Date = new Date()): string {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        }).format(date).replace(',', '');
    }

    debounce(func, delay: number): () => void {
        let timer: ReturnType<typeof setTimeout>;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    }

    getQueryParamAsArray(queryParamName: string, params: ParamMap, separator: string = this.queryParamSeparator): string[] {
        return params.get(queryParamName) ?
            params.get(queryParamName)
                .split(separator)
                .filter(value => value !== '') : [];
    }

}
