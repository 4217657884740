<div class="grid grid-cols-lg-1_2 gap-md gap-md-xl">
    <div class="col-span flex items-center gap-xs">
        <h2 class="heading flex items-center gap-xs flex-1">
            {{ 'localisation'|translate }}
        </h2>
        <div class="flex gap-xs">
        </div>
    </div>

    <div class="grid gap-md content-start">
        <app-input-simple
                [label]="'geo.lat'"
                resetable="true"
                inputClass="bg-surface-1"
                prefix="map"
                [(ngModel)]="control.value.lat"
                (ngModelChange)="handleTextPositionChanged($event, 'lat')"
        ></app-input-simple>
        <app-input-simple
                [label]="'geo.lon'"
                resetable="true"
                prefix="map-outline"
                inputClass="bg-surface-1"
                [(ngModel)]="control.value.lng"
                (ngModelChange)="handleTextPositionChanged($event, 'lng')"
        ></app-input-simple>

        <app-keyvals [indicateurs]="indicateurs_localisation"></app-keyvals>
    </div>

    <figure class="w-full">
        <form (submit)="handleSubmit()">
            <app-geo-input
                    [control]="control"
                    [field]="field"
                    [canSubmit]="true"
            ></app-geo-input>
        </form>
    </figure>
</div>
