<ion-header>
    <ion-toolbar>
        <ion-title>&nbsp;</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-segment [(ngModel)]="segment" *ngIf="!isLoading">
    <ion-segment-button value="infos">
        <ion-label>{{'informations' | translate}}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="trames" id="trame_hard" (click)="trackMix('Detail équipement activité')">
        <ion-label>{{'activite' | translate}}</ion-label>
    </ion-segment-button>
</ion-segment>


<ion-content>
    <ion-item *ngIf="centrale">
        <ion-label>
            <h1 class="boldSf">{{'minisense' | translate}} {{centrale.nom}}</h1>
        </ion-label>
    </ion-item>

    <ion-item *ngIf="isLoading" text-wrap>
        <ion-spinner name="lines"></ion-spinner>
        <ion-label style="text-align:center;">
            {{'chargement_des_informations_du_minisense' | translate}}
        </ion-label>

    </ion-item>
    <div [ngSwitch]="segment" *ngIf="!isLoading">
        <ion-card *ngSwitchCase="'infos'">
            <ion-card-content *ngIf="centrale">

                <ion-item>
                    <ion-label>{{'Type' | translate}} :{{ centrale.type | translate}}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>{{'Propriétaire' | translate}} : {{centrale.nom_orga}}</ion-label>
                </ion-item>
                <ion-item *ngIf="centrale.firmware">
                    <ion-label>Firmware : {{centrale.firmware}}</ion-label>
                </ion-item>
                <ion-item *ngIf="centrale.serie">
                    <ion-label>{{'Série' | translate}} : {{centrale.serie}}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>{{'Création' |translate}}
                        : {{centrale.date_creation.replace(' ', 'T') | date:'mediumDate'}}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>{{'Dernier appel' | translate}}
                        : {{centrale.date_appel.replace(' ', 'T') | date:'mediumDate'}}</ion-label>
                </ion-item>
                <ion-item *ngIf="centrale.trames[0].messages.vbat_mv">
                    <ion-label>{{'Batterie' | translate}} : {{centrale.trames[0].messages.vbat_mv / 1000}} V</ion-label>
                </ion-item>
                <ion-item *ngIf="centrale.trames[0].messages.trame_type_txt">
                    <ion-label>{{'type_de_trame'}} : {{centrale.trames[0].messages.trame_type_txt}}</ion-label>
                </ion-item>


            </ion-card-content>


        </ion-card>

        <ion-card *ngSwitchCase="'trames'">
            <ion-list *ngIf="centrale.trames">
                <app-sondes *ngFor="let trame of centrale.trames"[trame]="trame"></app-sondes>
            </ion-list>
        </ion-card>
    </div>
</ion-content>



