<ion-tabs #ref
          *ngIf="responsiveService.isMobile() else desktop"
          (ionTabsDidChange)="handleTabDidChange()">
    <ion-tab-bar slot="bottom">
        <ng-container *ngTemplateOutlet="tabGroup">
        </ng-container>
    </ion-tab-bar>
</ion-tabs>

<ng-template #desktop>
    <ion-tabs (ionTabsDidChange)="handleTabDidChange()">
        <ion-tab-bar slot="top">
            <ng-container *ngTemplateOutlet="tabGroup">
            </ng-container>
        </ion-tab-bar>
    </ion-tabs>
</ng-template>

<ng-template #tabGroup>
    <ng-container *ngFor="let tab of tabs">
        <ion-tab-button
                *ngIf="!tab.isHidden && tab.route"
                tab="{{ tab.route }}">
            <ion-icon name="{{tab.icon}}"></ion-icon>
            <ion-label>{{ tab.translationKey | translate }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
                *ngIf="!tab.isHidden && tab.link"
                href="{{ tab.link }}" target="_blank">
            <ion-icon name="{{tab.icon}}"></ion-icon>
            <ion-label>{{ tab.translationKey | translate }}</ion-label>
        </ion-tab-button>
    </ng-container>
</ng-template>
