import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {
    IFormField,
    IFormStep,
    IModularFormPayload
} from '../../../interfaces/IFormStep';
import {ModularFormService} from '../../../services/modular-form.service';
import {MatVerticalStepper} from '@angular/material/stepper';
import {InputTypeEnum} from '../../../enums/InputTypeEnum';
import {StorageService} from '../../../services/storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../services/utils.service';
import {MatDialog} from '@angular/material/dialog';
import {ModularSelectDialogComponent} from '../../dialogs/modular-select-dialog/modular-select-dialog.component';

@Component({
    selector: 'app-modular-form',
    templateUrl: './modular-form.component.html',
    styleUrls: ['./modular-form.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {showError: true},
        },
    ],
})
export class ModularFormComponent implements OnInit {
    @ViewChild('stepper') stepper: MatVerticalStepper;
    @Input() steps: IFormStep[];
    @Input() showSteps = true;
    @Input() previousData: IModularFormPayload;
    @Input() isMandatory = false;
    @Input() formKey: string;
    @Input() storageKey: string;
    @Input() storageSubKey: string;
    @Input() startingStep = 0;
    @Input() showSaveBtn = false;
    @Input() canSelectPreviousData = false;
    @Input() previousDataLabels: string[] = [];

    @Output() onFormSubmit: EventEmitter<IModularFormPayload> = new EventEmitter();

    visitedSteps: number[] = [];
    interval: any;
    loading = false;
    ready = false;

    constructor(
        private formBuilder: FormBuilder,
        private formService: ModularFormService,
        private storage: StorageService,
        private snackBar: MatSnackBar,
        private translator: TranslateService,
        private utils: UtilsService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        console.log('init');
        // Handling Edit mode with previous Data were passed as prop
        if (null != this.previousData) {
            this.initForm();
            this.ready = true;
            return;
        }

        // Else, defaulting to empty value
        this.previousData = {};

        // if storage exists
        let storageData = null;
        if (this.canSelectPreviousData) {
            // If previousData selection is ON, we need to enable subKey;
            this.storageSubKey = this.formService.generateRandomStorageKey();
        }
        if (this.storageKey) {
            storageData = this.storage.getKey<IModularFormPayload>(
                this.formService.FORM_STORAGE_KEY,
                this.storageKey
            ) ?? {};
        }
        //// A - it points to a FormData (=> previousData)
        if (this.formService.isFormPayload(storageData)) {
            this.previousData = this.storage.getKey<IModularFormPayload>(
                this.formService.FORM_STORAGE_KEY,
                this.storageKey
            ) ?? {};
        }
        //// else B - it points to an object with FormDatas (=> choices)
        if (this.canSelectPreviousData && Object.keys(storageData).length > 0) {
            this.handleSelectionFromStorage(storageData);
            return;
        }

        // Launching the form as usual without previous Data
        // or with singular previous Data (this.previousData)
        this.initForm();
        this.ready = true;
    }

    handleSelectionFromStorage(formDatasFromStorage: object) {
        const dialogRef = this.dialog.open(ModularSelectDialogComponent, {
            data: {
                storageKey: this.storageKey,
                choices: formDatasFromStorage,
                labels: this.previousDataLabels,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((choice) => {
            // Waiting for User choice =======================
            this.storageSubKey = choice ?? this.formService.generateRandomStorageKey();
            this.previousData = formDatasFromStorage[this.storageSubKey] ?? {};

            this.initForm();
            this.ready = true;
        });
    }

    initForm() {
        // Generating FormFields for each row of TableInput
        this.steps = this.formService.populateWithPreviousData(this.steps, this.previousData);
        // Generating a FormGroup for each Step
        this.steps.forEach(step => {
            step.formGroup = this.formService.turnFieldsIntoFormGroup(step.fields);

            for (const field of this.formService.getFlatFieldList(step)) {
                // Auto-generate labels
                this.formService.generateTranslationKey(field, this.formKey);

                // Adding event listeners
                if (field.onChange || step.onChange) {
                    step.formGroup.controls[field.key]
                        .valueChanges
                        .subscribe(newValue => {
                            if (field.type !== InputTypeEnum.ACCORDEON) {
                                if (field.onChange) {
                                    field.onChange(newValue, this.steps, this.stepper, field.key);
                                }
                                if (step.onChange) {
                                    step.onChange(newValue, this.steps, this.stepper, field.key);
                                }
                            }
                        });
                }
            }
        });
        if (this.startingStep !== 0) {
            this.loading = true;
            this.safeMoveToStartingStep();
        }
        this.openSnackAboutPreviousData();
    }

    safeMoveToStartingStep() {
        this.interval = setInterval(() => {
            clearInterval(this.interval);
            this.stepper.selectedIndex = this.startingStep;
            this.loading = false;
        }, 300);
    }

    /**
     * Generate a IModularFormPayload and emits "onFormSubmit", passing
     * the payload along.
     */
    handleSubmit(): void {
        const formPayload: IModularFormPayload = this.formService.getModularFormPayload(this.steps);
        this.onFormSubmit.emit(formPayload);
    }

    isHiddenInput(field: IFormField): boolean {
        return field.type === this.formService.types.HIDDEN;
    }

    /**
     * Determines if each form steps can be navigated manually.
     */
    isAutoStepper(): boolean {
        return this.isMandatory
            || this.steps.some(step => step.onNext || step.onPrev);
    }

    isEditMode(): boolean {
        return Object.keys(this.previousData).length > 0
            && this.storageKey == null;
    }

    /**
     * Push last visited step into visitedSteps history.
     * Needed for handlePrev()
     */
    updateHistory(e): void {
        this.visitedSteps.push(e.previouslySelectedIndex);
    }

    /**
     * Show appropriate step depending on current step and form type
     */
    handlePrev(
        stepper: MatVerticalStepper,
        currentStep: IFormStep,
        currentStepIndex: number,
    ): void {
        if (!this.isAutoStepper()) {
            // If no step transition condition (onPrev/onNext) simply go prev
            stepper.previous();
            return;
        }
        if (currentStep.onPrev) {
            // If a onPrev cb was passed, it takes priority
            currentStep.onPrev(stepper, currentStep.formGroup, this.steps);
            return;
        }
        // Else, we need to check user history to get the last visited step
        const prevStepIndex = this.formService.getPreviousAutoStep(this.visitedSteps, currentStepIndex);
        stepper.selectedIndex = prevStepIndex;
    }

    /**
     * Checks if the user is able to proceed to next step.
     * If the form is an autostepper (controled by onPrev/onNext callbacks),
     * then the current Step must be valid.
     */
    canGoNext(formGroup: FormGroup): boolean {
        return !this.isAutoStepper() || this.formService.isValidFormGroup(formGroup);
    }

    handleNext(
        stepper: MatVerticalStepper,
        currentStep: IFormStep,
    ) {
        if (currentStep.onNext) {
            // If a onNext cb was passed, it takes priority
            currentStep.onNext(stepper, currentStep.formGroup, this.steps);
        } else {
            stepper.next();
        }
        // Saving onNext event (need to be done AFTER onNext callback in case data changes)
        this.saveToStorage();
    }

    saveToStorage(): boolean {
        if (null == this.storageKey) {
            return false;
        }

        let dataToSave;
        if (this.storageSubKey !== null) {
            // Handling nested sub key
            dataToSave = this.storage.getKey(
                this.formService.FORM_STORAGE_KEY,
                this.storageKey,
            ) ?? {};
            dataToSave[this.storageSubKey] = this.formService.getModularFormPayload(this.steps, false);
        } else {
            dataToSave = this.formService.getModularFormPayload(this.steps, false);
        }

        const success = this.storage.setKey<IModularFormPayload>(
            this.formService.FORM_STORAGE_KEY,
            this.storageKey,
            dataToSave,
        );

        const message = success ? 'form_data_saved_success' : 'form_data_saved_error';
        this.snackBar.open(
            this.translator.instant(message),
            this.translator.instant('ok'),
            {
                duration: this.utils.toastDuration.short,
                verticalPosition: 'top',
            }
        );
    }

    openSnackAboutPreviousData() {
        if (!this.previousData || !this.previousData.createdAt) return;
        const date = new Date(this.previousData.createdAt);
        this.snackBar.open(
            this.translator.instant('form_data_retrieved_at', {
                date: date.toLocaleDateString(),
                time: date.toLocaleTimeString(),
            }),
            this.translator.instant('ok'),
            {duration: this.utils.toastDuration.long});
    }

    removeFormDataFromStorage() {
        this.formService.removeFormDataFromStorage(this.storageKey, this.storageSubKey);
    }

}
