<ion-grid>
    <ion-row>
       <!-- <ion-col size="12" size-md="6" >
        <div style="height: 50vh;width:100%;" leaflet [leafletOptions]="Mapoptions"
             (leafletMapReady)="onMapReady($event)"></div>
        <ion-button *ngIf="typePage != 'creer_mission'" [disabled]="!userLocation"
                    (click)="openGoogleMapsRoute()">Voir l'itinéraire sur Google Maps
        </ion-button>

    </ion-col> -->

    <mat-accordion *ngIf="responsiveService.isMobile()" class="full-width-accordion" >
        <mat-expansion-panel expanded (opened)="onAccordionOpened()" (closed)="onAccordionClosed()">
          <mat-expansion-panel-header>
            <mat-panel-title>
                {{ panelStateText }}
            </mat-panel-title>

          </mat-expansion-panel-header>
          <ion-col size="12" size-md="6" >
            <div style="height: 50vh;width:100%;" leaflet [leafletOptions]="Mapoptions" (leafletMapReady)="onMapReady($event)"></div>
            <ion-button *ngIf="typePage != 'creer_mission'" [disabled]="!userLocation" (click)="openGoogleMapsRoute()">Voir l'itinéraire sur Google Maps</ion-button>
          </ion-col>
        </mat-expansion-panel>
      </mat-accordion>


        <ion-col size="12" size-md="6">
            <ng-container *ngIf="typePage == 'creer_mission'">
                <h3>{{ 'creer_mission.title_create' | translate }} {{ typeDemandeur | user_role_key | translate }}</h3>
                <p>{{ 'creer_mission.about_to_create' | translate }}</p>
            </ng-container>
            <ng-container *ngIf="typePage == 'voir_mission'">
                <h3>{{ 'creer_mission.title_see' | translate }} {{ typeDemandeur | user_role_key | translate }}</h3>
                <p *ngIf="missionStatut">
                    {{ 'status' | translate }} :
                    <app-status-badge [status]="missionStatut"></app-status-badge>
                </p>
                <app-time-detail
                        [start]="startAt"
                        [end]="endAt"
                ></app-time-detail>
                <ion-button
                        *ngIf="canBeAcceptedOrDeclined"
                        color="danger"
                        size="medium"
                        (click)="declineMission()">
                    {{ 'mission_decline' | translate }}
                </ion-button>

                <ion-button
                        *ngIf="canBeAcceptedOrDeclined"
                        color="primary"
                        size="medium"
                        (click)="accepterMission()">
                    {{ 'mission_accept' | translate }}
                </ion-button>
                <p>{{ 'creer_mission.see_interventions' | translate }} :</p>
            </ng-container>
            <ion-list cdkDropList
                      class="responsive-offset"
                      (cdkDropListDropped)="drop($event)">
                <ion-item *ngFor="let intervention of intervDisplay; let i = index;"
                          cdkDrag
                          style="cursor: move;"
                          class="mission-step"
                          [ngClass]="{
                              'cdk-drag-disabled': readOnlyMission,
                              'urgent': intervention.interv_detail_parsed?.urgent,
                              'transparent': intervention.intst_statut === this.interventionService.status.REALISEE && typeDemandeur == userService.roles.INSTALLATEUR,
                              'no-interaction': intervention.intst_statut === this.interventionService.status.REALISEE && typeDemandeur == userService.roles.INSTALLATEUR
                              }">
                    <div class="mission-step__index" cdkDragHandle>
                        {{ i + 1 }}
                        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div>
                    <ion-label class="ion-text-wrap">
                        <div>
                            <h4>
                                <a href="/site/{{intervention.id_sit}}/data/vegetaux/sujet/{{intervention.id_suj}}/vegetal"
                                   class="simple-link"
                                   target="_blank"
                                >
                                    <app-intervention-type-badge style="vertical-align: middle;"
                                                                 [interventionType]="intervention.interv_type"
                                                                 [interventionDetailJson]="intervention.interv_detail"
                                    ></app-intervention-type-badge>
                                    [<em>{{ intervention?.nom_sit }}</em>]
                                </a>
                                <a href="/site/{{intervention.id_sit}}/data/vegetaux/sujet/{{intervention.id_suj}}/vegetal"
                                   class="simple-link"
                                   target="_blank"
                                >
                                    {{ intervention?.nom_suj }}
                                </a>
                            </h4>
                            <div class="flex-row-wrap">
                                <ion-buttons>
                                    <ion-button
                                            *ngIf="typeDemandeur === userService.roles.INTERVENTION_ADMIN && intervention?.device"
                                            href="{{minisenseCheckerUrl}}?id_ms={{intervention.device}}"
                                            [color]="'success'"
                                            target="_blank"
                                    >
                                        <ion-icon name="leaf-outline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                                <small *ngIf="intervention?.device">
                                    Minisense: {{ intervention?.device }}
                                </small>
                            </div>
                        </div>
                        <p>
                            {{
                                'creer_mission.intervention_created_at' | translate: {
                                    id: intervention?.interv_id,
                                    date: (intervention?.interv_creation_date | date: 'dd/MM/yyyy')
                                }
                            }}
                            <ion-badge color="warning" *ngIf="intervention?.interv_date_buttoir"
                                       style="vertical-align:middle;">
                                {{ 'date_limit' | translate: {date: intervention?.interv_date_buttoir | date: 'dd/MM/yyyy'} }}
                            </ion-badge>
                        </p>

                        <p *ngIf="getDetailInter(intervention?.interv_detail).station">
                            <ion-badge style="vertical-align:middle;">Station
                                <ion-icon style="vertical-align:middle;" name="hardware-chip-outline"></ion-icon>
                            </ion-badge>
                        </p>
                        <p *ngIf="getDetailInter(intervention?.interv_detail)?.remarque">
                            {{ 'remarque' | translate }}
                            : {{ getDetailInter(intervention?.interv_detail).remarque }}
                        </p>
                        <p *ngIf="missionStatut">
                            {{ 'status' | translate }} :
                            <app-status-badge [status]="intervention.intst_statut"></app-status-badge>
                        </p>

                        <ng-container *ngIf="intervention.intst_rem">
                            <p>{{ 'comment_last' | translate }}</p>
                            <app-intervention-remarque
                                    *ngIf="intervention.intst_rem"
                                    [remarque]="intervention.intst_rem"
                            ></app-intervention-remarque>
                        </ng-container>

                        <mat-form-field
                                style="width: 100%"
                                *ngIf="(intervention?.intst_statut == interventionService.status.ACCEPTEE && typeDemandeur == userService.roles.INSTALLATEUR) || (typePage == 'creer_mission') || (intervention?.intst_statut == interventionService.status.REALISEE && typeDemandeur == userService.roles.INTERVENTION_ADMIN) ">
                            <mat-label>{{ 'comment_placeholder' | translate }}</mat-label>
                            <textarea [(ngModel)]="intervention.remarque_installateur"
                                      matInput
                                      id="remarque{{intervention?.interv_id}}"
                                      name="remarque{{intervention?.interv_id}}"
                                      rows="5"></textarea>
                        </mat-form-field>

                        <div style="display: grid; gap: 1rem;">
                            <ion-button color="danger" slot="end"
                                        size="medium"
                                        (click)="supprimerIntervention(i,intervention?.interv_id)"
                                        *ngIf="typeDemandeur == userService.roles.INTERVENTION_ADMIN && interventionService.isShowable(intervention.intst_statut)">
                                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                                <ion-label>{{ 'supprimer' | translate }}</ion-label>
                            </ion-button>
                            <!--Boutons installateurs-->
                            <ng-container
                                    *ngIf="typePage == 'voir_mission' && typeDemandeur == userService.roles.INSTALLATEUR && intervention?.intst_statut == interventionService.status.ACCEPTEE">
                                <ion-button
                                        *ngIf="intervention.interv_type == interventionService.types.MAINTENANCE || intervention.interv_type == interventionService.types.MAINTENANCE_OLD"
                                        color="primary" slot="end"
                                        size="medium"
                                        (click)="debuterMaintenance(null, intervention.id_suj,intervention.interv_id)">
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    <ion-label>{{ 'intervention_do_maintenance' | translate }}</ion-label>
                                </ion-button>

                                <ion-button
                                        *ngIf="intervention.interv_type == interventionService.types.INSTALLATION && !this.interventionService.canMakeInstallationDiag(intervention)"
                                        color="success" slot="end"
                                        size="medium"
                                        (click)="debuterInstallation(null, intervention.id_suj,intervention.interv_id)">
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    <ion-label>{{ (intervention.interv_remarque_parsed.type === 'string' ? 'intervention_do_install' : 'intervention_finish_install') | translate }}</ion-label>
                                </ion-button>

                                <ion-button
                                        *ngIf="intervention.interv_type == interventionService.types.INSTALLATION && this.interventionService.canMakeInstallationDiag(intervention)"
                                        color="tertiary" slot="end"
                                        size="medium"
                                        [routerLink]="['/installation-diag-form/'+ intervention.interv_id]">
                                    <ion-icon name="thermometer"></ion-icon>
                                    <ion-label>{{ "intervention_diagnosis" | translate }}</ion-label>
                                </ion-button>

                                <ion-button
                                        [disabled]="!intervention.remarque_installateur || intervention.remarque_installateur == ''"
                                        color="warning" slot="end"
                                        size="medium"
                                        (click)="invalideFinIntervention(intervention)">
                                    <ion-icon name="close-outline"></ion-icon>
                                    <ion-label>{{ 'abort' | translate }}</ion-label>
                                </ion-button>
                            </ng-container>

                            <!--Boutons Admin-->
                            <ng-container
                                    *ngIf="typePage == 'voir_mission' && typeDemandeur == userService.roles.INTERVENTION_ADMIN">

                                <ng-container
                                        *ngIf="intervention?.intst_statut == interventionService.status.REALISEE">
                                    <ion-button color="primary" slot="end"
                                                (click)="valideConformiteIntervention(intervention)"
                                                [disabled]="!intervention.remarque_installateur || intervention.remarque_installateur == ''"
                                    >
                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                        <ion-label>{{ 'conforme' | translate }}</ion-label>
                                    </ion-button>

                                    <ion-button color="warning" slot="end"
                                                size="medium"
                                                (click)="invalideConformiteIntervention(intervention)"
                                                [disabled]="!intervention.remarque_installateur || intervention.remarque_installateur == ''"
                                    >
                                        <ion-icon name="close-outline"></ion-icon>
                                        <ion-label>{{ 'invalid' | translate }}</ion-label>
                                    </ion-button>
                                </ng-container>

                                <ng-container
                                        *ngIf="intervention?.intst_statut == interventionService.status.NON_REALISEE">
                                    <ion-button color="primary" slot="end"
                                                size="medium"
                                                (click)="reproposerIntervention(intervention)">
                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                        <ion-label>{{ 'creer_mission.repropose' | translate }}</ion-label>
                                    </ion-button>
                                    <ion-button color="warning" slot="end"
                                                size="medium"
                                                (click)="annulerIntervention(intervention)">
                                        <ion-icon name="close-outline"></ion-icon>
                                        <ion-label>{{ 'Annuler' | translate }}</ion-label>
                                    </ion-button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ion-label>
                </ion-item>

            </ion-list>
        </ion-col>

        <ion-col size="12" size-md="6" *ngIf="!responsiveService.isMobile()">
            <div style="height: 50vh;width:100%;" leaflet [leafletOptions]="Mapoptions"
                 (leafletMapReady)="onMapReady($event)"></div>
            <ion-button *ngIf="typePage != 'creer_mission'" [disabled]="!userLocation"
                        (click)="openGoogleMapsRoute()">Voir l'itinéraire sur Google Maps
            </ion-button>
            <!-- <div style="height:200px"   id="map" leaflet [leafletOptions]="optionsMap" (leafletMapReady)="onMapReady($event)"></div> -->
        </ion-col>
    </ion-row>
</ion-grid>

<h4 *ngIf="typePage == 'creer_mission'">{{ 'creer_mission.date_label_create' | translate }}</h4>
<h4 *ngIf="typePage == 'voir_mission'">{{ 'creer_mission.date_label_read' | translate }}</h4>

<ion-item *ngIf="typePage == 'creer_mission'">
    <ion-checkbox [disabled]="!startAt || readOnlyMission" (ionChange)="checkboxClick($event)"
                  slot="start"></ion-checkbox>
    <ion-label>{{ 'creer_mission.one_day_intervention' | translate }}</ion-label>
</ion-item>

<ion-item>
    <mat-form-field *ngIf="interventionSurUnJour; else interventionPeriode">
        <mat-label>{{ 'creer_mission.start_intervention' | translate }}</mat-label>
        <input matInput
               [min]="minDate"
               [max]="maxDate"
               [(ngModel)]="startAt"
               [matDatepicker]="picker"
               [disabled]="readOnlyMission || typePage == 'voir_mission'"
        >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <ng-template #interventionPeriode>
        <mat-form-field>
            <mat-label>{{ 'creer_mission.start_end_intervention' | translate }}</mat-label>
            <mat-date-range-input
                    [min]="minDate"
                    [max]="maxDate"
                    [rangePicker]="rangePicker">
                <input
                        matStartDate
                        placeholder="Date de début"
                        [(ngModel)]="startAt"
                        [disabled]="readOnlyMission || typePage == 'voir_mission'"
                >
                <input
                        matEndDate
                        placeholder="Date de fin"
                        [(ngModel)]="endAt"
                        [disabled]="readOnlyMission || typePage == 'voir_mission'"
                >
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>
        </mat-form-field>
    </ng-template>

</ion-item>
<h4 *ngIf="typePage == 'creer_mission'">{{ 'creer_mission.installer_choice' | translate }}</h4>
<h4 *ngIf="typePage == 'voir_mission'">{{ 'USER_ROLE_18' | translate }}</h4>
<ion-button *ngIf="typePage == 'creer_mission'" [disabled]="readOnlyMission" (click)="openChoixInstallateur()">
    {{ 'creer_mission.installer_choice_please' | translate }}
</ion-button>

<ng-container *ngIf="missionCanBeTransfered">
    <h4>{{ 'creer_mission.transfer_mission' | translate }}</h4>
    <ion-buttons>
        <ion-button
                (click)="openChoixNouvelInstallateur()"
                [color]="'warning'">
            {{ 'creer_mission.installer_choice_new' | translate }}
        </ion-button>
        <ion-button
                [disabled]="newInstallateur == null"
                [color]="'success'"
                (click)="transferMission()">
            {{ 'creer_mission.transfer_mission_validate' | translate }}
        </ion-button>
    </ion-buttons>
</ng-container>

<ion-row>
    <ion-col *ngIf="installateur">
        <app-installateur-card
                [installateur]="installateur"
        ></app-installateur-card>
    </ion-col>

    <ion-col *ngIf="newInstallateur">
        <app-installateur-card
                [installateur]="newInstallateur"
        ></app-installateur-card>
    </ion-col>
</ion-row>

<ion-item *ngIf="installateur && startAt && endAt && intervDisplay && typePage == 'creer_mission'">
    <ion-label>
        <h4>{{ 'creer_mission.create_mission_ready' | translate }}</h4>
        <ion-button size="medium" color="success" (click)="validerMission()">
            {{ 'creer_mission.create_mission_ok' | translate }}
        </ion-button>
        <ion-button size="medium" color="warning" (click)="unselectInstallateur()">
            {{ 'creer_mission.create_mission_cancel' | translate }}
        </ion-button>
    </ion-label>
</ion-item>

<ion-item
        *ngIf="installateur
            && typeDemandeur === userService.roles.INTERVENTION_ADMIN
            && startAt && endAt
            && intervDisplay
            && typePage == 'voir_mission'
            && missionStatut === this.interventionService.status.AFFECTEE">
    <ion-label>
        <h4>
            {{ 'creer_mission.created_not_proposed' | translate }}
        </h4>
        <p>
            {{ 'creer_mission.installer_mail' | translate }} :
            <a href="mailto:{{installateur?.mail}}?subject=Proposition de mission Urbasense&cc=jessica.chicot@urbasense.fr&body=Bonjour,%0D%0AUne nouvelle mission vient de vous être proposée.%0D%0A%0D%0ADate de début : {{startAt}}%0D%0ADate de fin : {{endAt}}%0D%0ALieu : {{sites}}%0D%0A%0D%0AMerci de vous rendre sur le lien suivant pour accepter ou refuser la mission%0D%0Ahttps://app.urbasense.eu/missions/installateur">{{ installateur?.mail }}</a>
        </p>
        <!-- <p>Mail client : <a href="mailto:{{installateur?.mail}}?subject=Proposition de mission Urbasense&cc=jessica.chicot@urbasense.fr&body=Bonjour,%0D%0AUne nouvelle mission vient de vous être proposée.%0D%0A%0D%0ADate de début : {{date_debut}}%0D%0ADate de fin : {{date_fin}}%0D%0ALieu : {{sites}}%0D%0A%0D%0AMerci de vous rendre sur le lien suivant pour accepter ou refuser la mission%0D%0Ahttps://app.urbasense.eu/missions/installateur">{{installateur?.mail}}</a></p> -->


        <ion-button color="success" [disabled]="isClicked"
                    (click)="proposerMission()">{{ 'creer_mission.answer_yes' | translate }}
        </ion-button>
        <ion-button size="medium" color="warning">{{ 'creer_mission.answer_no' | translate }}</ion-button>
    </ion-label>
</ion-item>
