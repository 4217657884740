<div class="grid gap-lg">
    <h2 class="heading">{{ 'votre_profil'|translate }}</h2>

    <app-loader
            *ngIf="loading"
            [loading]="true"
            [message]="'loading_profile'"
    ></app-loader>

    <ng-container *ngIf="!loading && user">

        <div class="grid gap-xs">
            <!--        <app-card-row-->
            <!--                [label]="'Votre prénom nom'"-->
            <!--                [value]="'TODO'"-->
            <!--        ></app-card-row>-->
            <app-card-row
                    [label]="'votre_email'"
                    [value]="user.email"
            ></app-card-row>
            <!--        <app-card-row-->
            <!--                [label]="'Votre téléphone'"-->
            <!--                [value]="'TODO'"-->
            <!--        ></app-card-row>-->
            <!--        <app-card-row-->
            <!--                [label]="'Nom d\'organisation'"-->
            <!--                [value]="'TODO'"-->
            <!--        ></app-card-row>-->
            <!--        <app-card-row-->
            <!--                [label]="'Votre mot de passe'"-->
            <!--                [value]="'************'"-->
            <!--        ></app-card-row>-->
        </div>

        <div class="flex flex-wrap gap-sm">
            <span class="ff-alt text-muted fs-xs uppercase">{{'vos_roles'|translate}} :</span>
            <ul class="clean-list flex flex-wrap gap-xs items-center">
                <li *ngFor="let role of roles">
                    <ion-badge color="light">{{ ('USER_ROLE_' + role)|translate }}</ion-badge>
                </li>
            </ul>
        </div>

        <!--                <app-button variant="outline" icon="lock-closed-outline" class="justify-self-lg-end">-->
        <!--                    {{'changer_mon_mot_de_passe'|translate}}-->
        <!--                </app-button>-->
    </ng-container>
</div>
