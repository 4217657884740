<ion-button
color="{{ color }}"
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
        matTooltip="{{ message | translate }}"
        [matTooltipPosition]="position"
        matTooltipHideDelay="delay"
        aria-label="Button that displays a tooltip that hides when scrolled out of the container"
        class="modular-tooltip">
  <ion-icon icon="{{ icon }}" ></ion-icon>
</ion-button>
