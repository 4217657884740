<div class="flex flex-column flex-md-row-reverse gap-xs gap-md-md items-md-center">
    <div class="flex-1">
        {{projectDocument.filename}}
    </div>
    <div class="text-muted w-md-15ch">
        <p *ngIf="projectDocument.date_document">{{projectDocument.date_document.date|date: 'dd/MM/y'}}</p>
        <p class="truncate" title="{{projectDocument.auteur}}">{{'by'|translate}} {{projectDocument.auteur}}</p>
    </div>
    <app-button icon="download" variant="outline"
                [downloadUrl]="projectDocument.url"
                [userToken]="projectDocument.hash"
                [saveAs]="projectDocument.filename"
    >
        {{'telecharger'|translate}}
    </app-button>
</div>
