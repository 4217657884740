import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ComboboxDialogComponent} from '../combobox-dialog/combobox-dialog.component';
import {ModularFormService} from '../../../services/modular-form.service';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {Observable, Subject} from "rxjs";

export type ComboBoxOption = {
    value: string;
    label: string;
};

export type IntoComboBoxOptions<T> = {
    items: T[];
    into: (item: T) => ComboBoxOption;
};

export type IntoComboBoxOptionsObservable<T> = {
    items$: Observable<T[]>;
    into: (item: T) => ComboBoxOption;
};

@Component({
    selector: 'app-combobox',
    templateUrl: './combobox.component.html',
    styleUrls: ['./combobox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ComboboxComponent),
            multi: true
        }
    ]
})
export class ComboboxComponent<T> implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() control?: AbstractControl | FormControl;

    // Options and IntoOptions
    @Input() options: ComboBoxOption[];
    @Input() intoOptions: IntoComboBoxOptions<T>;
    @Input() intoOptions$: IntoComboBoxOptionsObservable<T>;

    // Debounce
    @Input() debounce?: number;

    // Attributes
    @Input() id: string = 'combobox-' + Math.random().toString(36).substr(2, 9);
    @Input() label?: string;
    @Input() placeholder = '';
    @Input() required: boolean = false;
    @Input() autofocus: boolean = false;
    @Input() autocomplete: string;
    @Input() multiple: boolean = false;

    // Styles
    @Input() containerClass: string;
    @Input() inputClass: string;

    // Icons
    @Input() prefix?: string;
    @Input() suffix: string = 'chevron-down';

    // Events
    @Output() change = new EventEmitter<ComboBoxOption[]>();
    private destroy$ = new Subject();


    constructor(
        private modalController: ModalController,
        public formService: ModularFormService,
    ) {
        if (null == this.control) {
            this.control = new FormControl([]);
        }

        this.control.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                this.onChange(value);
                this.change.emit(value);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }


    onChange: any = () => {
    }
    onTouched: any = () => {

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.control.setValue(obj);
    }


    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    async ngOnInit() {
        this.rawOptionsIntoOptions();

        if (this.intoOptions$) {
            this.intoOptions$.items$.pipe(takeUntil(this.destroy$)).subscribe({
                next: (items) => {
                    this.options = items
                        .filter(v => null != v)
                        .map(this.intoOptions$.into);
                    this.sanitizeControlValue();
                    this.control.enable();
                },
                error: (err) => {
                    this.control.disable();
                }
            });
        }
    }


    async showComboboxModal() {
        if (null == this.options) {
            return;
        }

        const modal = await this.modalController.create({
            component: ComboboxDialogComponent,
            cssClass: 'combobox-modal-translate',
            componentProps: {
                placeholder: this.placeholder,
                value: this.control.value,
                validator: this.control.validator,
                options: this.options,
                multiple: this.multiple,
                debounce: this.debounce,
            },
        });

        await modal.present();

        modal.onDidDismiss().then((result) => {
            if (result.data?.value) {
                this.control.setValue(result.data.value);
                this.control.markAllAsTouched();
            }

            // this.log();
        });
    }

    rawOptionsIntoOptions() {
        this.options = this.intoOptions ? this.intoOptions.items
                .filter(v => null != v)
                .map(this.intoOptions.into)
            : this.options;
    }

    log() {
        console.log({
            value: this.control.value,
            control: this.control,
            options: this.options,
        });
    }

    /**
     * Removes any no-longer existing values from the control
     */
    sanitizeControlValue() {
        const allValues = this.getAllValues();
        this.control.setValue(this.control
                .value
                .filter((selectedOption: ComboBoxOption) => allValues.includes(selectedOption.value))
            , {emitEvent: true});
    }

    getAllValues() {
        return this.options.map(o => o.value);
    }
}
