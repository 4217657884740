<ion-header>
    <ion-toolbar color="success">
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
        </ion-buttons>
        <ion-title>&nbsp;</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <app-gallery-group *ngFor="let group of imagesGroups"
                       [title]="group.groupTitle"
                       [images]="group.images"
    ></app-gallery-group>
</ion-content>
