<div class="grid grid-cols-lg-3 grid-cols-md-2 gap-md content-start items-start">
    <h2 class="heading mt-0 mb-mg col-span">{{ 'caracteristiques'|translate }}</h2>

    <div class="grid content-start items-start gap-xs">
        <app-card-rows [indicateurs]="indicateurs_projet"></app-card-rows>
    </div>

    <div class="grid gap-xs">
        <app-card-rows [indicateurs]="indicateurs_offre"></app-card-rows>
    </div>

    <div class="grid gap-sm justify-items-md-end">
        <app-button icon="settings"
                    appScrollTo="projet-contacts-section"
        >
            {{ 'changer_offre'|translate }}
        </app-button>
        <!--        <app-button variant="outline" icon="arrow-forward-outline">-->
        <!--            Accès programmmateur TODO-->
        <!--        </app-button>-->
        <!--        <app-button variant="outline" icon="arrow-forward-outline">-->
        <!--            Accès arrosage TODO-->
        <!--        </app-button>-->
        <!--        <app-button variant="outline" icon="arrow-forward-outline">-->
        <!--            Accès secteur TODO-->
        <!--        </app-button>-->
    </div>
</div>
