<ion-slides [options]="slideOpts"  [scrollbar]="'true'" *ngIf="jours.length  > 0"  (ionSlidesDidLoad)="ionSlidesDidLoad(jours)">
  <!-- [ngStyle]="{'background-color': detail.couleur}" -->
  <ion-slide *ngFor="let detail of jours" (click)="display.presentModalBulletin(detail)"   style="border-radius:5px;height:100px;min-width:130px;background: linear-gradient(0deg, rgba(255,255,255,1) 0%, {{detail?.couleur}} 100%);cursor:pointer;" >

    <!-- <h4 style="font-size:0.3em;">{{detail?.type_preco}}</h4> -->
    <div style="position:absolute;left:0px;top:0px;margin:3%;font-size:0.6em" *ngIf="detail.date_mes">
      {{detail?.date_mes?.replace(' ', 'T')  |  date:"shortDate"}}
    </div>
    <div style="margin-top:30px;">

      <img *ngIf="detail.preco_picto" src="{{photoUrl + '' +detail.preco_picto}}" style="height:40px;display:inline-block !important"/>
      <!-- <img *ngIf="(detail.type_preco == 'groupe_type_preco_manu' || detail.type_preco == 'preco_manu') && detail.preco_full && (detail.text_BULL_MANU_CONSEIL_PRECO == 'Ne pas arroser cette semaine.' || detail.text_BULL_MANU_CONSEIL_PRECO == 'Ne pas arroser cette semaine')" src="{{photoUrl + '/images/urbasense/icones/icones_graphes/goutte_exclamation_vert.svg'}}" style="height:40px;display:inline-block !important"/>
      <img *ngIf="(detail.type_preco == 'groupe_type_preco_manu' || detail.type_preco == 'preco_manu') && detail.preco_full && detail.text_BULL_MANU_CONSEIL_PRECO == 'Arroser cette semaine'" src="{{photoUrl + '/images/urbasense/icones/icones_graphes/goutte_exclamation_bleu.svg'}}" style="height:40px;display:inline-block !important"/> -->


      <img *ngIf="(detail.type_preco == 'groupe_type_preco_manu' || detail.type_preco == 'preco_manu') && detail.arro_picto && detail.arro_picto !=='/images/urbasense/icones/icones_graphes/robinet_null.svg' && detail.arro_picto !=='/images/urbasense/icones/icones_graphes/null.jpg'" src="{{photoUrl + '' +detail.arro_picto}}" style="height:40px;display:inline-block !important"/>


      <p *ngIf="detail?.preco"  style="font-size:0.6em">{{detail?.preco | translate}} <font *ngIf="detail.quantite  != 0">{{detail?.quantite}} Litres</font></p>
      <!-- <p *ngIf="detail?.preco_full"  style="font-size:0.6em">{{detail?.text_BULL_MANU_CONSEIL_PRECO}} <font *ngIf="detail?.BULL_MANU_CONSEIL_PRECO == '20'">{{detail.quantite_val}}L</font></p> -->

    </div>
    <!-- <h5 >{{detail.date_mes }} </h5><br>
 <div style="width:100%;text-align:center;">

    </div>  -->

  </ion-slide>
</ion-slides>