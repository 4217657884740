import {ComponentFactoryResolver, Injectable} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min';
import 'leaflet.markercluster';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ISujet} from '../interfaces/ISujet';
import {UtilsService} from './utils.service';
import {ProjectInfo} from '../interfaces/IProjet';
import {IPosition} from '../interfaces/IPosition';

export enum LeafletTemplateEnum {
    STREET = 'STREET',
    TOPO = 'TOPO',
    SATELLITE = 'SATELLITE',
    IGN = 'IGN',
}

export enum LeafletUrbasenseIconColorEnum {
    BLUE = 'blue',
    GREEN = 'green',
    YELLOW = 'yellow',
    RED = 'red',
}


@Injectable({
    providedIn: 'root'
})
export class MapService {
    readonly FRANCE_COORDINATES = L.latLng(46.058262, 2.775859);
    readonly ZOOM_LEVELS = {
        strong: 13,
        medium: 8,
    };

    // TILES ==================
    // templateStreet = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    templateStreet = environment.mapStyleUrl;
    templateIgn =
        'https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?' +
        '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0' +
        '&STYLE=normal' +
        '&TILEMATRIXSET=PM' +
        '&FORMAT=image/jpeg' +
        '&LAYER=ORTHOIMAGERY.ORTHOPHOTOS' +
        '&TILEMATRIX={z}' +
        '&TILEROW={y}' +
        '&TILECOL={x}';
    templateSatellite = 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
    templateTopo = 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png';
    // templateCycle: 'http://{s}.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png'; // unused

    defaultTileLayerOptions: L.TileLayerOptions = {
        // attribution: '<a href="http://openstreetmap.org">© OpenStreetMap contributors</a>',
        minZoom: 0,
        maxZoom: 18,
        tileSize: 256 // les tuiles du Géooportail font 256x256px
    };

    defaultIconOptions: L.IconOptions = {
        iconUrl: '../../../../assets/UrbaNewPictos/Vieux_arbres/vieux_arbres_default.png',
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -28],
        className: 'urbasense-cluster-icon'
    };

    defaultLocationControlOption = {
        setView: true,
        keepCurrentZoomLevel: false,
        icon: 'fa fa-location-arrow',
        metric: true,
        onLocationError: (err) => console.error(err.message),
    };


    constructor(
        private translator: TranslateService,
        private utils: UtilsService,
    ) {
    }

    getIcon(options: Partial<L.IconOptions> = {}): L.Icon {
        return L.icon({
            ...this.defaultIconOptions,
            ...options,
        });
    }

    createCluster(): L.MarkerClusterGroup {
        const icon: L.Icon = this.getIcon({});

        return new L.MarkerClusterGroup({
            showCoverageOnHover: false,
            iconCreateFunction: (cluster) => {

                const markers = cluster.getAllChildMarkers();
                icon.options.className = 'urbasense-cluster-icon';
                for (const marker of markers) {
                    if ((marker as any).niveau === 'urgent') {
                        icon.options.className = 'cluster-icon-urgent';
                    }
                }

                return L.divIcon({
                    html: '<div class="' + icon.options.className + '">' +
                        '<span>' + cluster.getChildCount() + '</span>' +
                        '</div>',
                    className: 'cluster-icon',
                    iconSize: icon.options.iconSize
                });
            }
        });
    }

    getLocationControl(options = {}) {
        // @ts-ignore
        return L.control.locate({
            ...this.defaultLocationControlOption,
            ...options
        });
    }

    getTileLayer(template: LeafletTemplateEnum = LeafletTemplateEnum.STREET): L.TileLayer {
        switch (template) {
            case LeafletTemplateEnum.STREET:
                return L.tileLayer(this.templateStreet, {
                    ...this.defaultTileLayerOptions,
                    attribution: '<a href="http://openstreetmap.org">© OpenStreetMap contributors</a>',
                });
            case LeafletTemplateEnum.IGN:
                return L.tileLayer(this.templateIgn, {
                    ...this.defaultTileLayerOptions,
                    attribution: '<a href="http://www.ign.fr">© IGN-F/Geoportail</a>',
                });
            case LeafletTemplateEnum.SATELLITE:
                return L.tileLayer(this.templateSatellite, {
                    ...this.defaultTileLayerOptions,
                    // attribution: withoutAttribution ? '' : 'satellite',
                });
            case LeafletTemplateEnum.TOPO:
                return L.tileLayer(this.templateTopo, {
                    ...this.defaultTileLayerOptions,
                    // attribution: withoutAttribution ? '' : 'topo',
                });
        }
    }

    getAllLayers(): L.Control.LayersObject {
        const BASE_TRAD_KEY = 'map_tile_style';
        return Object.values(LeafletTemplateEnum).reduce((acc, key) => {
            acc[this.translator.instant(`${BASE_TRAD_KEY}.${key.toLowerCase()}`)] = this.getTileLayer(key);
            return acc;
        }, {});
    }

    getLayersControl(overlays = {}, options = {}): L.Control.Layers {
        return L.control.layers(
            this.getAllLayers(),
            overlays,
            options
        );
    }

    /**
     * Parse a Sujet object into a Leaflet marker
     * with basic styling.
     */
    generateMarkerFromSujet(sujet: ISujet): L.Marker {
        const position = this.utils.parsePosition(sujet.position);

        return L.marker(position, {
            icon: L.icon({
                iconUrl: this.utils.getIconObject(sujet),
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                popupAnchor: [0, -28]
            })
        });
    }

    generateMarkerFromProjectInfo(projectInfo: ProjectInfo): L.Marker {
        // const position = this.utils.parsePosition(projectInfo.g_carte);
        // console.log(position);
        const position: IPosition = {lat: 48.8534, lng: 2.3488};

        const customIcon = L.divIcon({
            html: `<a class="custom-marker" href="/accueil">
                 <span class="marker-icon">&#9733;</span>
               </a>`,
            className: 'custom-div-icon',
            iconSize: [30, 42],
            iconAnchor: [15, 42],
            popupAnchor: [0, -40]
        });
        return L.marker(position, {
            icon: customIcon
        });
    }

}
