import {Component, OnInit} from '@angular/core';
import {IUser} from '../../../interfaces/IUser';
import {AuthService} from '@auth0/auth0-angular';
import {UserService} from '../../../services/user.service';
import {UserRolesEnum} from '../../../enums/UserRolesEnum';

@Component({
    selector: 'app-profil-card',
    templateUrl: './profil-card.component.html',
    styleUrls: ['./profil-card.component.scss'],
})
export class ProfilCardComponent implements OnInit {
    user: IUser;
    roles: UserRolesEnum[];
    loading = false;

    constructor(
        public auth: AuthService,
        public userService: UserService,
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.auth.user$.subscribe(
            async (user) => {
                this.user = user as IUser;
                this.roles = this.userService.parseUserRoles(user);
                this.loading = false;
                // console.log(this.user);
                // console.log(this.roles);
            }
        );
    }

}
