<div *ngIf="interventions && interventions.length === 0">
  <p>{{ 'none_interventions' | translate }}</p>
</div>
<div class="responsive-table desktop-only">
    <table style="width:100%" *ngIf="interventions && interventions.length> 0">
      <thead>
    <tr>
      <th>{{ 'region' | translate | uppercase }}</th>
      <th>{{ 'site' | translate | uppercase }}</th>
      <th>{{ 'sujet' | translate | uppercase }}</th>
      <th>{{ 'type' | translate | uppercase }}</th>
      <th>{{ 'status' | translate | uppercase}}</th>
      <th>{{ 'published_at' | translate | uppercase }}</th>
<!--      <th>{{ 'resp_eqpt_short' | translate | uppercase }}</th>-->
<!--      <th style="text-align:center !important;">{{ 'selection' | translate | uppercase }}</th>-->
      <th style="text-align:center !important;">{{ 'details' | translate | uppercase }}</th>
    </tr>
    </thead>
    <tbody>
    <tr intervention-row *ngFor="let intervention of interventions"
        [intervention]="intervention"
        [short]="true"
        (showInterventionHistoryModalEmitter)="display.presenterModalHistoriqueIntervention(intervention.interv_id)"
      >
    </tr>
    </tbody>
  </table>
</div>
<div class="responsive-only" style="color: white">
  <ul class="card-list">
    <li intervention-row *ngFor="let intervention of interventions"
        [card]="true"
        [intervention]="intervention"
        [short]="true"
        (showInterventionHistoryModalEmitter)="display.presenterModalHistoriqueIntervention(intervention.interv_id)"
      >
    </li>
  </ul>
</div>
