<app-modal-header
        [title]="'maintenance'"
></app-modal-header>

<ion-content>
  <app-sujet-maintenance-form
          [sujet]="sujet"
          [interventionId]="interventionId"
  >
  </app-sujet-maintenance-form>
</ion-content>
