<div style="height: 100%;">
    <div leaflet
         (leafletMapReady)="onMapReady($event)"
         style="width: 100%;
         position: relative;"
         [ngStyle]="{'height': field.routing ? '30rem' : '20rem'}"
    >
        <label
                class="geo-input__label"
                for="{{ field.key }}">{{ field.label | translate }}</label>

        <ion-icon
                data-from="left"
                class="show-geoinput"
                *ngIf="control.disabled"
                name="eye-outline"></ion-icon>
    </div>
    <small class="transparent" *ngIf="this.userLocation">{{ this.userLocation }}</small>
    <small class="transparent"
           *ngIf="this.userLocation == null">{{ 'position_utilisateur_introuvable' | translate }}</small>

    <div class="grid grid-auto-fit gap-md">

        <app-button
                [disabled]="!showResetButton"
                (btnClick)="resetMap()"
                type="button"
                variant="ghost"
                color="danger"
        >
            <ion-icon name="close-outline"></ion-icon>
            {{ 'reset' | translate }}
        </app-button>

        <app-button
                *ngIf="showMarkerOnMe || true"
                (btnClick)="putMarkerOnMe()"
                type="button"
                variant="ghost"
                color="secondary"
        >
            <ion-icon name="flag-outline"></ion-icon>
            {{ 'geoinput_onme' | translate }}
        </app-button>


        <a *ngIf="this.marker != null"
           class="block"
           href="http://maps.apple.com/?q={{this.marker.getLatLng().lat}},{{this.marker.getLatLng().lng}}"
           target="_blank"
        >
            <app-button variant="ghost" icon="location-outline"
                        fill="block"
                        color="warning"
                        class="h-full"
            >
                {{ 'geoinput_googlemap' | translate }}
            </app-button>
        </a>

        <app-button
                *ngIf="this.markers.length"
                color="warning"
                (btnClick)="this.seeMarkersOnGoogleMaps()"
        >
            <ion-icon name="location-outline"></ion-icon>
            {{ 'geoinput_googlemap' | translate }}
        </app-button>

        <app-button
                *ngIf="showResetButton && canSubmit"
                color="success"
                variant="ghost"
                fill="block"
                icon="checkmark"
                type="submit"
                [disabled]="control.disabled"
        >
            {{ 'submit' | translate }}
        </app-button>
    </div>
</div>
