<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<!--// Anchor version-->
<a *ngIf="to != null || href != null" class="urbabtn"
   [routerLink]="to"
   [queryParams]="queryParams"
   [queryParamsHandling]="queryParamsHandling ? queryParamsHandling : null"
   [attr.href]="href ? href :  null"
   [attr.target]="target"
   [rel]="target === '_blank' ? 'noopener noreferrer' : null"
   [fragment]="fragment"
   [attr.data-color]="color"
   [attr.data-variant]="variant"
   [attr.data-size]="size"
   [attr.data-fill]="fill"
   [attr.title]="title ? (title | translate) : null"
   [attr.data-radius]="rounded"
   [ngClass]="btnClass"
>
    <ion-icon *ngIf="icon" icon="{{icon}}" class="shrink-0"></ion-icon>
    <span class="contents">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
    <ion-icon *ngIf="suffix" icon="{{suffix}}" class="shrink-0"></ion-icon>
</a>

<!--// Button version-->
<button *ngIf="to == null && href == null" class="urbabtn"
        [attr.data-color]="color"
        [attr.data-variant]="variant"
        [attr.data-size]="size"
        [attr.data-fill]="fill"
        [attr.data-radius]="rounded"
        [attr.role]="role"
        [attr.type]="type"
        [attr.disabled]="disabled || loading ? true : null"
        [attr.title]="title ? (title | translate) : null"
        [ngClass]="btnClass"
        (click)="onBtnClick()"
>
    <ion-icon *ngIf="icon" icon="{{icon}}" class="shrink-0"></ion-icon>
    <span class="contents">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
    <ion-icon *ngIf="suffix" icon="{{suffix}}" class="shrink-0"></ion-icon>
</button>
