<div class="grid gap-md items-start">
    <div class="col-span flex items-center gap-xs">
        <h2 class="heading flex items-center gap-xs flex-1">
            <ion-icon icon="images-outline"></ion-icon>
            {{ 'photos'|translate }}
        </h2>
        <div class="flex gap-xs">
        </div>
    </div>

    <div class="sujet-galerie-section-grid">
        <app-file-form
                [title]="'Ajouter une photo'"
                class="grid gap-md bg-surface-1 p-sm"
                (onFileUpload)="handleFileUpload($event)"
                [loading]="sendingNewImage"
        ></app-file-form>

        <div class="carousel-wrapper">
            <app-sujet-galerie-carousel
                    [photos]="photos"
                    (selectedPhotoChange)="handleSelectedPhotoChanged($event)"
            ></app-sujet-galerie-carousel>
        </div>

        <div class="figure-wrapper">
            <app-figure *ngIf="featuredPhoto else no_picture"
                        [imgSrc]="prefix + featuredPhoto.photo_path"
                        [blurhash]="featuredPhoto.blurhash"
            >
<!--                // min-height to avoid layout switch when toggling edit mode (3rem is height of input)-->
                <div class="flex gap-sm items-center" style="min-height: 3rem">
                    <div class="flex-1">
                        <p class="p-sm"
                           [ngClass]="{hidden: isEditing}"
                           (click)="toggleEdit()"
                        >{{ featuredPhoto.legende }}</p>
                        <app-input-simple
                                [ngClass]="{hidden: !isEditing}"
                                [control]="legendControl"
                                prefix="pencil"
                                inputClass="bg-surface-1"
                                (keydown.enter)="handleUpdateLegend()"
                        ></app-input-simple>
                    </div>

                    <app-button
                            *ngIf="isEditing"
                            icon="checkmark"
                            variant="outline"
                            [rounded]="'full'"
                            title="valider"
                            color="success"
                            (btnClick)="handleUpdateLegend()"
                            [loading]="updatingLegend"
                    ></app-button>

                    <app-button
                            mat-raised-button
                            matTooltip="{{'editer' | translate}}"
                            [icon]="isEditing ? 'close' : 'pencil'"
                            variant="ghost"
                            [rounded]="'full'"
                            title="editer"
                            [color]="isEditing ? 'danger' : null"
                            (btnClick)="toggleEdit()"
                            [loading]="updatingLegend"
                    ></app-button>
                </div>

                <p class="block text-muted uppercase mt-sm">
                    {{'importee'|translate}}
                    <span *ngIf="featuredPhoto.date_photo">
                        {{'at'|translate}} {{ featuredPhoto.date_photo | date: 'dd/MM/y' }}
                    </span>
                    <span class="lowercase">
                        {{ 'by' | translate }} {{ featuredPhoto.user ? featuredPhoto.user : ('inconnu' |translate) }}
                    </span>
                </p>
            </app-figure>
            <ng-template #no_picture>
                <div class="bg-surface-1 text-center py-lg fs-h3">
                    {{ 'aucune_photo_selectionnee' | translate }}
                </div>
            </ng-template>
        </div>

    </div>
</div>


