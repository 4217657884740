<div class="urbaradio-btn-group flex">
    <div *ngFor="let choice of choices" class="radio-button">
        <input
                type="radio"
                id="{{ choice.value }}"
                [value]="choice.value"
                [(ngModel)]="selectedValue"
                hidden
        >
        <label [for]="choice.value" (click)="onSelectValue(choice.value)">
            <app-button [variant]="selectedValue === choice.value ? 'default' : 'outline'">
                {{ choice.label | translate}}
            </app-button>
        </label>
    </div>

</div>
