<div class="grid gap-lg">
    <h2 class="heading col-span">{{title}}</h2>

    <app-wip class="bg-surface-1"></app-wip>

<!--    <mat-accordion>-->
<!--        <mat-expansion-panel *ngFor="let item of questions" class="bg-surface-1 shadow-none">-->
<!--            <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                    {{ item.question|translate }}-->
<!--                </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <p>-->
<!--                {{item.answer|translate}}-->
<!--            </p>-->
<!--        </mat-expansion-panel>-->
<!--    </mat-accordion>-->
</div>
