import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ComboBoxOption} from '../combobox/combobox.component';
import {UtilsService} from '../../../services/utils.service';
import {InputSimpleComponent} from '../input-simple/input-simple.component';

@Component({
    selector: 'app-combobox-dialog',
    templateUrl: './combobox-dialog.component.html',
    styleUrls: ['./combobox-dialog.component.scss'],
})
export class ComboboxDialogComponent implements OnInit, AfterViewInit {
    @ViewChild(InputSimpleComponent) input!: InputSimpleComponent;
    @ViewChild('optionList', {read: ElementRef, static: false}) optionList: ElementRef<HTMLUListElement>;
    // Attributes
    @Input() placeholder: string;
    @Input() listId: string = 'combolist-' + Math.random().toString(36).substr(2, 9);

    // Control
    @Input() value: ComboBoxOption[];
    @Input() validator: ValidatorFn;
    @Input() options: ComboBoxOption[];
    @Input() multiple: boolean = false;


    filteredOptions: ComboBoxOption[];
    cursorIndex: number;

    searchControl = new FormControl('');
    selectionControl = new FormControl([]);

    // Debounce
    @Input() debounce?: number;
    debouncedApplyFilter: () => void;

    constructor(
        private modalController: ModalController,
        private utils: UtilsService,
    ) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.input.focus();
        }, 300);
    }

    ngOnInit() {
        this.selectionControl.setValue(this.value);
        this.selectionControl.setValidators(this.validator);

        this.applyFilter();

        // Setting the debounce delay if provided
        this.debouncedApplyFilter = this.debounce ?
            this.utils.debounce(this.applyFilter.bind(this), this.debounce)
            : this.applyFilter.bind(this)
        ;

        this.cursorIndex = this.filteredOptions.length > 0 ? 0 : null;

        this.searchControl.valueChanges.subscribe((e) => {
            this.debouncedApplyFilter();
            this.cursorIndex = this.filteredOptions.length > 0 ? 0 : null;
        });

        this.selectionControl.setValue(this.value ?? []);
        this.removeDuplicates();
    }

    setSelectedOptionIndex(index: number) {
        this.cursorIndex = Math.max(0, index);
    }

    removeDuplicates() {
        this.selectionControl.setValue(Array.from(new Set(this.selectionControl.value)));
        const inArray = [];
        this.selectionControl.setValue(this.selectionControl.value.filter(option => {
            if (!inArray.includes(option.value)) {
                inArray.push(option.value);
                return true;
            }
            return false;
        }));
    }


    applyFilter() {
        if (this.searchControl.value?.trim().length > 0) {
            this.filteredOptions = this.options.filter(option => {
                return option.label.toLowerCase().includes(this.searchControl.value?.toLowerCase())
                    || option.value.toLowerCase().includes(this.searchControl.value?.toLowerCase())
                    ;
            });
        } else {
            this.filteredOptions = this.options;
        }
    }

    handleSelection(option: ComboBoxOption) {
        if (this.multiple) {
            option = option ?? this.filteredOptions[this.cursorIndex];
            if (option == null) {
                return;
            }

            if (this.optionIsSelected(option)) {
                this.removeFromSelection(option);
            } else {
                this.selectionControl.setValue([...this.selectionControl.value, option]);
            }

            this.removeDuplicates();

            // this.resetSearch();
        } else {
            this.handleChoice(option);
        }
    }

    resetSearch() {
        this.searchControl.setValue('');
    }

    addToSelection(option: ComboBoxOption): void {
        this.selectionControl.setValue([...this.selectionControl.value, option]);
    }

    removeFromSelection(option: ComboBoxOption) {
        this.selectionControl.setValue(this.selectionControl
            .value.filter((c: ComboBoxOption) => c.value !== option?.value));
    }


    handleChoice(option: ComboBoxOption) {
        option = option ?? this.filteredOptions[this.cursorIndex];

        if (option == null) {
            return;
        }

        this.selectionControl.setValue([option]);
        this.closeWithValue();
    }

    closeWithValue() {
        this.modalController.dismiss({
            value: this.selectionControl.value,
        });
    }

    closeAndReset() {
        this.modalController.dismiss({
            value: [],
        });
    }

    close(e: Event) {
        if (e) {
            e.preventDefault();
        }
        this.modalController.dismiss(null);
    }

    log() {
        console.log({
            value: this.searchControl.value,
            control: this.searchControl,
            options: this.options,
            selectionControlValue: this.selectionControl.value,
            selectionControl: this.selectionControl,
            filteredChoices: this.filteredOptions,
            selectedChoiceIndex: this.cursorIndex,
        });
    }

    moveCursorTo(e: Event, newIndex: number = 0): void {
        e.preventDefault();
        this.cursorIndex = Math.max(0, Math.min(newIndex, this.filteredOptions.length - 1));
        this.cursorIntoView();
    }

    moveCursorBy(e: Event, move: number = 1): void {
        e.preventDefault();

        if (this.cursorIndex == null && this.filteredOptions.length > 0) {
            this.cursorIndex = 0;
            return;
        }

        this.cursorIndex = (this.cursorIndex + move + this.filteredOptions.length) % this.filteredOptions.length;
        this.cursorIntoView();
    }

    cursorIntoView() {
        this.optionList.nativeElement.children[this.cursorIndex].scrollIntoView({
            block: 'center',
        });
    }

    getOptionOnCursor(): ComboBoxOption {
        if (this.cursorIndex == null) {
            return null;
        }
        return this.filteredOptions[this.cursorIndex];
    }

    optionIsSelected(choice: ComboBoxOption): boolean {
        return this.selectionControl.value?.some((c: ComboBoxOption) => c.value === choice.value);
    }

    handleSelectionAndRefocus(e: Event, option) {
        this.handleSelection(option);
        this.input.focus();
    }


    handleExtraBackspace() {
        if (this.searchControl.value === '' || this.searchControl.value == null) {
            this.removeLastSelectedOption();
        }
    }

    removeLastSelectedOption() {
        if (!this.multiple || this.selectionControl.value.length === 0) {
            return;
        }
        this.removeFromSelection(this.selectionControl.value[this.selectionControl.value.length - 1]);
    }
}
