import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges, OnInit {
    @Input() resultsPerPage: number = 1;
    @Input() maxResults: number;
    @Input() resultsLabel: string = 'results';
    @Input() currentPage: number = 1;
    @Input() nbOfPages: number = 1;
    @Input() pages: number[] = [1];
    @Input() queryParam: string = 'page';
    @Output() pageChanged = new EventEmitter<number>();
    @Input() showFirstLastButtons = true;
    @Input() containerClass: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.nbOfPages) {
            this.generatePageRange();
        }
    }

    ngOnInit() {
        this.generatePageRange();
    }

    goTo(page: number) {
        this.currentPage = Math.max(1, Math.min(this.nbOfPages, page));
        this.pageChanged.emit(this.currentPage);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {[this.queryParam]: this.currentPage},
            queryParamsHandling: 'merge',
        });
    }

    generatePageRange() {
        this.pages = Array(this.nbOfPages).fill(0).map((x, i) => i + 1);
    }
}
