<div class="grid gap-lg p-lg-lg p-md bg-surface-1">
    <h3 class="heading">{{'newsletter_form_title'|translate}}</h3>
    <app-wip></app-wip>
<!--    <p class="fw-bold">{{'newsletter_form_cta'|translate}}</p>-->

<!--    <mat-checkbox-->
<!--            [(ngModel)]="hasAcceptedConditions"-->
<!--            [color]="'primary'"-->
<!--    >-->
<!--        {{ 'mentions_legales_accept'|translate }}-->
<!--    </mat-checkbox>-->

<!--    <mat-slide-toggle [color]="'primary'"-->
<!--                      [disabled]="!hasAcceptedConditions"-->
<!--    >-->
<!--        {{ 'subscribe_me'|translate }}-->
<!--    </mat-slide-toggle>-->
</div>
