<div class="h-full flex flex-column gap-sm p-md overflow-auto">
    <div *ngIf="multiple" style="height: 2.5rem">
        <span *ngIf="selectionControl.value.length == 0" class="text-neutral text-muted">
            {{ 'no_selection' | translate }}
        </span>

        <ul *ngIf="selectionControl.value.length > 0"
            class="clean-list flex min-w-0 gap-2xs overflow-auto scrollable-x w-full"
        >
            <li *ngFor="let option of selectionControl.value" class="shrink-0">
                <app-button type="button"
                            variant="ghost"
                            [color]="''"
                            size="sm"
                            data-from="right"
                            suffix="close"
                            (click)="handleSelectionAndRefocus($event, option)">
                    {{ option.label }}
                </app-button>
            </li>
        </ul>
    </div>

    <div class="flex w-full gap-sm items-center">
        <app-input-simple
                class="flex-1"
                type="search"
                role="combobox"
                prefix="search"
                inputClass="bg-surface-1"
                [ariaControls]="listId"
                [placeholder]="placeholder"
                [autofocus]="false"
                [control]="searchControl"
                [resetable]="true"
                (keydown.enter)="handleSelection(null)"
                (keydown.backspace)="handleExtraBackspace()"
                (keydown.arrowUp)="moveCursorBy($event, -1)"
                (keydown.arrowDown)="moveCursorBy($event, 1)"
                (keydown.control.k)="moveCursorBy($event, -1)"
                (keydown.control.j)="moveCursorBy($event, 1)"
                (keydown.control.u)="moveCursorTo($event, 0)"
                (keydown.control.d)="moveCursorTo($event, filteredOptions.length - 1)"
                (keydown.pageUp)="moveCursorTo($event, 0)"
                (keydown.pageDown)="moveCursorTo($event, filteredOptions.length - 1)"
                (keydown.escape)="close($event)"
                [autocomplete]="'off'"
                [ariaAutoComplete]="'list'"
                [ariaExpanded]="true"
                [ariaControls]="listId"
        ></app-input-simple>

        <app-button
                *ngIf="multiple"
                class="bg-surface-1"
                icon="checkmark"
                variant="ghost"
                type="button"
                [color]="''"
                (click)="closeWithValue()"
        ></app-button>

        <app-button
                class="bg-surface-1"
                icon="close"
                variant="ghost"
                type="button"
                [color]="''"
                (click)="closeAndReset()"
        ></app-button>
    </div>


    <ul #optionList class="clean-list flex flex-column flex-1 gap-2xs scrollable false-padding-2xs"
        id="{{ listId }}"
        role="listbox"
    >
        <li *ngIf="filteredOptions.length == 0"
            class="flex flex-column gap-sm h-full justify-center items-center
            text-neutral text-muted fs-lg">
            <ion-icon icon="search"></ion-icon>
            <span>{{ 'no_match' | translate }}</span>
        </li>

        <li *ngFor="let option of filteredOptions; let i = index"
            (click)="handleSelection(option)"
            (mouseenter)="setSelectedOptionIndex(i)"
            role="option"
            [ngClass]="{
                'selected': optionIsSelected(option),
                'active-urbacard': i == cursorIndex
            }"
        >
            <div class="urbacard bg-surface-1 cursor-pointer duration-200 rounded-sm">
                {{ option.label }}
            </div>
        </li>
    </ul>

    <app-form-error
            [errors]="selectionControl.errors"
    ></app-form-error>
</div>
