<ion-grid style="width:100%">
<ion-row *ngIf="modeAffichage == 'detail'">
  <ion-col size="12" size-lg="4" *ngFor="let indicateur of data?.listeParams | keyvalue">
  <ion-card >
    <ion-item [style.--background]="data[indicateur.value]?.couleur" [style.--color]="'#FFFFFF'">
      <ion-label class="ion-text-wrap"  matTooltip="{{ data[indicateur.value]?.tooltip | translate }}" >
        <h4 style="font-weight:bolder;font-size:large;">{{data[indicateur.value]?.nom | translate}}</h4>
        <p style="font-weight:bolder;font-size:xx-large;line-height: 34px;" [style.color]="'#FFFFFF'">{{data[indicateur.value]?.valeur}} {{data[indicateur.value]?.unite}}</p>
      </ion-label>

      <!-- <ion-badge style="background-color: rgba(0, 0, 255, 0);">
        <ion-icon name="checkmark-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'ok'" color="transparent"></ion-icon>
        <ion-icon name="eye-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'vigilance'" color="transparent"></ion-icon>
        <ion-icon name="alert-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'alerte'" color="transparent"></ion-icon>
      </ion-badge> -->
    </ion-item>
  </ion-card>
</ion-col>
</ion-row>

<ion-row *ngIf="modeAffichage == 'liste'">
    <ion-col *ngFor="let indicateur of data?.listeParams | keyvalue" >
        <ion-card>
            <ion-item [style.--background]="data[indicateur.value]?.couleur" [style.--color]="'#FFFFFF'">
              <ion-icon *ngIf="data[indicateur.value]?.icon" [name]="data[indicateur.value]?.icon" [size]="'large'" [style.--color]="'#55c277'"></ion-icon>
                <ion-label class="ion-text-wrap ion-text-center">
                  <h4 style="font-weight:bolder;">{{data[indicateur.value]?.nom | translate}}</h4>
                  <p *ngIf="!data[indicateur.value]?.picto" style="font-weight:bolder;" [style.color]="'#FFFFFF'">{{data[indicateur.value]?.valeur}} {{data[indicateur.value]?.unite}}</p>

                </ion-label>

                <!-- <ion-badge style="background-color: rgba(0, 0, 255, 0);">
                  <ion-icon name="checkmark-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'ok'" color="transparent"></ion-icon>
                  <ion-icon name="eye-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'vigilance'" color="transparent"></ion-icon>
                  <ion-icon name="alert-outline" size="large"  *ngIf="data[indicateur.value]?.badge === 'alerte'" color="transparent"></ion-icon>
                </ion-badge> -->
            </ion-item>
        </ion-card>
    </ion-col>
</ion-row>

<ion-row *ngIf="modeAffichage == 'map'">
  <ion-list>
      <ion-item *ngFor="let indicateur of data?.listeParams | keyvalue" [style.--background]="data[indicateur.value]?.couleur" [style.--color]="'#FFFFFF'">
          <ion-label>
            <h4>{{data[indicateur.value]?.nom | translate}}</h4>
            <p style="font-weight:bolder;margin:0px !important;" [style.color]="'#FFFFFF'">{{data[indicateur.value]?.valeur}} {{data[indicateur.value]?.unite}}</p>
          </ion-label>
      </ion-item>
  </ion-list>
  <!-- <ion-col size="12"  style="padding-top:0px !important;padding-bottom:0px !important;">
  <ion-card >
    <ion-item  style=" font-size: 12px; /* Taille du texte */
    padding: 4px 8px !important;
    height: auto !important;font-size:xx-small !important;">
      <ion-label class="ion-text-wrap"  matTooltip="{{ data[indicateur.value]?.tooltip | translate }}" >
        <h4 style="font-weight:bolder;"></h4>
        <p ></p>
      </ion-label>
    </ion-item>
  </ion-card>
</ion-col> -->
</ion-row>
</ion-grid>
