<button appScrollTo="ms_sujet_{{device.id_suj}}"></button>

<ion-item style="cursor:pointer !important;" id="ms_sujet_{{device.id_suj}}">
    <ion-label class="ion-text-wrap">
        <ion-text class="boldSf" (click)="display.presentModalMinisense(device.centrale)">
            {{ device.nom_device }}
        </ion-text>
        <!-- <p *ngIf="device.type_dispositif != 47 && device.type_dispositif != 48 && device.type_dispositif != 49">
          {{'etat_des_sondes' | translate}}
          <ion-icon *ngIf="device?.aberrantValeur == true"  style="vertical-align:middle;font-size:1em;color:red !important;height:18px;"  name="checkmark-circle"></ion-icon>
          <ion-icon *ngIf="device?.aberrantValeur != true" style="vertical-align:middle;font-size:1em;color:#55c277 !important;height:18px;" name="checkmark-circle"></ion-icon>
        </p>
        <p>
          {{'statut_communication' | translate}}
          <ion-icon  style="vertical-align:middle;font-size:1em;height:18px" [ngStyle]="utils.getDeviceInfoSynchro(device,'text')" name="wifi"></ion-icon>
        </p>
        <h5>{{'sujets_equipes' | translate}} :</h5>
       <ion-button (click)="goSujet(sujet.id_suj);" size="small" *ngFor="let sujet of device.sujets">{{sujet.nom_suj}}</ion-button>

      <ion-button size="small" fill="clear" [ngStyle]="{'color': getVbatColor(utils.getBatteryLevel(device))}">
        <ion-icon slot="start" name="battery-charging-outline"></ion-icon>
        {{utils.getBatteryLevel(device)}} V
      </ion-button> -->
        <app-affichage-indicateurs [data]="device.diagnostic_trame"
                                   [modeAffichage]="'liste'"></app-affichage-indicateurs>
        <h5>{{ 'sujets_equipes' | translate }} :</h5>
        <ion-button (click)="goSujet(sujet.id_suj);" size="small"
                    *ngFor="let sujet of device.sujets">{{ sujet.nom_suj }}
        </ion-button>
    </ion-label>
</ion-item>
