<ng-container *ngIf="(counters$ | async) as counters; else loading">
    <ion-badge *ngIf="counters[role] != null">
        {{counters[role][key]}}
    </ion-badge>
</ng-container>

<ng-template #loading>
    <ion-spinner [slot]="'end'" name="lines"></ion-spinner>
</ng-template>

