<ion-header>
    <ion-toolbar color="success">
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">{{'fermer' | translate}}</ion-button>
        </ion-buttons>
        <ion-title>&nbsp;</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <app-mission-editor
            [missionId]="id_mission"
            [interventionsSelectionnees]="interventionsSelectionnees"
            [typeDemandeur]="type_demandeur"
    ></app-mission-editor>
</ion-content>
