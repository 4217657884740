<form class="grid gap-lg"
      [formGroup]="form"
      (ngSubmit)="handleSubmit()">
    <fieldset class="contents">
        <legend class="heading fs-h4">{{title | translate}}</legend>

        <app-file-input-simple
                [control]="files"
                [multiple]="multiple"
        ></app-file-input-simple>

        <div class="grid gap-sm">
            <app-input-simple
                    label="légende"
                    placeholder="Légende"
                    [control]="form.get('legend')"
            ></app-input-simple>

            <app-button type="submit"
                        icon="checkmark"
                        class="justify-self-lg-end"
                        [loading]="loading"
            >
                {{'ajouter'|translate}}
            </app-button>
        </div>
    </fieldset>
</form>

