import {Component, Input, OnInit} from '@angular/core';
import {ProjetSimple} from '../../../pages/archive-bulletins/archive-bulletins.page';
import {ComboBoxOption} from '../../molecules/combobox/combobox.component';
import {Router} from '@angular/router';
import {EVService} from '../../../services/ev.service';
import {ModalController} from '@ionic/angular';
import {BulletinGroup, BulletinWithGroups} from '../../../interfaces/Bulletin';
import {ModularFormService} from "../../../services/modular-form.service";

@Component({
    selector: 'app-bulletin-modal',
    templateUrl: './bulletin-modal.component.html',
    styleUrls: ['./bulletin-modal.component.scss'],
})
export class BulletinModalComponent implements OnInit {
    @Input() projet: ProjetSimple;
    @Input() date: string; // date
    filterByArrosage: boolean = false;
    groups: BulletinGroup[];
    loading: boolean = false;
    error: boolean = false;


    constructor(
        private router: Router,
        private ev: EVService,
        private modalController: ModalController,
        public formService: ModularFormService,
    ) {
    }

    ngOnInit() {
        this.loading = true;

        this.ev.v7_detail_bulletin(this.projet.id_bulletin).subscribe({
            next: (bulletinLast: BulletinWithGroups) => {
                console.log(bulletinLast);
                this.groups = bulletinLast.groupes;
                this.loading = false;
                this.error = false;
            },
            error: (err) => {
                console.error(err);
                this.error = true;
                this.loading = false;
            }
        });
    }


    async onSearchSujet(options: ComboBoxOption[]) {
        if (options.length > 0) {
            await this.router.navigate(['/sujet', options[0].value]);

            // Need to wait for the Combobox modal to close before closing this modal
            setTimeout(async () => {
                await this.close();
            }, 100);
        }
    }

    async close() {
        await this.modalController.dismiss();
    }

}
