<ng-container *ngIf="indicateurs">
    <app-keyval *ngFor="let param of indicateurs.listeParams"
                [label]="indicateurs[param].nom"
                [value]="asBlock === false ? indicateurs[param].valeur : null"
    >
        <ng-container *ngIf="asBlock">
            {{ indicateurs[param].valeur }}
        </ng-container>
    </app-keyval>
</ng-container>
