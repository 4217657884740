import {Component, Input, OnInit} from '@angular/core';
import {Auth0Client} from '@auth0/auth0-spa-js';

import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from "../../services/utils.service";
import {EVService} from "../../services/ev.service";
import {Observable} from "rxjs";
import {ProjectInfo} from "../../interfaces/IProjet";
import {shareReplay} from "rxjs/internal/operators/shareReplay";
import {FormControl} from "@angular/forms";
import {DisplayService} from "../../services/display.service";
import {Id} from "../../interfaces/Id";
import {ComboBoxOption} from "../molecules/combobox/combobox.component";
import {ModularFormService} from "../../services/modular-form.service";


@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
    isAuthenticated = false;
    @Input() params: { active: boolean | string, url: string };
    @Input() textTitle: string;
    previousUrl: string = null;

    private auth0Client: Auth0Client;

    projects: Observable<ProjectInfo[]>;
    projects$: Observable<ProjectInfo[]> = this.ev.projects$;

    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private utils: UtilsService,
        private ev: EVService,
        public formService: ModularFormService,
    ) {
    }

    async ngOnInit() {
        this.route.queryParamMap.subscribe(queryParams => {
            // Priority is given to "from" param, then params.url
            this.previousUrl = queryParams.get('from') ?? null;
        });
    }

    onSearchProject(options: ComboBoxOption[]) {
        console.log(options);
        if (options.length > 0) {
            this.router.navigate(['/accueil'], {
                queryParams: {
                    site: options[0].value
                }
            });
        }
    }

    goBack() {
        this.location.back();
    }

    getPageTitleExcerpt() {
        if (this.textTitle) {
            return this.utils.getExcerpt(this.textTitle, 65);
        }
        return '';
    }

}
