<ion-item  text-wrap >
  <ion-avatar slot="start">
    <img  style="height:40px;vertical-align:middle;"    src="../../assets/UrbaNewPictos/Electrovanne/electrovanne_default.png" alt="{{programmation.nom_suj}}"  />
    </ion-avatar>
    <ion-label class="ion-text-wrap">
      <ion-grid>
        <ion-row>

        <ion-col size="12" size-sm>
          <ion-text (click)="goSujet(programmation.subjectId);" style="cursor:pointer;">
            <h4 class="boldSf">{{programmation?.nom_suj}} (Voie {{programmation.voie}})</h4>
            </ion-text>

            <ion-text fill="outline" (click)="display.presentModalCompteur(programmation)" *ngIf="programmation.id_compteur" style="cursor:pointer;">
              <ion-icon style="vertical-align:middle" name="speedometer-outline"></ion-icon>
              <font style="font-size:0.8em;margin-left:5px">{{programmation.nom_compteur}}</font>
            </ion-text>
            <p style="cursor:pointer;">{{'secteur' | translate}} {{programmation?.nom_grp2}}</p>
        </ion-col>

        <ion-col >
          <ion-button fill="outline" (click)="display.presentModalMinisense(programmation.centrale_id)" *ngIf="programmation.centrale_id != 5">{{programmation.device}}</ion-button>

        <ion-button  fill="outline" routerLink="{{programmation.subjectId}}/reprogrammer">{{'Semainier' | translate}}</ion-button>
         <ion-button fill="outline" routerLink="{{programmation.subjectId}}/duree" *ngIf="programmation.centrale_id != 5">{{'Durée & Départ' | translate}}</ion-button>
        </ion-col>

    </ion-row>
    </ion-grid>
      </ion-label>

</ion-item>


   <ion-item *ngIf='programmation && programmation.centrale_id != 5'>
  <ion-label class="ion-text-wrap" >



    <app-ouvertureev style="display:block" [programmation]="programmation"></app-ouvertureev>
    <ion-text *ngIf="(programmation?.lastActivatedProgram || programmation?.Aactiver) && programmation.centrale_id != 5" >
      <ion-icon style="vertical-align:middle" name="alarm-outline"></ion-icon>
     <font *ngIf="strDays.length > 0" style="font-size:0.8em;margin-left:5px">{{'Programmation en cours' | translate}} : {{utils.transformTime(programmation.ev_duree_ouverture)}} à {{programmation.ev_heure_ouverture}} les {{strDays}} </font>
     <font *ngIf="strDays.length == 0" style="font-size:0.8em;margin-left:5px">{{'Programme STOP' | translate}}</font>
    </ion-text>
    <ion-text style="display:block" color="success" *ngIf="programmation?.lastActivatedProgram && programmation.centrale_id != 5">
      <ion-icon style="vertical-align:middle" name="checkmark-circle-outline"></ion-icon>
      <font style="font-size:0.8em;margin-left:5px">{{'Programme actif' | translate}} {{programmation.lastActivatedProgram.programme.nom}} {{'depuis le' | translate}} {{programmation.lastActivatedProgram.date_status_change.replace(' ', 'T') | date:"mediumDate"}}</font>
    </ion-text>
    <ion-text style="display:block" color="warning" *ngIf="programmation?.Aactiver && programmation.centrale_id != 5">
      <ion-icon style="vertical-align:middle" name="alert-circle-outline"></ion-icon>
      <font style="font-size:0.8em;margin-left:5px">{{'Changement de programme demandé le' | translate}} {{programmation.Aactiver.date_status_change.replace(' ', 'T') | date:"mediumDate"}} {{programmation.Aactiver.programme.nom}}</font>
      <!-- <p><lib-angular-countdown-dateTime [endDateBind]="programmation.Aactiver.reprogrammation_estimee" [dayText]="'jours'" [hourText]="'heures'" [minutesText]="'minute'" [secondsText]="'secondes'"> </lib-angular-countdown-dateTime></p> -->
    </ion-text>
  </ion-label>

</ion-item>