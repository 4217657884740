import {Component, Input, OnInit} from '@angular/core';
import {Sujet} from '../../../interfaces/ISujet';
import {Indicateurs} from '../../../interfaces/Indicateurs';
import {
    MaintenanceConfirmDialogComponent
} from "../../dialogs/maintenance-confirm-dialog/maintenance-confirm-dialog.component";
import {IonicColorKeyEnum} from "../../../enums/IonicColorKeyEnum";
import {EVService} from "../../../services/ev.service";
import {ProjetService} from "../../../services/projet.service";
import {DisplayService} from "../../../services/display.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-sujet-dispositif-section',
    templateUrl: './sujet-dispositif-section.component.html',
    styleUrls: ['./sujet-dispositif-section.component.scss'],
})
export class SujetDispositifSectionComponent implements OnInit {
    @Input() subject: Sujet;
    @Input() autre_sondes: Indicateurs;
    @Input() indicateur_sondes: Indicateurs;

    @Input() sujets_equipes: {nom: string, valeur: string}[];
    @Input() id_sonde: string;

    constructor(
        private ev: EVService,
        public projetService: ProjetService,
        public display: DisplayService,
        public dialog: MatDialog,
        private router: Router,
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
        // Removing duplicates
        this.sujets_equipes = this.sujets_equipes.filter((obj1, i, arr) => {
            return arr.findIndex(obj2 => (obj2.valeur == obj1.valeur)) === i;
        }).sort((a, b) => a.nom.localeCompare(b.nom));
    }

    /**
     * Check if sujet has an open intervention
     * Dialog proposes to proceed. 3 possible answers
     * -intervention_id:  with the found intervention (if any)
     * -true:  without intervention
     * -false: cancel
     */
    openSujetMaintenanceForm(sujet) {
        this.ev.get_check_intervention_by_sujet(sujet.id_suj).subscribe(intervention => {
            // console.log(intervention);

            const dialogRef = this.dialog.open(MaintenanceConfirmDialogComponent, {
                data: {
                    intervention
                }, // sending intervention to dialog
            });

            dialogRef.afterClosed().subscribe(async (choice) => {
                if (typeof choice === 'string' || typeof choice === 'number') {
                    // Maintenance form will be populated with interventionId
                    this.ev.post_maintenance_snatch(intervention.intst_ref_interv).subscribe(async (res) => {
                        if (res === 'success') {
                            // Intervention belongs to user or was successfuly transfered to user
                            await this.router.navigate([`/maintenance-form/${sujet.id_suj}/${choice}`]);
                        } else {
                            await this.snatchFailed();
                        }
                    }, async (err) => {
                        await this.snatchFailed();
                    });
                } else if (choice === true) {
                    // Maitenance "sauvage" sans intervention associée
                    await this.router.navigate([`/maintenance-form/${sujet.id_suj}`]);
                }
            });
        });
    }

    async snatchFailed() {
        return await this.utils.presentToast(
            'maintenance_snatch_fail',
            0,
            IonicColorKeyEnum.DANGER,
            '',
            'bottom',
            true
        );
    }
}
