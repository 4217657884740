<div class="grid grid-cols-lg-2_1 gap-md">
    <div class="col-span flex items-center gap-xs">
        <h2 class="heading flex items-center gap-xs flex-1">
            <ion-icon icon="folder-outline"></ion-icon>
            {{ 'dernier_bulletin'|translate }}
        </h2>
        <div class="flex gap-xs">
        </div>
    </div>

    <app-offre-guard
            class="col-span grid grid-cols-1 grid-cols-lg-2_1 gap-md"
            [has]="subject.type_offre" [required]="projetService.offers.INDICATEURS"
            [cta]="true"
            classes="poison justify-center justify-md-between gap-md"
    >

        <div class="grid grid-cols-md-2 gap-md content-start">
            <app-keyvals [indicateurs]="indicateur_bulletins"
                         [asBlock]="true"
            ></app-keyvals>
        </div>

            <div class="grid gap-md justify-lg-end justify-items-lg-end  content-start items-start">
<!--                <p class="heading justify-self-lg-end">Nom du groupe TODO</p>-->

<!--                <app-button variant="outline">-->
<!--                    <ion-icon icon="download"></ion-icon>-->
<!--                    {{'telecharger'|translate}} TODO-->
<!--                </app-button>-->

                <app-button
                        [to]="['/bulletins']"
                        [queryParams]="{projects: subject.id_sit}"
                        variant="outline" icon="calendar">
                    {{'historique'|translate}}
                </app-button>
            </div>

    </app-offre-guard>
</div>
