<div class="flex w-full gap-md items-center">
    <label class="text-muted uppercase">
        {{'langue'|translate}}
    </label>

    <ion-select interface="action-sheet" [(ngModel)]="currentLanguage"
                class="flex-1 shadow rounded-sm bg-surface-1"
                [cancelText]="('annuler'|translate)"
                (ionChange)="this.handleLanguageChange($event.detail.value)">
        <ion-select-option *ngFor="let language of this.getAvailableLanguages()"
                           value="{{language.key}}"
        >{{ language.name }}
        </ion-select-option>
    </ion-select>
</div>
