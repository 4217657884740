<div class="grid grid-cols-lg-3 gap-md">
    <h2 class="heading h2 col-span">{{ 'indicateurs_avancés'|translate }}</h2>

    <div>
        <p class="mb-md">{{ 'activite_racinaire'|translate }}</p>
        <div class="bg-light" *ngIf="indicateurs_stade.sportActivite">
            <img [src]="utils.getSVGImage(indicateurs_stade.sportActivite.image)"
                 alt="">
        </div>
    </div>

    <div>
        <p class="mb-md">{{ 'eau_disponible_aux_racines'|translate }}</p>
        <div class="bg-light" *ngIf="indicateurs_stade.sportHumidite">
            <img [src]="utils.getSVGImage(indicateurs_stade.sportHumidite.image)"
                 alt="">
        </div>
    </div>

    <div>
        <p class="mb-md">{{ 'praticabilite'|translate }}</p>
        <div class="flex gap-sm flex-wrap">
            <div *ngFor="let indic of indicateurs_praticabilite; let i = index;"
                 class="text-center"
            >
                <div style="height: 2rem; width: 2rem;"
                     class="rounded-sm"
                     [style.background-color]="indic.couleur"
                     title="{{indic.valeur + indic.unite}}"
                ></div>
                <span class="fs-sm uppercase">
                    {{ ('jour'|translate)[0] }}+{{ i + 1 }}
                </span>
            </div>

        </div>
    </div>
</div>
