<ion-grid >
  <ion-row>
      <ion-col [size]="sizeBg"  [sizeLg]="sizeLg">
          <h3 class="boldSf">Activité racinaire</h3>
          <ion-grid>
            <ion-row>
                <ion-col>
                    <img [src]="utils.getSVGImageUrl(indicateurs.sport_activite.image)" alt="">
                </ion-col>
                <ion-col *ngIf="indicateurs.sport_activite.legende">
                    <ion-grid>
                        <ion-row *ngFor="let item of indicateurs.sport_activite.legende.echelle">
                            <div style="display: flex; align-items: center;">
                            <div [style.background-color]="'#' + item.couleur_hex" style="width:2vw; height:2vw; margin:auto;"></div>
                            <span style="margin-left: 10px;font-size:smaller;">{{item.texte}}</span>
                            </div>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
          </ion-grid>


      </ion-col>
      <ion-col [size]="sizeBg" [sizeLg]="sizeLg">
          <h3 class="boldSf">Eau disponible aux racines</h3>
          <ion-grid>
            <ion-row>
                <ion-col>
                    <img [src]="utils.getSVGImageUrl(indicateurs.sport_humidite.image)" alt="">
                </ion-col>
                <ion-col *ngIf="indicateurs.sport_humidite.legende">
                    <ion-grid>
                        <ion-row *ngFor="let item of indicateurs.sport_humidite.legende.echelle">
                            <div style="display: flex; align-items: center;">
                            <div [style.background-color]="'#' + item.couleur_hex" style="width:2vw; height:2vw; margin:auto;"></div>
                            <span style="margin-left: 10px;font-size:smaller;">{{item.texte}}</span>
                            </div>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
          </ion-grid>
      </ion-col>
      <ion-col [size]="sizeBg" [sizeLg]="sizeLg">
          <h3 class="boldSf">Praticabilité</h3>
          <p *ngIf="withLegend">{{indicateurs.praticabilite.msg[indicateurs.praticabilite.msg.listeParams[0]].tooltip}}</p>
          <ion-grid *ngIf="indicateurs.praticabilite">
            <ion-row>
                <ion-col  *ngFor="let str of indicateurs.praticabilite.msg.listeParams;let i = index;">
                    <div style="display: flex; align-items: center;">
                        <div style="margin:auto;">
                            <div *ngIf="!withLegend" [style.background-color]="indicateurs.praticabilite.msg[str].couleur" style="width:26px; height:26px;display:inline-block;line-height: 26px;vertical-align: middle;"></div>
                            <div *ngIf="withLegend" [style.background-color]="indicateurs.praticabilite.msg[str].couleur" style="width:26px; height:26px;text-align:center;line-height: 26px;vertical-align: middle;font-size:0.7em;font-weight: bolder;display:inline-block;">{{parseValeur(indicateurs.praticabilite.msg[str].valeur)}}</div>
                             <span style="margin-left: 6px;font-size:smaller;">J+{{ i+1 }}</span>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="indicateurs.praticabilite.msg.legende && withLegend">
                <ion-col >
                    <ion-grid>
                        <ion-row >
                            <ion-col *ngFor="let item of indicateurs.praticabilite.msg.legende">
                                <div style="display: flex; align-items: center;">
                                    <div [style.background-color]="item.couleur" style="width:20px; height:20px; margin:auto;"></div>
                                    <span style="margin-left: 10px;font-size:smaller;">{{item.nom | translate}} ({{item.valeurMin}} - {{item.valeurMax}})</span>
                                </div>
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col *ngIf="vegetal" [size]="sizeBg" [sizeLg]="sizeLg" style="text-align:center;line-height: 1;">
        <div>
            <div style="font-size:0.8em;">Minisense</div>
            <div style="font-size:0.8em;margin:4%;line-height:1.2;">
                <img style="width:30px;" src="{{utils.getImageReseau(vegetal)}}"/>
                <div>
                    <ion-button fill="outline" slot="end"
                                (click)="handleClickShowModal($event, vegetal.id_centrale)"
                                *ngIf="vegetal.id_centrale != 5">{{vegetal.device}}</ion-button>
                </div>
            </div>
        </div>
    </ion-col>
  </ion-row>
</ion-grid>