<app-modal-header
        [title]="'installation'"
></app-modal-header>

<ion-content>
  <app-sujet-installation-form
          [sujet]="sujet"
          [interventionId]="interventionId"
  >
  </app-sujet-installation-form>
</ion-content>
