<div *ngIf="short" #anchor></div>
<ng-image-fullscreen-view

        [images]="images"
        [imageIndex]="selectedImageIndex"
        [show]="showFlag"
        (close)="closeEventHandler()"></ng-image-fullscreen-view>

<ng-container *ngIf="!short; else shortVersion">
    <h2 *ngIf="title">{{ title | translate }}</h2>
    <!-- <ul class="gallery-item-list">
        <li *ngFor="let image of images; let i = index"
            (click)="showLightbox(i)">
            <ion-icon name="image-outline"></ion-icon>
            {{ image.title | translate }}
        </li>
    </ul> -->
    <ul class="gallery-item-list">
        <li *ngFor="let image of images; let i = index" >
            <ion-icon name="image-outline"></ion-icon>
            {{ image.title | translate }}
            <a href="{{ image.image }}" target="_blank" rel="noopener noreferrer">
                <ion-icon name="open-outline"></ion-icon>
            </a>
        </li>
    </ul>
</ng-container>

<ng-template #shortVersion>
    <button mat-button class="btn-gallery"
            [disabled]="images.length === 0"
            (click)="showLightbox(0)"
    >
        <ion-icon name="images-outline"></ion-icon>
        {{(title ? title : 'gallery') | translate }}</button>
</ng-template>
