<div class="container w-full py-lg text-neutral overflow-auto">
    <div class="grid grid-cols-1 gap-md bg-surface-1">
            <app-button
                    class="justify-self-end sticky top-0"
                    variant="ghost"
                    [color]="''"
                    rounded="full"
                    icon="close"
                    (btnClick)="close()"
            ></app-button>

        <article class="urbacard rounded-sm shadow">
            <header class="flex flex-column flex-md-row items-md-center gap-xs flex-wrap">
                <h3 class="heading flex-1 h3">
                    {{ projet.nom_site }}
                </h3>

                <div class="flex items-center self-end gap-sm">
                    <app-button variant="outline"
                                icon="download"
                                [downloadUrl]="projet.link"
                                [saveAs]="'projet-' + projet.id_site + 'bulletin-' + projet.id_bulletin + '.pdf'"
                    >
                        {{ 'telecharger_bulletin' | translate }}
                    </app-button>
                </div>
            </header>
            <div class="grid grid-cols-1 grid-cols-md-3">
                <app-card-rows
                        [indicateurs]="projet.indicateurs"
                ></app-card-rows>
            </div>
        </article>

        <mat-slide-toggle
                *ngIf="!loading && !error && groups?.length > 0"
                class="justify-self-center my-md"
                [(ngModel)]="filterByArrosage"
        >
            {{ 'filtrer_groupes_arrosages_preconises'|translate }}
        </mat-slide-toggle>

        <app-loader *ngIf="loading && !error"
                    [message]="'Chargement des groupes'"
        ></app-loader>

        <app-error *ngIf="!loading && error"></app-error>

        <ul *ngIf="!loading && !error" class="clean-list grid grid-cols-1 gap-md">

            <li *ngIf="groups.length == 0">
                {{ 'no_group'|translate }}
            </li>

            <li *ngFor="let group of groups">
                <article class="urbacard grid-cols-lg-2_1 rounded-sm shadow">
                    <div class="col-span flex items-center gap-xs">
                        <h2 class="heading flex items-center gap-xs flex-1">
                            <ion-icon icon="folder-outline"></ion-icon>
                            {{ group.nom_groupe }}
                        </h2>
                        <div class="flex gap-xs">
                        </div>
                    </div>

                    <div class="grid grid-cols-md-2 gap-md content-start">
                        <app-keyvals [indicateurs]="group.indicateurs"
                                     [asBlock]="true"
                        ></app-keyvals>
                    </div>

                    <div>
                        <app-combobox
                                inputClass="bg-surface-1"
                                [placeholder]="'sujets'"
                                (change)="onSearchSujet($event)"
                                [intoOptions]="{
                                    items: group.sujets,
                                    into: this.formService.sujetIntoComboboxOption
                                }"
                        ></app-combobox>
                    </div>
                </article>
            </li>
        </ul>
    </div>
</div>
