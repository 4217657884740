import {Component, Input, OnInit} from '@angular/core';
import {IFormField, IFormFieldTable, IFormStep, InputValue, ModularFormField} from '../../../interfaces/IFormStep';
import {ModularFormService} from '../../../services/modular-form.service';
import {AbstractControl, FormControl} from '@angular/forms';
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'app-table-input',
    templateUrl: './table-input.component.html',
    styleUrls: ['./table-input.component.scss'],
})
export class TableInputComponent implements OnInit {
    @Input() field: IFormFieldTable;
    @Input() step: IFormStep;

    constructor(
        public formService: ModularFormService,
    ) {
    }

    ngOnInit() {
    }

    getFormControl(row: ModularFormField): FormControl {
        return this.step.formGroup
            .get(row.key) as FormControl;
    }

    getCommentFormControl(commentRow: ModularFormField): FormControl {
        return this.step.formGroup
            .get(commentRow.key) as FormControl;
    }

    setAll(choice: InputValue): void {
        for (const row of this.field.tableFields) {
            if (this.field.multiple) {
                this.addChoice(this.getFormControl(row), choice);
            } else {
                this.getFormControl(row).setValue(choice);
            }
        }
    }

    handleChange(e: MatCheckboxChange, row: ModularFormField, choice: InputValue) {
        const control = this.step.formGroup
            .get(row.key) as FormControl;
        if (e.checked) {
            this.addChoice(control, choice);
        } else {
            control.setValue(control.value.filter(v => choice !== v));
        }
    }

    addChoice(control: FormControl, choice: InputValue) {
        if (!control.value.includes(choice)) {
            control.value.push(choice);
        }
    }

    hasValue(row: ModularFormField, choice: InputValue): boolean {
        const control = this.step.formGroup
            .get(row.key) as FormControl;
        return control.value.includes(choice);
    }
}
