<div class="relative flex flex-column gap-xs"
     [ngClass]="containerClass"
>
    <app-label *ngIf="label"
               [label]="label"
               [for]="id"
    ></app-label>

    <app-form-error
            class="absolute right-0 top-0"
            *ngIf="(control.touched || control.dirty) && control.invalid"
            [errors]="control.errors"
    ></app-form-error>

    <button class="urbainput overflow-hidden text-neutral combobox
         flex items-center gap-sm fs-md"
            type="button"
            (click)="showComboboxModal()"
            [ngClass]="inputClass"
            [disabled]="control.disabled"
    >
        <ion-icon *ngIf="prefix" [icon]="prefix" class="shrink-0"></ion-icon>

        <div class="flex flex-1 overflow-hidden">
            <span *ngFor="let option of control.value;"
                  class="whitespace-nowrap comma-separated"
            >{{ option.label }}</span>

            <span *ngIf="!control.value || control.value?.length == 0"
                  class="placeholder whitespace-nowrap"
            >
                {{ placeholder | translate }}
            </span>
        </div>

        <ion-icon *ngIf="suffix" [icon]="suffix" class="ms-auto shrink-0"></ion-icon>

        <select id="{{id}}" tabindex="-1"
                hidden
                [required]="required"
                [formControl]="control"
                [multiple]="multiple"
                [attr.aria-invalid]="control.invalid && control.touched ? control.invalid : null"
        >
            <option *ngFor="let option of options"
                    value="{{option.value}}"
                    [selected]="option.value == control.value[0]?.value"
            >
                {{ option.label }}
            </option>
        </select>
    </button>
</div>


