import {Component, Input, OnInit} from '@angular/core';
import {IFormField, IFormFieldAccordeon, IFormStep} from '../../../interfaces/IFormStep';

@Component({
    selector: 'app-accordeon-input',
    templateUrl: './accordeon-input.component.html',
    styleUrls: ['./accordeon-input.component.scss'],
})
export class AccordeonInputComponent implements OnInit {
    @Input() field: IFormFieldAccordeon;
    @Input() step: IFormStep;

    constructor() {
    }

    ngOnInit() {
    }

}
