<div class="grid gap-lg grid-cols-lg-2 content-start items-end">
    <h2 class="heading mt-0 mb-mg col-span">{{ 'groupes'|translate }}</h2>

    <p>{{ 'groupe_explication' | translate }}</p>

    <app-combobox
            *ngIf="sujets"
            containerClass="flex-md-row items-md-center"
            inputClass="bg-surface-1"
            [label]="'rechercher un sujet'"
            [placeholder]="'rechercher un sujet'"
            [intoOptions]="{
                items: sujets,
                into: this.formService.sujetIntoComboboxOption
            }"
            (change)="onSearchSujet($event)"
    ></app-combobox>

    <mat-accordion class="col-span scrollable" style="max-height: 30rem">
        <mat-expansion-panel *ngFor="let groupe of groupes" class="bg-surface-1 shadow-none">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ groupe.nom }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="clean-list grid grid-auto-fit gap-md">
                <li *ngFor="let sujet of groupe.sujets">
                    <a [routerLink]="['/sujet', sujet.id_suj]">
                        <app-sujet-card [subject]="sujet"></app-sujet-card>
                    </a>
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>
</div>
