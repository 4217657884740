<figure class="grid gap-sm" [ngClass]="containerClass">
    <app-blurhash
            [style.height]="height"
            class="block w-full"
            [imageSrc]="imgSrc"
            [blurHash]="blurhash"
            objectFit="contain"
    ></app-blurhash>
    <figcaption class="empty-hidden"><ng-content></ng-content></figcaption>
</figure>
