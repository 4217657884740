import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModularFormComponent} from '../components/organisms/modular-form/modular-form.component';
import {ModularFormNavComponent} from '../components/molecules/modular-form-nav/modular-form-nav.component';
import {ModularFileInputComponent} from '../components/atoms/modular-file-input/modular-file-input.component';
import {ModularInputComponent} from '../components/atoms/modular-input/modular-input.component';
import {StepInputComponent} from '../components/atoms/step-input/step-input.component';
import {MunsellInputComponent} from '../components/atoms/munsell-input/munsell-input.component';
import {FormTooltipComponent} from '../components/atoms/form-tooltip/form-tooltip.component';
import {ChecklistInputComponent} from '../components/atoms/checklist-input/checklist-input.component';
import {TableInputComponent} from '../components/atoms/table-input/table-input.component';
import {NotInputComponent} from '../components/atoms/not-input/not-input.component';
import {NotAModularInputDirective} from '../directives/not-amodular-input.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordeonInputComponent} from '../components/atoms/accordeon-input/accordeon-input.component';
import {IonicModule} from '@ionic/angular';
import {MatModule} from '../shared/mat.module';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ModularSelectDialogComponent} from '../components/dialogs/modular-select-dialog/modular-select-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {UiModule} from "../ui/ui.module";

@NgModule({
    declarations: [
        AccordeonInputComponent,
        ChecklistInputComponent,
        FormTooltipComponent,
        ModularFileInputComponent,
        ModularFormComponent,
        ModularFormNavComponent,
        ModularInputComponent,
        ModularSelectDialogComponent,
        MunsellInputComponent,
        NotAModularInputDirective,
        NotInputComponent,
        StepInputComponent,
        TableInputComponent,
    ],
    exports: [
        AccordeonInputComponent,
        ChecklistInputComponent,
        FormTooltipComponent,
        ModularFileInputComponent,
        ModularFormComponent,
        ModularFormNavComponent,
        ModularInputComponent,
        ModularSelectDialogComponent,
        MunsellInputComponent,
        NotAModularInputDirective,
        NotInputComponent,
        StepInputComponent,
        TableInputComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        UiModule,
        IonicModule,
        LeafletModule,
        MatModule,
        ReactiveFormsModule,
        TranslateModule,
        ZXingScannerModule,
    ]
})
export class ModularFormModule {
}

