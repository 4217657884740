<div class="grid gap-md">
    <app-contact-data
            *ngIf="contact != null"
            [title]="'contacts'"
            [contactJuridique]="contact"
    ></app-contact-data>

    <mat-accordion multi *ngIf="projets.length">
        <mat-expansion-panel class="bg-surface-1 shadow-none">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'trouvez_vos_conseillers_par_projet'|translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="grid gap-md">
                <app-button
                        *ngFor="let projet of projets"
                        icon="arrow-forward"
                        class="bg-surface-2"
                        btnClass="justify-start"
                        variant="outline"
                        [to]="['/projet', projet.id_sit]"
                        [fragment]="'projet-contacts-section'"
                >
                    {{ projet.nom_sit }}
                </app-button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</div>
