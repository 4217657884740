import {Component, Input, OnInit} from '@angular/core';
import {DisplayService} from '../../services/display.service';
import {UtilsService} from '../../services/utils.service';
import {ModalController} from '@ionic/angular';
import {DetailSujetPage} from '../../pages/detail-sujet/detail-sujet.page'
import {ActivatedRoute, Router} from '@angular/router';
import {Device} from "../../interfaces/Device";
import {Id} from "../../interfaces/Id";

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
    @Input() device: Device;
    id_projet: Id;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public display: DisplayService,
        public utils: UtilsService,
        private modalController: ModalController
    ) {
        this.id_projet = this.route.snapshot.params.id_projet;
    }

    ngOnInit() {
        this.device = this.utils.analyseSonde(this.device);
        console.log(this.device);
    }

    public async presentModalSujet(programmation) {

        const modal = await this.modalController.create({
            component: DetailSujetPage,
            cssClass: 'modal-screen',
            componentProps: {
                'id_sujet': programmation
            }
        });
        modal.onDidDismiss().then(data => {

            if (data.data.update) {
                if (data.data.update == true) {


                }
            }
        });
        return await modal.present();
    }

    goSujet(vegetalid) {
        this.router.navigate(['/site/' + this.id_projet + '/equipement/sujet', vegetalid]);
    }

    getVbatColor(valeur) {
        let color = "red";
        if (valeur) {
            if (valeur < 5) {

            } else if (valeur >= 5 && valeur < 5.5) {
                color = "orange";
            } else if (valeur >= 5.5) {
                color = "#55c277";
            }
        }
        return color;
    }
}
