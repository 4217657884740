import {Component, forwardRef, Input, OnInit,} from '@angular/core';
import {IFormStep, ModularFormField} from '../../../interfaces/IFormStep';
import {ModularFormService} from '../../../services/modular-form.service';
import {AbstractControl, ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatVerticalStepper} from '@angular/material/stepper';

@Component({
    selector: 'app-modular-input',
    templateUrl: './modular-input.component.html',
    styleUrls: ['./modular-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModularInputComponent),
            multi: true
        }
    ]
})
export class ModularInputComponent implements ControlValueAccessor, OnInit {
    @Input() field: ModularFormField;
    @Input() control: AbstractControl;
    @Input() step: IFormStep;
    @Input() stepper: MatVerticalStepper;
    formArray: FormArray;
    formControl: FormControl;

    isScanning = false;

    onChange: any = () => {
    }

    constructor(
        public modularFormService: ModularFormService,
    ) {
    }

    ngOnInit() {
        this.formControl = this.getFormControl();
        this.formArray = this.getFormArray();

        if (this.field.disabled && null != this.getFormControl()) {
            this.getFormControl().disable();
        }
    }

    getFormControl(): FormControl {
        if (null == this.control) {
            return null;
        }
        return this.control instanceof FormControl ? this.control : this.control.get(this.field.key) as FormControl;
    }

    getFormArray(): FormArray {
        if (null == this.control) {
            return null;
        }
        if (this.field.disabled) {
            this.control.disable({});
        }
        return this.control instanceof FormArray ? this.control : this.control.get(this.field.key) as FormArray;
    }

    /**
     * Retrieving QR code value and inject it
     * inside this.control as value
     */
    handleScanSuccess(e) {
        this.isScanning = false;
        // Ici on doit trim le 1er zero
        if (e.startsWith('0')) {
            e =  e.slice(1);
        }
        this.control.setValue(e);
    }

    toggleScanner() {
        this.isScanning = !this.isScanning;
    }

    writeValue(value: any): void {
        this.getFormControl().setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

}
