<div>
	<p *ngIf="!datePeriod">
		{{message}}
	</p>

	<mat-form-field appearance="fill" *ngIf="datePeriod">
		<mat-label>Merci de choisir les dates d'export souhaitées</mat-label>
		<mat-date-range-input [rangePicker]="picker">
		  <input [(ngModel)]="dateDebut" matStartDate placeholder="Début">
		  <input [(ngModel)]="dateFin" matEndDate placeholder="Fin">
		</mat-date-range-input>

		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
	  </mat-form-field>
</div>
<div align="center">
	<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button (click)="close()" mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</div>