<app-modal-header
        [title]="'fiche_site.title'"
></app-modal-header>

<ion-content>
    <app-project-form
            [projectId]="projectId"
            [ficheSite]="ficheSite"
    ></app-project-form>
</ion-content>
