<div class="grid grid-cols-md-2 gap-md">
    <div class="col-span flex content-start items-center gap-xs flex-wrap">
        <h2 class="heading items-center gap-xs flex-1">
            <ion-icon icon="wifi-outline" [color]="subject.dispositifs_defectueux ? 'danger' : 'success'"></ion-icon>
            {{ 'dispositifs'|translate }}
        </h2>
        <div class="flex gap-xs">
            <app-button *ngIf="subject.droits.gestion_device == '1'" size="sm" variant="outline"
                        icon="wifi"
                        [to]="['/site', subject.id_sit, 'equipement']"
                        [fragment]="'ms_sujet_' + subject.id_suj"
            >
                {{ 'equipement'|translate }}
            </app-button>

            <app-button *ngIf="subject" size="sm" variant="outline"
                        (btnClick)="this.openSujetMaintenanceForm(subject)"
                        [disabled]="subject.droits.gestion_device != '1'"
                        icon="construct"
            >
                {{ 'maintenance'|translate }}
            </app-button>
        </div>
    </div>

    <app-card-rows [indicateurs]="indicateur_sondes"></app-card-rows>

    <div class="keyval-group self-start">
        <app-keyvals [indicateurs]="autre_sondes"></app-keyvals>
        <app-keyval label="sujets_equipes">
            <ul class="clean-list flex flex-wrap gap-sm">
                <li *ngFor="let sujet of sujets_equipes">
                    <app-button
                            [to]="['/sujet', sujet.valeur]"
                            icon="leaf"
                            variant="ghost"
                            [color]="''" btnClass="primary-link shadow-none">
                        {{ sujet.nom }}
                    </app-button>
                </li>
            </ul>
        </app-keyval>
    </div>
</div>

