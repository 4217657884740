<h4 *ngIf="field.widget.title" class="{{field.widget.titleClass}}">
    {{ field.widget.title | translate }}
</h4>
<p *ngFor="let p of field.widget.content" class="{{field.widget.contentClass}}">
    {{ p | translate }}
</p>


<ng-template appNotAModularInput></ng-template>
<app-loader [loading]="loading"></app-loader>
