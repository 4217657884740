<mat-vertical-stepper #stepper
                      *ngIf="ready"
                      (selectionChange)="updateHistory($event)"
                      [ngClass]="{
                      'auto-stepper': isAutoStepper(),
                      'hidden-stepper': !showSteps,
                      'loading': loading
                      }"
>
    <mat-step *ngFor="let step of steps; let stepNumber = index"
              [stepControl]="step.formGroup"
              errorMessage="{{ step.formGroup.errors }}">
        <form [formGroup]="step.formGroup"
              class="modular-grid"
              [style.--_xs-cols]="step.gridTemplate?.xs?.cols"
              [style.--_xs-rows]="step.gridTemplate?.xs?.rows"
              [style.--_sm-cols]="step.gridTemplate?.sm?.cols"
              [style.--_sm-rows]="step.gridTemplate?.sm?.rows"
              [style.--_md-cols]="step.gridTemplate?.md?.cols"
              [style.--_md-rows]="step.gridTemplate?.md?.rows"
              [style.--_lg-cols]="step.gridTemplate?.lg?.cols"
              [style.--_lg-rows]="step.gridTemplate?.lg?.rows"
              [style.--_xl-cols]="step.gridTemplate?.xl?.cols"
              [style.--_xl-rows]="step.gridTemplate?.xl?.rows"
              [style.--_2xl-cols]="step.gridTemplate?.xxl?.cols"
              [style.--_2xl-rows]="step.gridTemplate?.xxl?.rows"
        >
            <ng-template matStepLabel>{{ step.label | translate }}</ng-template>
            <app-modular-input
                    *ngFor="let field of step.fields"
                    [field]="field"
                    [control]="step.formGroup.get(field.key)"
                    [step]="step"
                    [stepper]="stepper"
                    [style.--_xs-col]="field.grid?.xs?.col"
                    [style.--_xs-row]="field.grid?.xs?.row"
                    [style.--_xs-colspan]="field.grid?.xs?.colspan"
                    [style.--_xs-rowspan]="field.grid?.xs?.rowspan"
                    [style.--_xs-justify-self]="field.grid?.xs?.justifySelf"
                    [style.--_xs-align-self]="field.grid?.xs?.alignSelf"
                    [style.--_sm-col]="field.grid?.sm?.col"
                    [style.--_sm-row]="field.grid?.sm?.row"
                    [style.--_sm-colspan]="field.grid?.sm?.colspan"
                    [style.--_sm-rowspan]="field.grid?.sm?.rowspan"
                    [style.--_sm-justify-self]="field.grid?.sm?.justifySelf"
                    [style.--_sm-align-self]="field.grid?.sm?.alignSelf"
                    [style.--_md-col]="field.grid?.md?.col"
                    [style.--_md-row]="field.grid?.md?.row"
                    [style.--_md-colspan]="field.grid?.md?.colspan"
                    [style.--_md-rowspan]="field.grid?.md?.rowspan"
                    [style.--_md-justify-self]="field.grid?.md?.justifySelf"
                    [style.--_md-align-self]="field.grid?.md?.alignSelf"
                    [style.--_lg-col]="field.grid?.lg?.col"
                    [style.--_lg-row]="field.grid?.lg?.row"
                    [style.--_lg-colspan]="field.grid?.lg?.colspan"
                    [style.--_lg-rowspan]="field.grid?.lg?.rowspan"
                    [style.--_lg-justify-self]="field.grid?.lg?.justifySelf"
                    [style.--_lg-align-self]="field.grid?.lg?.alignSelf"
                    [style.--_xl-col]="field.grid?.xl?.col"
                    [style.--_xl-row]="field.grid?.xl?.row"
                    [style.--_xl-colspan]="field.grid?.xl?.colspan"
                    [style.--_xl-rowspan]="field.grid?.xl?.rowspan"
                    [style.--_xl-justify-self]="field.grid?.xl?.justifySelf"
                    [style.--_xl-align-self]="field.grid?.xl?.alignSelf"
                    [style.--_2xl-col]="field.grid?.xxl?.col"
                    [style.--_2xl-row]="field.grid?.xxl?.row"
                    [style.--_2xl-colspan]="field.grid?.xxl?.colspan"
                    [style.--_2xl-rowspan]="field.grid?.xxl?.rowspan"
                    [style.--_2xl-justify-self]="field.grid?.xxl?.justifySelf"
                    [style.--_2xl-align-self]="field.grid?.xxl?.alignSelf"
                    [hidden]="isHiddenInput(field)"
            >
            </app-modular-input>

            <app-modular-form-nav
                    [currentStepIndex]="stepNumber"
                    [stepsTotal]="steps.length"
                    [isEditMode]="isEditMode()"
                    [canGoBack]="step.enablePrev != null ? step.enablePrev : true"
                    (onFormSubmit)="handleSubmit()"
                    (onPrev)="handlePrev(stepper, step, stepNumber)"
                    (onNext)="handleNext(stepper, step)"
                    [isCompletedStep]="canGoNext(step.formGroup)"
                    [showSaveBtn]="showSaveBtn"
                    (onSave)="saveToStorage()"
            ></app-modular-form-nav>
        </form>
    </mat-step>
</mat-vertical-stepper>
<!--// TODO REMOVE THIS-->
<!--<button (click)="handleSubmit()">Soumission test</button>-->
<!--// TODO REMOVE THIS-->

