<dl class="grid grid-cols-auto-1 gap-md content-start items-start">
    <ng-container *ngFor="let item of definitions">
        <dt>
            <ion-icon *ngIf="item.icon" [icon]="item.icon" [color]="item.color"></ion-icon>
            <span class="empty-hidden">{{item.term|translate}}</span>
        </dt>
        <dd class="m-0">
            {{item.definition|translate}}
        </dd>
    </ng-container>
</dl>
