<ul *ngIf="photos.length; else no_picture"
    class="clean-list grid gap-sm overflow-auto justify-start grid-flow-col
            py-sm px-xs scrollable-x"
style="margin-top: calc(var(--s-sm) * -1); margin-inline: calc(var(--s-xs) * -1); grid-auto-columns: 5rem">
    <li *ngFor="let photo of photos" class="relative"
        [ngClass]="{'urba-selected': (this.selectedPhoto != null && photo.id == this.selectedPhoto.id), 'cursor-pointer': showActions}"
        (click)="handleCarouselItemSelected(photo.id)"
        (keydown.enter)="handleCarouselItemSelected(photo.id)"
        tabindex="0"
        class="relative overflow-hidden rounded-sm"
    >
        <div *ngIf="photo.is_favorite"
             class="absolute top-0 left-0 rounded-sm bg-surface-1 p-2xs m-2xs z-1">
            <ion-icon icon="pin"></ion-icon>
        </div>
        <app-blurhash style="height: 5rem; width: 5rem;"
                  [blurHash]="photo.blurhash"
                  [imageSrc]="pathPrefix + photo.photo_path"
        ></app-blurhash>
    </li>
</ul>
<ng-template #no_picture>
    <p *ngIf="!loadingImages" class="bg-surface-1 p-md">
        {{'aucune_photo_sujet' | translate}}
    </p>
    <app-loader *ngIf="loadingImages" [loading]="true"
                [message]="'loading_images'"
    ></app-loader>
</ng-template>

<app-button
        *ngIf="photos.length && showActions"
        [fill]="'block'"
        variant="outline"
        icon="pin"
        class="mt-md"
        (click)="setFavorite()"
            [disabled]="this.selectedPhoto == null || this.selectedPhoto.is_favorite"
        [loading]="changingFavorite"
>
    {{'pin_image_principale' | translate }}
</app-button>
