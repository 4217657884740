import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IFormField} from '../../../interfaces/IFormStep';
import {ModularFormService} from '../../../services/modular-form.service';

@Component({
    selector: 'app-checklist-input',
    templateUrl: './checklist-input.component.html',
    styleUrls: ['./checklist-input.component.scss'],
})
export class ChecklistInputComponent implements OnInit {
    @Input() control: FormControl;
    @Input() field: IFormField;

    constructor(
        public modularFormService: ModularFormService,
    ) {
    }

    ngOnInit() {
        if (!Array.isArray(this.control.value)) {
            this.control.setValue([]);
        }
        this.checkingChoicesOrder();
    }

    valueIsAtTheRightIndex(index: number): boolean {
        return this.control.value[index] === this.field.choices[index];
    }

    /**
     * Choices order matters so we make sure to populate the input with
     * valid value in a valid order.
     */
    checkingChoicesOrder() {
        let i = 0;
        while (this.valueIsAtTheRightIndex(i)) {
            i++;
        }
        this.control.setValue(this.field.choices.map(c => c.value)
            .slice(0, i)
        );
    }

    handleChange(checked: boolean, i: number) {
        this.control.setValue(this.field.choices.map(c => c.value)
            .slice(0, i + (checked ? 1 : 0)
            ));
    }

    isDisabled(index: number) {
        return index > this.control.value.length;
    }

    isChecked(index: number) {
        if (this.control.value.length === 0) {
            return false;
        }
        return index < this.control.value.length;
    }

}
