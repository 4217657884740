<ion-header *ngIf="bulletin">
    <ion-toolbar color="secondary">
        <ion-buttons slot="end">
            <ion-button id="closeB" (click)="closeModal()">{{'fermer' | translate}}</ion-button>
        </ion-buttons>
        <ion-title>&nbsp;</ion-title>
        <ion-label>
            <h5 style="margin-left: 10px;">{{'Détail du bulletin' | translate}}</h5>
        </ion-label>

    </ion-toolbar>
    <ion-item *ngIf="bulletin">
        <ion-buttons slot="end">
            <ion-button color="primary" (click)="getBulletin(entete)">{{'download_bulletin' | translate}}</ion-button>
        </ion-buttons>
        <ion-label class="ion-text-wrap">
            <ion-text>
                <h2 class="boldSf"><font *ngIf="entete">{{entete.nom_site}}</font>
                </h2>

                <!-- </h2>
      -->
                <p *ngIf="entete.date_envoi_mail">{{entete.date_envoi_mail.replace(' ', 'T') | date:'mediumDate' : '' : translate.currentLang}}</p>
                <ion-badge style="background-color:#55c277 !important;    vertical-align: middle;"
                           *ngIf="entete?.risque == 'ok'">{{'risque_ok' | translate}}</ion-badge>
                <ion-badge style="background-color:#fab41d !important;    vertical-align: middle;"
                           *ngIf="entete?.risque == 'vigilance'">{{'risque_vigilance' | translate}}</ion-badge>
                <ion-badge style="background-color:#d54927 !important;    vertical-align: middle;"
                           *ngIf="entete?.risque == 'alerte'">{{'risque_alerte' | translate}}</ion-badge>
                <ion-badge style="background-color:#54384E !important;    vertical-align: middle;"
                           *ngIf="entete?.risque == 'crise'">{{'risque_crise' | translate}}</ion-badge>
            </ion-text>


        </ion-label>


    </ion-item>
    <ion-item *ngIf="entete.rem_preco">
        <ion-label class="ion-text-wrap" [innerHTML]="sanitizer.bypassSecurityTrustHtml(entete.rem_preco)"></ion-label>
    </ion-item>
</ion-header>
<ion-content>
    <div *ngIf="bulletin">
        <div *ngIf="!entete.pdf_path">
            <div *ngFor="let bull of bulletin">
                <ion-item>
                    <ion-label>
                        <ion-text>
                            <h4 class="boldSf">{{bull?.nom_groupe}}</h4>


                            <ion-badge style="background-color:#55c277 !important;    vertical-align: middle;"
                                       *ngIf="bull?.risque == 'ok' && !bull?.BULL_ENTETE_RISQUE">{{'risque_ok' | translate}}</ion-badge>
                            <ion-badge style="background-color:#fab41d !important;    vertical-align: middle;"
                                       *ngIf="bull?.risque == 'vigilance' && !bull?.BULL_ENTETE_RISQUE">{{'risque_vigilance' | translate}}</ion-badge>
                            <ion-badge style="background-color:#d54927 !important;    vertical-align: middle;"
                                       *ngIf="bull?.risque == 'alerte' && !bull?.BULL_ENTETE_RISQUE">{{'risque_alerte' | translate}}</ion-badge>
                            <ion-badge style="background-color:#54384E !important;    vertical-align: middle;"
                                       *ngIf="bull?.risque == 'crise' && !bull?.BULL_ENTETE_RISQUE">{{'risque_crise' | translate}}</ion-badge>

                            <ion-grid
                                    *ngIf="bull?.type_preco == 'groupe_type_preco_auto' || bull?.type_preco == 'groupe_type_preco_autoMS_ing'">

                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:center" size="3">
                                        <h5 class="boldSf">{{'Action à mener' | translate}}</h5>
                                        <div>
                                            <img *ngIf="bull?.preco_picto !='/images/urbasense/icones/icones_graphes/null.jpg'"
                                                 src="{{photo_url +''+ bull?.preco_picto}}"/>
                                            <p *ngIf="bull?.preco && bull?.preco != 'pasmodif'">{{bull?.preco | translate}}
                                                <font *ngIf="bull?.quantite  != 0">{{bull?.quantite}} L</font></p>
                                        </div>
                                    </ion-col>


                                    <ion-col class="ion-text-wrap" size="5">
                                        <div *ngIf="bull?.q_jour">
                                            <h5 class="boldSf">{{'Conseil programmation' | translate}}</h5>
                                            {{bull.q_jour}} <span *ngIf="convertToJSON(bull.jours)[0] != ''">les </span>
                                            <span *ngFor="let jour of convertToJSON(bull.jours)">{{jour}} </span>
                                        </div>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" size="4">
                                        <div *ngIf="bull?.remarque_diagnostic">
                                            <h5 class="boldSf">{{'Diagnostic' | translate}}</h5>
                                            {{bull.remarque_diagnostic}}
                                        </div>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap">
                                        <div *ngIf="bull?.remarque_conseil">
                                            <h5 class="boldSf">{{'Conseil' | translate}}</h5>
                                            {{bull.remarque_conseil}}
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>


                            <ion-grid
                                    *ngIf="bull?.type_preco != 'groupe_type_preco_auto' && bull?.type_preco != 'groupe_type_preco_autoMS_ing'">
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:center" size="3">
                                        <h5 class="boldSf">{{'Préconisation ancien' | translate}}</h5>
                                        <div>
                                            <img *ngIf="bull?.preco_picto !='/images/urbasense/icones/icones_graphes/null.jpg'"
                                                 src="{{photo_url +''+ bull?.preco_picto}}"/>
                                            <p *ngIf="bull?.preco && bull?.preco != 'pasmodif'">{{bull?.preco | translate}}
                                                <font *ngIf="bull?.quantite  != 0">{{bull?.quantite}} L</font></p>
                                        </div>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" size="5">

                                    </ion-col>
                                    <ion-col class="ion-text-wrap" size="4">

                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap">

                                    </ion-col>
                                </ion-row>

                            </ion-grid>
                        </ion-text>
                    </ion-label>
                </ion-item>
            </div>
        </div>

        <div *ngIf="entete.pdf_path">
            <div *ngFor="let bull of bulletin">
                <ion-item class="ion-text-wrap">
                    <ion-label>
                        <h4 class="boldSf">{{bull?.nom_groupe}}</h4>
                        <div *ngIf="!bull.BULL_RISQUE">
                            <ion-grid *ngIf="bull.type_preco == 'preco_manu'">
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Diagnostic' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.remarque_pag"></p>
                                    </ion-col>
                                    <ion-col size="6" class="ion-text-wrap" style="text-align:left">
                                        <h5>{{'Préconisation' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_MANU_CONSEIL_PRECO"></p>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Matériel' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_DIAG_MATERIEL"></p>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Conseil' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_MANU_CONSEIL_AGRO"></p>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                            <ion-grid *ngIf="bull.type_preco == 'preco_autoMS'">
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Diagnostic' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.remarque_diagnostic_automs"></p>
                                    </ion-col>
                                    <ion-col size="6" class="ion-text-wrap" style="text-align:left">
                                        <h5>{{'Préconisation' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_AUTOMS_CONSEIL_PRECO"></p>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Matériel' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_DIAG_MATERIEL"></p>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Conseil' | translate}}</h5>
                                        <p [innerHTML]="prs(bull?.preco_full)?.text_BULL_AUTOMS_CONSEIL_AGRO"></p>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>

                        </div>

                        <div *ngIf="bull.BULL_RISQUE">
                            <ion-grid *ngIf="bull.type_preco == 'groupe_type_preco_manu'">
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Diagnostic' | translate}}</h5>
                                        <p [innerHTML]="bull?.remarque_pag"></p>
                                    </ion-col>
                                    <ion-col size="6" class="ion-text-wrap" style="text-align:left">
                                        <h5>{{'Préconisation' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_MANU_CONSEIL_PRECO"></p>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Matériel' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_DIAG_MATERIEL"></p>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Conseil' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_MANU_CONSEIL_AGRO"></p>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                            <ion-grid *ngIf="bull?.type_preco == 'groupe_type_preco_autoMS_ing'">
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Diagnostic' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_AUTOMS_DIAG_ARROSAGE"></p>
                                    </ion-col>
                                    <ion-col size="6" class="ion-text-wrap" style="text-align:left">
                                        <h5>{{'Préconisation' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_AUTOMS_CONSEIL_PRECO"></p>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Matériel' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_DIAG_MATERIEL"></p>
                                    </ion-col>
                                    <ion-col class="ion-text-wrap" style="text-align:left" size="6">
                                        <h5>{{'Conseil' | translate}}</h5>
                                        <p [innerHTML]="bull?.text_BULL_AUTOMS_CONSEIL_AGRO"></p>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>


                    </ion-label>

                </ion-item>
            </div>
        </div>

    </div>

    <ion-item *ngIf="!isLoading  &&  !bulletin">
        <ion-label>
            {{'chargement_bulletin_impossible' | translate}}
        </ion-label>
    </ion-item>


</ion-content>
