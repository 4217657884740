<div class="grid gap-lg content-start items-start">
    <h2 class="heading mt-0 mb-mg col-span">{{ 'documents'|translate }}</h2>
    <div class="grid grid-cols-lg-1_2 gap-lg">

        <app-file-form
                [title]="'ajouter_document'"
                [acceptedFileTypes]="acceptedFileTypes"
                class="bg-surface-1 p-sm"
                (onFileUpload)="handleFileUpload($event)"
                [loading]="sendingDocument"
                [fileInputName]="'doc'"
                [legendInputName]="'implantation'"
        ></app-file-form>

        <div class="grid gap-md content-start scrollable" style="max-height: 20rem">
<!--            // TODO need API endpoint to download archive-->
<!--            <app-button class="justify-self-lg-start" icon="download">-->
<!--                {{'telecharger_tout' | translate}}-->
<!--            </app-button>-->
            <ul class="clean-list contents">
                <li *ngFor="let fichier of fichiers" data-from="right">
                    <app-document-item
                            [projectDocument]="fichier"
                    ></app-document-item>
                </li>
            </ul>
        </div>
    </div>
</div>
