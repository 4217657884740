<div class="file-input__container urbasense-input-container">
    <input type="file"
           name="field.key"
           id="{{ field.key }}"
           [multiple]="field.multiple"
           [accept]="field.accept?.join(', ')"
           [disabled]="control.disabled"
           (change)="handleFileSelect($event)"
           #input
    />

    <label for="{{ field.key }}">{{ field.label | translate }}</label>
    <div class="file-input-button-container"
         (click)="handleInputClick()"
    >
        <button class="file-input__button">
            {{'file_input_btn' + (field.multiple ? '_multiple' : '') | translate }}</button>
        <p class="file-input__text">{{ message | translate: {count: control.controls.length} }}</p>
    </div>

    <ul class="file-preview__list">
        <li class="file-preview__item" *ngFor="let fileControl of control.controls; let i = index">
            <div class="item-container">
                <img *ngIf="isPreviewable(fileControl.controls.file.value); else simplePreview"
                     [src]="fileControl.controls.blob.value"
                     alt="{{field.key}}_preview_{{i}}">
                <ng-template #simplePreview>
                    <ion-icon name="document-outline"></ion-icon>
                </ng-template>
                <button class="delete-image-btn"
                        (click)="deleteFile(i)"
                >
                    <ion-icon name="trash-outline"></ion-icon>
                </button>
            </div>
        </li>
    </ul>
</div>

