<div class="urbacard shadow rounded-sm" (mouseenter)="onHoverMarker(id)"
(mouseleave)="onLeaveMarker()">
    <div class="flex gap-sm min-w-0">
        <h2 class="truncate flex-1 ff-heading fw-bold" title="{{subject.nom_suj}}">{{subject.nom_suj}}</h2>

        <div class="flex gap-sm">
<!--            <ion-icon icon="pin-outline" action="TODO"></ion-icon>-->
            <ion-icon icon="water-outline" [color]="subject.sujet_en_detresse ? 'danger' : 'success'" ></ion-icon>
            <ion-icon icon="wifi-outline" [color]="subject.dispositifs_defectueux ? 'danger' : 'success'"></ion-icon>
        </div>
    </div>
    <p class="text-muted" *ngIf="subject.nom_sit">{{subject.nom_sit}}</p>

    <img *ngIf="subject.fav_photo" src="{{subject.fav_photo}}" style="height: 5rem; width: 100%;"
         class="rounded-sm object-cover" lazyLoad>
</div>
