<figure class="bg-light"
        [ngStyle]="{'height': height}"
>
    <p style="background-color:rgba(216, 56, 56, 0.774);color:white;padding:1%;border-radius:5px;margin:10px;"
       *ngIf="sujet.sous_contrat == 0" color="danger">
        {{ 'votre_vegetal_n_est_plus_sous_contrat_pour_renouveler_votre_contrat_merci_de_contacter_notre_equipe' | translate }}
        :
        <span *ngFor="let contact of sujet.contacts"><br>{{ contact.mail }}
            - {{ contact.site_fonction }} {{ contact.tel }}</span>
    </p>

    <app-loader *ngIf="isLoading"
                [loading]="true"
                [message]="'graphe_loading'"
                [classes]="'bg-surface-1 h-full'"
    ></app-loader>

    <div
            #chartContainer
            [hidden]="!sujet && sujet.sous_contrat == 0 || isLoading || error"
            [id]="'chartdiv' + myDinid"
            [ngStyle]="{'height': height}"
            style="width: 100%;">
    </div>

    <app-error
            *ngIf="error"
            [message]="'data_fetch_error'"
    ></app-error>
</figure>
