<article class="grid gap-lg content-start"
         [class.grid-cols-lg-1_2]="imgSrc"
>
    <app-figure *ngIf="imgSrc" [imgSrc]="imgSrc"></app-figure>

    <div class="grid grid-cols-md-2 gap-md items-end content-start">
        <h2 class="heading my-0 col-span">{{ title|translate }}</h2>

        <div class="grid gap-md" *ngIf="contact">
            <app-keyval
                    [label]="('prenom'|translate) + '-' + ('nom'|translate)"
                    [value]="contact.prenom + ' ' + contact.Nom"
            ></app-keyval>
            <app-keyval
                    [label]="'telephone'"
                    [value]="contact.tel"
            ></app-keyval>
            <app-keyval
                    [label]="'e-mail'"
                    [value]="contact.mail"
            ></app-keyval>
        </div>

        <div class="grid gap-md" *ngIf="contactJuridique">
            <app-keyval
                    [label]="'site_internet'"
                    [value]="contactJuridique.adresse_site"
            ></app-keyval>
            <app-keyval
                    [label]="'telephone'"
                    [value]="contactJuridique.telephone"
            ></app-keyval>
            <app-keyval
                    [label]="'e-mail'"
                    [value]="contactJuridique.email"
            ></app-keyval>
        </div>

        <div class="self-end">
            <p class="text-muted uppercase">{{'contact_disponibilite.mail_ou_tel'|translate}}</p>
            <p class="text-muted uppercase">{{'contact_disponibilite.du_lundi_au_vendredi'|translate}}</p>
            <p class="text-muted uppercase">{{'contact_disponibilite.de_9h_à_17h'|translate}}</p>
        </div>
    </div>
</article>
