import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild
} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {InputTypeEnum} from '../../../enums/InputTypeEnum';

@Component({
    selector: 'app-input-simple',
    templateUrl: './input-simple.component.html',
    styleUrls: ['./input-simple.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSimpleComponent),
            multi: true,
        },
    ],
})
export class InputSimpleComponent implements AfterViewInit, ControlValueAccessor {
    @ViewChild('input') inputRef: ElementRef<HTMLInputElement> = new ElementRef<HTMLInputElement>(null);

    @Input() type = InputTypeEnum.TEXT;
    @Input() id: string = 'input-' + Math.random().toString(36).substr(2, 9);
    @Input() value = '';
    @Input() control: AbstractControl | FormControl = new FormControl('');

    @Input() label?: string;
    @Input() placeholder = '';
    @Input() autofocus: boolean = false;
    @Input() autocomplete: string;
    @Input() resetable: boolean = false;
    @Input() disabled: boolean = false;

    // Styles
    @Input() containerClass: string;
    @Input() inputClass: string;

    togglePassword: boolean = false;

    // Icons
    @Input() prefix?: string;
    @Input() suffix?: string;

    // Aria
    @Input() ariaControls: string;
    @Input() ariaExpanded: boolean;
    @Input() ariaAutoComplete: string;
    @Input() ariaActiveDescendant: string;

    constructor() {
    }

    ngAfterViewInit() {
        if (this.type === InputTypeEnum.PASSWORD) {
            this.togglePassword = true;
        }

        if (this.autofocus) {
            setTimeout(() => {
                this.focus();
                // timeout needed to make sure the input is not unfocused after the modal finishes transition
            }, 350);
        }
    }

    togglePasswordView() {
        this.type = this.type === InputTypeEnum.TEXT ? InputTypeEnum.PASSWORD : InputTypeEnum.TEXT;
    }

    private onChange: (value: any) => void = () => {
    }
    private onTouched: () => void = () => {
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    // Registers a callback to notify the form model of touch events
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Optional: Handles the disabled state of the input
    setDisabledState?(isDisabled: boolean): void {
        // Implement this if necessary
    }

    // Handles the `input` event from the template
    onInput(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.value = value;
        this.onChange(value); // Notify the form model of the value change
    }

    // Handles the `blur` event from the template
    onBlur(): void {
        this.onTouched(); // Notify the form model that the input was touched
    }

    focus() {
        this.inputRef.nativeElement.focus();
    }

    reset(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.control.setValue('');
        this.focus();
    }

}
