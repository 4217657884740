<mat-label *ngIf="field.label" [innerHtml]="field.label | translate"></mat-label>

<div class="munsell-input-group">
    <mat-form-field>
        <mat-label>{{ 'munsell.hue' | translate }}</mat-label>
        <mat-select [required]="field.required" [(value)]="hue" (valueChange)="onChange()">
            <mat-option *ngFor="let munsellHue of this.munsellHues"
                        value="{{munsellHue}}">
                {{ munsellHue }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'munsell.value' | translate }}</mat-label>
        <mat-select [required]="field.required" [(value)]="value" (valueChange)="onChange()">
            <mat-option *ngFor="let munsellValue of this.munsellValues"
                        value="{{munsellValue}}">
                {{ munsellValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'munsell.chroma' | translate }}</mat-label>
        <mat-select [required]="field.required" [(value)]="chroma" (valueChange)="onChange()">
            <mat-option *ngFor="let munsellChroma of this.munsellChromas"
                        value="{{munsellChroma}}">
                {{ munsellChroma }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="munsell-preview" [style.background-color]="hexColor"></div>
